import React from 'react'

import type { EditTreatmentNameContextValue } from '@/features/treatments/user-actions/edit-treatment-name/context'

import { EditTreatmentNameDialog } from '@/features/treatments/user-actions/edit-treatment-name/components/edit-treatment-name-dialog'
import { EditTreatmentNameContext } from '@/features/treatments/user-actions/edit-treatment-name/context'
import { editTreatmentNameStore } from '@/features/treatments/user-actions/edit-treatment-name/store'

interface EditTreatmentNameProviderProps extends Omit<EditTreatmentNameContextValue, 'store'> {
  children?: React.ReactNode
}

export function EditTreatmentNameProvider({
  patientId,
  treatment,
  children,
}: EditTreatmentNameProviderProps) {
  const [store] = React.useState(() => editTreatmentNameStore())

  return (
    <EditTreatmentNameContext.Provider value={{ store, patientId, treatment }}>
      <EditTreatmentNameDialog />

      {children}
    </EditTreatmentNameContext.Provider>
  )
}
