import type { AddExercisesStore } from '@/features/treatments/user-actions/add-exercise/store'
import type { EventEmitter } from 'ahooks/lib/useEventEmitter'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export const [AddExercisesContext, useAddExercisesContext] = createStrictContext<{
  store: StoreApi<AddExercisesStore>
  resetFilters$: EventEmitter<void>
}>({
  name: 'AddExercisesContext',
  errorMessage: 'useAddExercisesContext must be used within a AddExercisesContext.Provider.',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
