import { createFileRoute, redirect } from '@tanstack/react-router'

import { SettingsView } from './-settings-view'

export const Route = createFileRoute('/_auth/settings/')({
  beforeLoad: ({ context: { hypertune } }) => {
    const isEnabled = hypertune.enableSettingsPage({ fallback: false })

    if (!isEnabled) {
      throw redirect({ to: '/patients' })
    }
  },
  component: SettingsView,
})
