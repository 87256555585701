'use client'

import React from 'react'
import { XIcon } from 'lucide-react'
import { Dialog as RACDialog, Heading as RACHeading } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'

import type {
  DialogProps as RACDialogProps,
  HeadingProps as RACHeadingProps,
} from 'react-aria-components'
import type { ButtonGroupProps } from '../../buttons/button-group/button-group'
import type { ButtonProps } from '../../buttons/button/button'
import type { TextProps } from '../../typography/text/text'

import { ButtonGroup } from '../../buttons/button-group/button-group'
import { Button } from '../../buttons/button/button'
import { Text } from '../../typography/text/text'
import { cn } from '../../utils'

export type DialogProps = RACDialogProps

export function Dialog(props: DialogProps) {
  return (
    <RACDialog
      {...props}
      className={twMerge(
        'relative max-h-[inherit] overflow-auto p-8 text-left outline outline-0 [[data-placement]>&]:p-4',
        props.className,
      )}
    />
  )
}

export interface DialogTitleProps extends RACHeadingProps {
  /** The content to be displayed within the dialog title. */
  children: React.ReactNode

  /** Optional CSS class for custom styling. */
  className?: string
}

export function DialogTitle({ children, ...rest }: DialogTitleProps) {
  return (
    <RACHeading slot={'title'} {...rest}>
      <Text size={'4'} medium>
        {children}
      </Text>
    </RACHeading>
  )
}

export type DialogSubtitleProps = TextProps

export function DialogSubtitle({ children, className, ...rest }: DialogSubtitleProps) {
  return (
    <Text size={'2'} elementType={'p'} subtle className={cn('mt-2', className)} {...rest}>
      {children}
    </Text>
  )
}

export type DialogContentProps = React.ComponentPropsWithoutRef<'div'>

export function DialogContent({ children, className, ...rest }: DialogContentProps) {
  return (
    <div className={cn('mt-6', className)} {...rest}>
      {children}
    </div>
  )
}

export type DialogCtaProps = ButtonProps

export function DialogCta({ children, ...rest }: DialogCtaProps) {
  return (
    <Button
      type={'submit'}
      size={'sm'}
      variant={'solid'}
      color={'accent'}
      className={'self-start'}
      {...rest}
    >
      {children}
    </Button>
  )
}

export type DialogCancelButtonProps = ButtonProps

export function DialogCancelButton(props: DialogCancelButtonProps) {
  return (
    <Button size={'sm'} variant={'soft'} color={'neutral'} className={'self-start'} {...props}>
      {props.children ?? 'Annuller'}
    </Button>
  )
}

export type DialogButtonGroupProps = ButtonGroupProps

export function DialogButtonGroup({ className, ...rest }: DialogButtonGroupProps) {
  return <ButtonGroup size={'sm'} className={cn('justify-end', className)} {...rest} />
}

export type DialogCloseButtonProps = ButtonProps

export function DialogCloseButton(props: DialogCloseButtonProps) {
  return (
    <Button
      size={'lg'}
      variant={'soft'}
      color={'neutral'}
      className={'shrink-0'}
      aria-label={'Luk'}
      {...props}
    >
      <XIcon />
    </Button>
  )
}
