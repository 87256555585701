import React from 'react'
import { useQuery } from '@tanstack/react-query'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'
import { ChangePractitionerDrawer } from '@/features/patients/user-actions/change-practitioner/components/change-practitioner-drawer'
import { ChangePractitionerContext } from '@/features/patients/user-actions/change-practitioner/context'
import { createChangePractitionerStore } from '@/features/patients/user-actions/change-practitioner/store'

export function ChangePractitionerProvider({ children }: { children: React.ReactNode }) {
  const [store] = React.useState(() => createChangePractitionerStore())
  const query = useQuery(clinicEmployeeQueries.list())

  const employees = query.data ?? []

  return (
    <ChangePractitionerContext.Provider value={{ store, employees }}>
      <ChangePractitionerDrawer />

      {children}
    </ChangePractitionerContext.Provider>
  )
}
