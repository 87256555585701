import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type React from 'react'

import { createStrictContext } from '@fysioscout/utils/react'

export type ExerciseTableAction = 'edit-exercise'

interface ExerciseTableContextValue {
  /** Optional function to render a custom name cell for each exercise item. */
  renderNameCell?: (item: Schemas['ExerciseInfo']) => React.ReactNode

  /** Optional function to render a custom video cell for each exercise item. */
  renderVideoCell?: (item: Schemas['ExerciseInfo']) => React.ReactNode

  /** Disables the specified actions in the ExerciseTableContext. */
  disabledActions?: ExerciseTableAction[]
}

export const [ExerciseTableContext, useExerciseTableContext] =
  createStrictContext<ExerciseTableContextValue>({
    name: 'ExerciseTableContext',
    errorMessage: 'useExerciseTable must be used within a ExerciseTableContext.Provider.',
    allowMissingProvider: false,
    isDevelopment: import.meta.env.DEV,
  })
