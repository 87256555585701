import { queryOptions } from '@tanstack/react-query'
import { snakeKeys } from 'string-ts'

import type { ListTemplateTreatmentsOptions } from '@/api/fysioscout/endpoints/clinic-template-treatment/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTemplateTreatmentQueries = {
  all: () => ['clinic-template-treatments'],
  lists: () => [...clinicTemplateTreatmentQueries.all(), 'lists'],

  /**
   * List Template Treatments
   *
   * @param path - The path parameters
   * @param opts - Extra query options
   * @param opts.enabled - Whether the query should be enabled
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Template-Treatment-or-Shared/paths/~1api~1clinics~1template-treatments~1regions~1%7Bregion_id%7D/get}
   */
  list: (
    path: CamelKeys<ListTemplateTreatmentsOptions['params']['path']>,
    opts: { enabled?: boolean } = {},
  ) => {
    return queryOptions({
      queryKey: [...clinicTemplateTreatmentQueries.lists(), path],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/template-treatments/regions/{region_id}', {
            signal,
            params: { path: snakeKeys(path) },
          }),
        )
      },
      ...opts,
    })
  },
}
