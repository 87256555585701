/**
 * Adds a transition blocker style to the document head.
 *
 * @returns A function that removes the added style from the document head.
 */
export function addTransitionBlockerStyle(): () => void {
  const style = document.createElement('style')
  const cssText = `
    *, *::before, *::after {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      animation: none !important;
    }
  `

  style.append(document.createTextNode(cssText))
  document.head.append(style)

  return () => {
    style.remove()
  }
}
