import { useSuspenseQuery } from '@tanstack/react-query'
import { useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { Route } from '@/app/routes/_auth/patients/$patientId/index'
import { PageHeading, PageLayout } from '@/components/layouts/page-layout'
import { PatientTopBar } from '@/features/patients/components/patient-top-bar'
import { DetachPatientProvider } from '@/features/patients/user-actions/detach-patient/components/detach-patient-provider'
import { TreatmentList } from '@/features/treatments/components/treatment/treatment-list'
import { useCreateTreatmentActions } from '@/features/treatments/user-actions/create-treatment/store'
import { EditTreatmentNameProvider } from '@/features/treatments/user-actions/edit-treatment-name/components/edit-treatment-name-provider'

export function PatientView() {
  const navigate = Route.useNavigate()
  const { patientId } = Route.useParams()

  const createTreatmentActions = useCreateTreatmentActions()

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(patientId))

  useTitle(patient.name ?? patient.email)

  const handleDetachPatientSuccess = async () => {
    await navigate({ to: '/patients', search: { status: 'accepted' } })
  }

  return (
    <DetachPatientProvider onSuccess={handleDetachPatientSuccess}>
      <EditTreatmentNameProvider patientId={patientId}>
        <PageLayout>
          <PageHeading>{patient.name}</PageHeading>
          <PatientTopBar className={'mt-6'} patient={patient} />

          <div className={'stack mt-8 items-end justify-between'}>
            <Button
              variant={'solid'}
              color={'accent'}
              iconEnd={<PlusIcon />}
              onPress={() => {
                createTreatmentActions.open()
              }}
            >
              Opret behandlingsforløb
            </Button>
          </div>

          <TreatmentList className={'mt-8'} patient={patient} />
        </PageLayout>
      </EditTreatmentNameProvider>
    </DetachPatientProvider>
  )
}
