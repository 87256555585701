import { Outlet } from '@tanstack/react-router'

import { Route } from '@/app/routes/_auth/patients/$patientId/route'
import { ExportExercisesProvider } from '@/features/patients/user-actions/export-exercises/components/export-exercises-provider'
import { CreateTreatmentProvider } from '@/features/treatments/user-actions/create-treatment/components/create-treatment-provider'

export function PatientRoute() {
  const { patient } = Route.useLoaderData()
  const { patientId } = Route.useParams()

  return (
    <>
      <ExportExercisesProvider patient={patient}>
        <CreateTreatmentProvider patientId={patientId}>
          <Outlet />
        </CreateTreatmentProvider>
      </ExportExercisesProvider>
    </>
  )
}
