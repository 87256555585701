import { tv } from 'tailwind-variants'

import { focusRing } from '../../utils'

export const fieldBorderStyles = tv({
  extend: focusRing,
  base: 'border-neutral-6 rounded-md border',
  variants: {
    isHovered: {
      true: 'border-neutral-7',
    },
    isFocusWithin: {
      true: 'border-contrast-9',
    },
    isFocused: {
      true: 'border-contrast-9',
    },
    isFocusVisible: {
      true: 'border-neutral-6',
    },
    isInvalid: {
      true: 'border-red-9',
    },
    isDisabled: {
      true: 'border-neutral-6',
    },
  },
})

export const fieldGroupStyles = tv({
  extend: focusRing,
  base: 'border-neutral-6 bg-field group flex h-9 items-center overflow-hidden rounded-md border transition-colors duration-150',
  variants: {
    ...fieldBorderStyles.variants,
    isDisabled: {
      true: `${fieldBorderStyles.variants.isDisabled.true} bg-neutral-3 text-disabled-foreground`,
    },
  },
})

export const fieldStyles = tv({
  base: 'group flex flex-col gap-1 transition duration-150 disabled:pointer-events-none disabled:opacity-40',
})

export const inputStyles = tv({
  base: 'disabled:text-disabled-foreground disabled:bg-neutral-3 bg-field flex min-h-9 min-w-0 flex-1 items-center px-2.5 text-sm outline outline-0 transition-colors duration-150',
})
