import { createRedirectToAuth } from '@fysioscout/fysioscout-js/auth'

import { env } from '@/config/env'
import { removeSession } from '@/stores/auth-store'

/**
 * Redirects the user to the login page with a safely encoded redirect URL.
 *
 * @example
 *   redirectToAuth({ redirectTo: 'https://www.fysioscout.dk' })
 *
 * @param params - The parameters for redirecting the user to the auth site.
 * @param params.afterLoginRedirectUrl - The URL to redirect the user to after login.
 */
export const redirectToAuth = createRedirectToAuth(env.VITE_AUTH_URL)

/**
 * Handles the logout process.
 *
 * This function removes the JWT from the memory store and redirects the user to the login page.
 *
 * @see authStore
 */
export function handleLogout(): void {
  removeSession()
  redirectToAuth()
}
