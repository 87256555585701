import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { match } from 'ts-pattern'

import type { Locale } from '@fysioscout/env'
import type { ConfigType, Dayjs } from 'dayjs'

import 'dayjs/locale/da'
import 'dayjs/locale/en'

export interface DateService {
  format: (date: ConfigType, dateStyle?: 'full' | 'long' | 'short') => string
  instance: (date?: ConfigType) => Dayjs
  to: (date: ConfigType) => string
}

/**
 * Creates a date service to format and parse dates using dayjs.
 *
 * @param locale - The locale to use for the date service
 * @returns A date service
 * @see {@link https://day.js.org/docs/en/installation/installation}
 */
export function createDateService(locale: Locale = 'da'): DateService {
  dayjs.extend(relativeTime)

  const localDayjs = (date?: ConfigType): Dayjs => {
    return dayjs(date).locale(locale)
  }

  const to = (date: ConfigType): string => {
    return localDayjs().to(date)
  }

  /**
   * Format a date using dayjs
   *
   * @param date - The date to format
   * @param dateStyle - The style of the date
   * @returns The formatted date with default format DD. mmmm YYYY
   */
  function format(date: ConfigType, dateStyle: 'full' | 'long' | 'short' = 'long'): string {
    const dateTemplate = match(dateStyle)
      .with('full', () => 'DD. MMMM YYYY')
      .with('long', () => 'DD. MMM YYYY')
      .with('short', () => 'DD.MM.YYYY')
      .exhaustive()

    return localDayjs(date).format(dateTemplate)
  }

  return {
    format,
    instance: localDayjs,
    to,
  }
}
