import React from 'react'

import { Drawer } from '@fysioscout/ui/overlays/drawer'

import { useCreateVideoConsultationActions, useIsOpen } from '../store'
import { CreateVideoConsultationDialog } from './create-video-consultation-dialog'

export function CreateVideoConsultationDrawer() {
  const actions = useCreateVideoConsultationActions()
  const isOpen = useIsOpen()

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.onOpenChange} size={'2'}>
      <CreateVideoConsultationDialog />
    </Drawer>
  )
}
