import { createFileRoute, redirect } from '@tanstack/react-router'

import { TemplatesView } from '@/app/routes/_auth/templates/-templates-view'

export const Route = createFileRoute('/_auth/templates/')({
  beforeLoad: ({ context: { hypertune } }) => {
    const isEnabled = hypertune.enableTemplatesPage({ fallback: false })

    if (!isEnabled) {
      throw redirect({ to: '/patients' })
    }
  },
  component: TemplatesView,
})
