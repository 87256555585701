import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useChangePractitioner } from '@/features/patients/user-actions/change-practitioner/context'

export interface ChangePractitionerStore {
  isOpen: boolean
  patient: Schemas['PatientWithEmployee'] | null
  practitioner: Schemas['ClinicEmployeesDetails'] | null
  actions: {
    open: () => void
    onOpenChange: (isOpen: boolean) => void
    load: (data: {
      patient: Schemas['PatientWithEmployee']
      practitioner: Schemas['ClinicEmployeesDetails'] | null
    }) => void
    reset: () => void
  }
}

export type InitialState = Omit<ChangePractitionerStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  patient: null,
  practitioner: null,
} satisfies InitialState

export const createChangePractitionerStore = (initialState: InitialState = INITIAL_STATE) =>
  createStore<ChangePractitionerStore>((set) => ({
    ...initialState,
    actions: {
      open: () => {
        set({ isOpen: true })
      },
      onOpenChange: (isOpen) => {
        set({ isOpen })
      },
      load: ({ patient, practitioner }) => {
        set({ patient, practitioner })
      },
      reset: () => INITIAL_STATE,
    },
  }))

function useChangePractitionerStore<T>(selector: (store: ChangePractitionerStore) => T): T {
  const { store } = useChangePractitioner()

  return useStore(store, selector)
}

export function usePatient() {
  return useChangePractitionerStore((state) => state.patient)
}

export function usePractitioner() {
  return useChangePractitionerStore((state) => state.practitioner)
}

export function useIsOpen() {
  return useChangePractitionerStore((state) => state.isOpen)
}

export function useChangePractitionerActions() {
  return useChangePractitionerStore((state) => state.actions)
}
