import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useExportExercisesContext } from '@/features/patients/user-actions/export-exercises/context'

export interface ExportExercisesStore {
  isOpen: boolean
  treatment: Schemas['TreatmentDto'] | undefined
  actions: {
    open: () => void
    close: () => void
    onOpenChange: (isOpen: boolean) => void
    setTreatment: (treatment: Schemas['TreatmentDto']) => void
    reset: () => void
  }
}

export type InitialState = Omit<ExportExercisesStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  treatment: undefined,
} satisfies InitialState

export const createExportExercisesStore = (initialState: InitialState = INITIAL_STATE) =>
  createStore<ExportExercisesStore>((set) => ({
    ...initialState,
    actions: {
      open: () => {
        set({ isOpen: true })
      },
      close: () => {
        set({ isOpen: false })
      },
      onOpenChange: (isOpen) => {
        set({ isOpen })
      },
      setTreatment: (treatment) => {
        set({ treatment })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))

function useExportExercisesStore<T>(selector: (store: ExportExercisesStore) => T): T {
  const { store } = useExportExercisesContext()

  return useStore(store, selector)
}

export function useTreatment() {
  return useExportExercisesStore((state) => state.treatment)
}

export function useIsOpen() {
  return useExportExercisesStore((state) => state.isOpen)
}

export function useExportExercisesActions() {
  return useExportExercisesStore((state) => state.actions)
}
