import React from 'react'

import type { CreateClinicPatientResponse } from '@/api/fysioscout/endpoints/clinic-patient/models'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { Switch } from '@fysioscout/ui/forms/switch'
import { TextField } from '@fysioscout/ui/forms/text-field'
import { Callout } from '@fysioscout/ui/status/callout'
import { toast } from '@fysioscout/ui/status/toast'

import { useCreateClinicPatient } from '@/features/patients/api/create-clinic-patient'

const FIELDS = {
  name: 'name',
  email: 'email',
  chat: 'chat',
}

interface CreateNewPatientFormProps {
  /** Optional CSS class names to apply. */
  className?: string

  /** Success callback to invoke when the form is submitted successfully. */
  onSuccess?: (response: CreateClinicPatientResponse) => void
}

export function CreateNewPatientForm(props: CreateNewPatientFormProps) {
  const mutation = useCreateClinicPatient({ redirectToPatient: true })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const name = String(formData.get(FIELDS.name))
    const email = String(formData.get(FIELDS.email))
    const is_chat_enabled = formData.get(FIELDS.chat) === 'on'

    mutation.mutate(
      { name, email, is_chat_enabled },
      {
        onSuccess: (response) => {
          toast.success(`${name} er nu oprettet.`, {
            description:
              'Patienten skal nu acceptere invitationen for at blive tilknyttet klinikken.',
          })

          props.onSuccess?.(response)
        },
      },
    )
  }

  return (
    <div data-testid={'create-new-patient-form'} className={props.className}>
      <Form onSubmit={handleSubmit}>
        <TextField autoFocus label={'Navn'} name={FIELDS.name} type={'text'} isRequired />
        <TextField label={'Email'} name={FIELDS.email} type={'email'} isRequired />
        <Switch name={FIELDS.chat}>Aktivér chat til patienten</Switch>

        <Callout type={'accent'}>
          Hvis patienten allerede eksisterer i FysioScout appen med deres e-mail skal du trykke
          &quot;tilknyt eksisterende patient&quot;, for at tilknytte patienten til din klinik.
        </Callout>

        <Button variant={'solid'} color={'accent'} type={'submit'} isPending={mutation.isPending}>
          Opret patient
        </Button>
      </Form>
    </div>
  )
}
