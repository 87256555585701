'use client'

import React from 'react'
import { Form as RACForm } from 'react-aria-components'

import type { FormProps } from 'react-aria-components'

import { cn } from '../../utils'

export const Form = React.forwardRef<React.ElementRef<typeof RACForm>, FormProps>((props, ref) => (
  <RACForm {...props} ref={ref} className={cn('stack gap-4 text-left', props.className)} />
))

Form.displayName = 'Form'
