import React, { Suspense } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
  useRouter,
} from '@tanstack/react-router'
import { RouterProvider, useLocale } from 'react-aria-components'

import type { useHypertune } from '@fysioscout/hypertune/hypertune.react'
import type { QueryClient } from '@tanstack/react-query'

import { Toaster } from '@fysioscout/ui/status/toast'
import { useThemeContext } from '@fysioscout/widgets/theme'

import { env } from '@/config/env'
import { useTransitionBlockerStyle } from '@/hooks/use-transition-blocker-style'

export interface AppRouterContext {
  /** Query client used for the application. */
  queryClient: QueryClient

  /** Feature flags used for conditional rendering. */
  hypertune: ReturnType<typeof useHypertune>
}

export const Route = createRootRouteWithContext<AppRouterContext>()({
  component: RootLayout,
})

function RootLayout() {
  const router = useRouter()
  const { locale, direction } = useLocale()
  const { theme } = useThemeContext()

  React.useEffect(() => {
    document.documentElement.lang = locale
    document.documentElement.dir = direction
  }, [direction, locale])

  return (
    <div data-testid={'root-view'} lang={locale} dir={direction}>
      <ScrollRestoration />

      {env.VITE_DISABLE_ANIMATIONS ? <TransitionBlocker /> : null}

      <Toaster
        theme={theme}
        toastOptions={{ classNames: { toast: 'gap-3' } }}
        position={'bottom-center'}
      />

      <RouterProvider
        navigate={(to, options) => router.navigate({ to, ...options })}
        useHref={(to) => router.buildLocation({ to }).href}
      >
        <Outlet />
      </RouterProvider>

      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>

      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

function TransitionBlocker() {
  useTransitionBlockerStyle()

  return null
}

const LazyTanStackRouterDevtools = React.lazy(() =>
  import('@tanstack/router-devtools').then((res) => ({
    default: res.TanStackRouterDevtools,
  })),
)

export function TanStackRouterDevtools() {
  if (import.meta.env.PROD) {
    return null
  }

  return <LazyTanStackRouterDevtools />
}
