import React from 'react'

import { EditExerciseDrawer } from '@/features/treatments/user-actions/edit-exercise/components/edit-exercise-drawer'
import { EditExerciseProvider } from '@/features/treatments/user-actions/edit-exercise/components/edit-exercise-provider'

interface EditExercisesProps {
  children?: React.ReactNode
}

export function EditExercises({ children }: EditExercisesProps) {
  return (
    <EditExerciseProvider>
      <EditExerciseDrawer />

      {children}
    </EditExerciseProvider>
  )
}
