import { snakeKeys } from 'string-ts'

import type {
  CreateClinicTreatmentOptions,
  EditClinicTreatmentOptions,
} from '@/api/fysioscout/endpoints/clinic-treatment/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTreatmentMutations = {
  create: (body: CreateClinicTreatmentOptions['body']) => {
    return unwrap(fysioscoutClient.POST('/api/clinics/patients/treatments', { body }))
  },

  update: (params: {
    path: CamelKeys<EditClinicTreatmentOptions['params']['path']>
    body: CamelKeys<EditClinicTreatmentOptions['body']>
  }) => {
    return unwrap(
      fysioscoutClient.PUT('/api/clinics/patients/{patient_id}/treatments/{treatment_id}', {
        params: { path: snakeKeys(params.path) },
        body: snakeKeys(params.body),
      }),
    )
  },
}
