import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSpinDelay } from 'spin-delay'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'
import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

import { CreateVideoConsultationContext } from '../context'
import { createVideoConsultationStore } from '../store'

interface CreateVideoConsultationProviderProps {
  children?: React.ReactNode
}

export function CreateVideoConsultationProvider({
  children,
}: CreateVideoConsultationProviderProps) {
  const [store] = React.useState(() => createVideoConsultationStore())
  const employeesQuery = useQuery(clinicEmployeeQueries.list())
  const patientsQuery = useQuery(clinicPatientQueries.list('accepted'))

  const employees = employeesQuery.data ?? []
  const patients = patientsQuery.data?.employee_patients.flatMap(({ patients }) => patients) ?? []

  const isLoading = useSpinDelay(employeesQuery.isLoading || patientsQuery.isLoading, {
    minDuration: 400,
    delay: 0,
  })

  return (
    <CreateVideoConsultationContext.Provider value={{ store, employees, patients, isLoading }}>
      {children}
    </CreateVideoConsultationContext.Provider>
  )
}
