'use client'

import { ModalOverlay as RACModalOverlay } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { ModalOverlayProps as RACModalOverlayProps } from 'react-aria-components'

const modalOverlayStyles = tv({
  base: 'bg-blackA-6 fixed left-0 top-0 isolate z-20 flex h-[--visual-viewport-height] w-full items-center justify-center backdrop-blur',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in',
    },
  },
})

export type ModalOverlayProps = RACModalOverlayProps

export function ModalOverlay(props: ModalOverlayProps) {
  return <RACModalOverlay {...props} className={modalOverlayStyles} />
}
