import React from 'react'

type ProviderComponent = React.JSXElementConstructor<{ children: React.ReactNode }>

interface ComposeProvidersProps {
  providers: ProviderComponent[]
  children: React.ReactNode
}

/**
 * Composes multiple providers and wraps the children with them. If the providers array is empty,
 * the children are rendered as is.
 *
 * @param params - The parameters for composing the providers.
 * @param params.providers - The providers to compose.
 * @param params.children - The children to wrap with the composed providers.
 * @returns - The children wrapped by the composed providers.
 */
export function ComposeProviders({ providers, children }: ComposeProvidersProps) {
  const renderProviders = (
    providers: ProviderComponent[],
    children: React.ReactNode,
  ): React.ReactNode => {
    if (providers.length === 0) return children

    const [Provider, ...rest] = providers

    if (!Provider) {
      return children
    }

    return <Provider>{renderProviders(rest, children)}</Provider>
  }

  return renderProviders(providers, children)
}
