import { createStore, useStore } from 'zustand'

import { useCreatePatient } from '@/features/patients/user-actions/create-patient/context'

export interface CreatePatientStore {
  isOpen: boolean
  patientType: 'new' | 'pdf' | 'existing' | null
  actions: {
    open: () => void
    close: () => void
    onOpenChange: (isOpen: boolean) => void
    selectPatientType: (type: 'new' | 'pdf' | 'existing' | null) => void
    reset: () => void
  }
}

type InitialState = Omit<CreatePatientStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  patientType: null,
} satisfies InitialState

export const createPatientStore = (initialState: InitialState = INITIAL_STATE) =>
  createStore<CreatePatientStore>((set) => ({
    ...initialState,
    actions: {
      open: () => {
        set({ isOpen: true })
      },
      close: () => {
        set({ isOpen: false })
      },
      onOpenChange: (isOpen) => {
        set({ isOpen })
      },
      selectPatientType: (type) => {
        set({ patientType: type })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))

function useCreatePatientStore<T>(selector: (state: CreatePatientStore) => T) {
  const { store } = useCreatePatient()

  return useStore(store, selector)
}

export function useIsOpen() {
  return useCreatePatientStore((state) => state.isOpen)
}

export function usePatientType() {
  return useCreatePatientStore((state) => state.patientType)
}

export function useCreatePatientActions() {
  return useCreatePatientStore((state) => state.actions)
}
