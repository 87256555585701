import { createStrictContext } from '@fysioscout/utils/react'

export interface CollapsiblePanelContextValue {
  isDisabled?: boolean
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const [CollapsiblePanelContext, useCollapsiblePanelContext] =
  createStrictContext<CollapsiblePanelContextValue>({
    name: 'CollapsiblePanelContext',
    errorMessage:
      'useCollapsiblePanelContext must be used within a CollapsiblePanelContext.Provider.',
    allowMissingProvider: false,
  })
