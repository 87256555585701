import { queryOptions } from '@tanstack/react-query'
import { snakeKeys } from 'string-ts'

import type { Params } from '@fysioscout/fysioscout-js/type-helpers'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicNotebookQueries = {
  all: () => ['clinic-notebook'],
  lists: () => [...clinicNotebookQueries.all(), 'lists'],

  /**
   * List all notes for a clinic
   *
   * @param path - The path parameters
   * @param options - The options for the query
   * @param options.enabled - Whether the query should be enabled
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Notebook/paths/~1api~1clinics~1notebooks~1%7Btreatment_id%7D/get}
   */
  listNotebooks: (
    path: CamelKeys<Params<'get', '/api/clinics/notebooks/{treatment_id}'>['path']>,
    options?: { enabled?: boolean },
  ) =>
    queryOptions({
      queryKey: [...clinicNotebookQueries.lists(), 'notebooks', path],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/notebooks/{treatment_id}', {
            signal,
            params: { path: snakeKeys(path) },
          }),
        )
      },
      ...options,
    }),
}
