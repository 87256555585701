import { Drawer } from '@fysioscout/ui/overlays/drawer'

import {
  useAddExercisesActions,
  useIsOpen,
} from '@/features/treatments/user-actions/add-exercise/store'

import { AddExercisesDialog } from './add-exercises-dialog'

export function AddExercisesDrawer() {
  const actions = useAddExercisesActions()
  const isOpen = useIsOpen()

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.onOpenChange} size={'4'}>
      <AddExercisesDialog />
    </Drawer>
  )
}
