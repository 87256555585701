import { snakeKeys } from 'string-ts'

import type {
  SendExercisesPdfEmailOptions,
  UpdateClinicExerciseOptions,
} from '@/api/fysioscout/endpoints/clinic-exercise/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicExerciseMutations = {
  update: (params: {
    id: UpdateClinicExerciseOptions['params']['path']['id']
    body: UpdateClinicExerciseOptions['body']
  }) => {
    return unwrap(
      fysioscoutClient.PATCH('/api/clinics/exercises/{id}', {
        params: { path: { id: params.id } },
        body: params.body,
      }),
    )
  },

  /**
   * Send the PDF version of exercises to email
   *
   * @param body - The request body.
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Exercise/paths/~1api~1clinics~1exercises~1pdf~1send-email/post}
   */
  sendExercisesPdfEmail: (body: CamelKeys<SendExercisesPdfEmailOptions['body']>) => {
    return unwrap(
      fysioscoutClient.POST('/api/clinics/exercises/pdf/send-email', { body: snakeKeys(body) }),
    )
  },
}
