import React from 'react'

import type { DetachPatientContextValue } from '@/features/patients/user-actions/detach-patient/context'

import { DetachPatientDialog } from '@/features/patients/user-actions/detach-patient/components/detach-patient-dialog'
import { DetachPatientContext } from '@/features/patients/user-actions/detach-patient/context'
import { createDetachPatientFromClinicStore } from '@/features/patients/user-actions/detach-patient/store'

interface DetachPatientProviderProps extends Omit<DetachPatientContextValue, 'store'> {
  children?: React.ReactNode
}

export function DetachPatientProvider({ children, onSuccess }: DetachPatientProviderProps) {
  const [store] = React.useState(() => createDetachPatientFromClinicStore())

  return (
    <DetachPatientContext.Provider value={{ store, onSuccess }}>
      <DetachPatientDialog />

      {children}
    </DetachPatientContext.Provider>
  )
}
