import React from 'react'
import { RouterProvider as TanStackRouterProvider } from '@tanstack/react-router'

import type { RouterProps } from '@tanstack/react-router'

import { useHypertune } from '@fysioscout/hypertune/hypertune.react'

export function RouterProvider({ router }: Pick<RouterProps, 'router'>) {
  const hypertune = useHypertune()

  return <TanStackRouterProvider router={router} context={{ hypertune }} />
}
