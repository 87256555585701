import React from 'react'

import { addTransitionBlockerStyle } from '@fysioscout/utils/dom'

/**
 * Adds a transition blocker style to the document body using the `addTransitionBlockerStyle`
 * function upon component mount. Removes the transition blocker style from the document body using
 * the returned `removeTransitionBlocker` function upon component unmount.
 */
export function useTransitionBlockerStyle(): void {
  React.useEffect(() => {
    const removeTransitionBlocker = addTransitionBlockerStyle()

    return () => {
      removeTransitionBlocker()
    }
  }, [])
}
