import { queryOptions } from '@tanstack/react-query'

import type { GetClinicPatientsOptions } from '@/api/fysioscout/endpoints/clinic-patient/models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicPatientQueries = {
  all: () => ['clinic-patients'],
  lists: () => [...clinicPatientQueries.all(), 'lists'],

  /**
   * List clinic patients
   *
   * @param status - Filter by status
   * @returns The query option
   */
  list: (status: GetClinicPatientsOptions['params']['query']['status']) => {
    return queryOptions({
      queryKey: [...clinicPatientQueries.lists(), status],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/web/v1/clinics/patients', {
            signal,
            params: { query: { status } },
          }),
        )
      },
    })
  },

  /**
   * Get clinic patient by id
   *
   * @param patientId - The id of the clinic patient
   * @returns The query option
   */
  getById: (patientId: string) => {
    return queryOptions({
      queryKey: [...clinicPatientQueries.all(), patientId],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/patients/{patient_id}', {
            signal,
            params: { path: { patient_id: patientId } },
          }),
        )
      },
    })
  },
}
