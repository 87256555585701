import { queryOptions } from '@tanstack/react-query'
import ms from 'ms'
import { snakeKeys } from 'string-ts'

import type {
  GetClinicTreatmentExerciseOptions,
  GetClinicTreatmentOptions,
  GetClinicTreatmentsOptions,
} from '@/api/fysioscout/endpoints/clinic-treatment/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTreatmentQueries = {
  all: () => ['clinic-treatments'],
  lists: () => [...clinicTreatmentQueries.all(), 'lists'],

  /**
   * List all treatments for a patient
   *
   * @param patientId - The ID of the patient
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments/get}
   */
  treatments: (patientId: GetClinicTreatmentsOptions['params']['path']['patient_id']) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.lists(), patientId],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/patients/{patient_id}/treatments', {
            signal,
            params: {
              path: {
                patient_id: patientId,
              },
            },
          }),
        )
      },
    })
  },

  /**
   * Get a single treatment for a patient
   *
   * @param params - The path parameters
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments~1%7Btreatment_id%7D/get}
   */
  treatment: (params: CamelKeys<GetClinicTreatmentOptions['params']['path']>) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.all(), 'treatment', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/patients/{patient_id}/treatments/{treatment_id}', {
            signal,
            params: {
              path: snakeKeys(params),
            },
          }),
        )
      },
      refetchInterval: ms('10s'),
    })
  },

  /**
   * Get exercise by id of a particular treatment
   *
   * @param params - The path parameters
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments~1%7Btreatment_id%7D~1exercises~1%7Bexercise_id%7D/get}
   */
  exercise: (params: CamelKeys<GetClinicTreatmentExerciseOptions['params']['path']>) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.all(), 'exercise', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET(
            '/api/clinics/patients/{patient_id}/treatments/{treatment_id}/exercises/{exercise_id}',
            {
              signal,
              params: {
                path: snakeKeys(params),
              },
            },
          ),
        )
      },
    })
  },
}
