import type { EditClinicTreatmentOptions } from '@/api/fysioscout/endpoints/clinic-treatment/models'
import type { EditTreatmentNameStore } from '@/features/treatments/user-actions/edit-treatment-name/store'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { CamelKeys } from 'string-ts'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export interface EditTreatmentNameContextValue
  extends CamelKeys<Pick<EditClinicTreatmentOptions['params']['path'], 'patient_id'>> {
  treatment?: Schemas['TreatmentDto']
  store: StoreApi<EditTreatmentNameStore>
}

export const [EditTreatmentNameContext, useEditTreatmentNameContext] =
  createStrictContext<EditTreatmentNameContextValue>({
    name: 'EditTreatmentNameStore',
    errorMessage: 'useEditTreatmentNameContext must be used within a EditTreatmentNameProvider',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
