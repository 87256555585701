import type { StoreApi } from 'zustand'
import type { ChangePatientEmailStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

export interface ChangeEmailContextValue {
  /** The store object for the detach patient use case. */
  store: StoreApi<ChangePatientEmailStore>

  /**
   * Callback function to be invoked when the detach patient operation is successful.
   *
   * @default undefined
   */
  onSuccess?: () => void
}

export const [ChangeEmailContext, useChangeEmailContext] =
  createStrictContext<ChangeEmailContextValue>({
    name: 'ChangeEmailStore',
    errorMessage: 'useChangeEmailContext must be used within a ChangeEmailProvider',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
