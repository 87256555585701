'use client'

import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { mergeProps, useFocusRing, useHover, usePress } from 'react-aria'
import { tv } from 'tailwind-variants'

import { focusRing } from '../../utils'

const styles = tv({
  extend: focusRing,
  base: 'transition-colors duration-100',
})

interface RenderProps {
  isHovered: boolean
  isFocusVisible: boolean
  isFocused: boolean
  isPressed: boolean
}

interface UnstyledLinkOwnProps {
  /**
   * Children of the link button. Can be a render function that receives the hover, focus, and press
   * states.
   */
  children?: React.ReactNode | ((props: RenderProps) => React.ReactNode)

  /** Optional CSS class name for the link button. */
  className?: string
}

export type UnstyledLinkProps = UnstyledLinkOwnProps

export function UnstyledLink({ children, className, ...rest }: UnstyledLinkProps) {
  const { focusProps, isFocusVisible, isFocused } = useFocusRing()
  const { isHovered, hoverProps } = useHover({})
  const { pressProps, isPressed } = usePress({})

  const classes = styles({ isFocusVisible, className })

  return (
    <Slot
      {...rest}
      {...mergeProps(hoverProps, focusProps, pressProps)}
      data-focus-visible={isFocusVisible || undefined}
      data-focused={isFocused || undefined}
      data-hovered={isHovered || undefined}
      data-pressed={isPressed || undefined}
      className={classes}
    >
      {typeof children === 'function'
        ? children({ isHovered, isFocused, isFocusVisible, isPressed })
        : children}
    </Slot>
  )
}
