import { createFileRoute, redirect } from '@tanstack/react-router'

import { PractitionersView } from './-practitioners-view'

export const Route = createFileRoute('/_auth/practitioners/')({
  beforeLoad: ({ context: { hypertune } }) => {
    const isEnabled = hypertune.enablePractitionersPage({ fallback: false })

    if (!isEnabled) {
      throw redirect({ to: '/patients' })
    }
  },
  meta: () => [{ title: 'Behandlere' }],
  component: PractitionersView,
})
