import type { StoreApi } from 'zustand'
import type { EditExercisesStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

export const [EditExercisesContext, useEditExercisesContext] = createStrictContext<{
  store: StoreApi<EditExercisesStore>
}>({
  name: 'EditExercisesContext',
  errorMessage: 'useEditExercisesContext must be used within a EditExercisesContext.Provider.',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
