import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { createStrictContext } from '@fysioscout/utils/react'

interface TreatmentContextValue {
  /** Unique identifier for the patient. */
  patientId: Schemas['PatientInfo']['id']

  /** The treatment. */
  treatmentId: Schemas['TreatmentDto']['id']
}

export const [TreatmentContext, useTreatmentContext] = createStrictContext<TreatmentContextValue>({
  name: 'TreatmentContext',
  errorMessage: 'useTreatmentContext must be used within a TreatmentContext.Provider.',
  allowMissingProvider: false,
  isDevelopment: import.meta.env.DEV,
})
