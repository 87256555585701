'use client'

import React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { motion } from 'framer-motion'
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react'
import { mergeProps, useFocusRing, useHover, useLink, useObjectRef } from 'react-aria'
import { tv } from 'tailwind-variants'

import type { AriaLinkOptions } from 'react-aria'
import type { VariantProps } from 'tailwind-variants'

import { focusRing } from '../../utils'

const linkStyles = tv({
  extend: focusRing,
  base: 'inline-flex items-center gap-2 self-start rounded transition disabled:cursor-not-allowed',
  variants: {
    variant: {
      primary: 'text-contrast-10 hover:text-contrast-11',
    },
    intent: {
      default: 'underline',
      back: '',
      forward: '',
    },
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      base: 'text-base',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'sm',
  },
})

type LinkVariants = VariantProps<typeof linkStyles>

interface LinkOwnProps {
  children?: React.ReactNode
  className?: string
}

export interface LinkProps extends LinkOwnProps, LinkVariants, AriaLinkOptions {}

export const Link = React.forwardRef<React.ElementRef<'a'>, LinkProps>(
  ({ intent, variant, size, children, className, ...rest }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)

    const { isFocusVisible, focusProps } = useFocusRing({ within: true, ...rest })
    const { isHovered, hoverProps } = useHover({})
    const { isPressed, linkProps } = useLink(rest, ref)

    const classes = linkStyles({
      intent,
      isFocusVisible,
      size,
      variant,
      className,
    })

    const arrowLeft =
      intent === 'back' ? (
        <motion.div
          variants={{ initial: { x: 0 }, hovered: { x: -4 } }}
          initial={'initial'}
          animate={isHovered ? 'hovered' : 'initial'}
        >
          <ArrowLeftIcon className={'size-[1em]'} />
        </motion.div>
      ) : null

    const arrowRight =
      intent === 'forward' ? (
        <motion.div
          variants={{ initial: { x: 0 }, hovered: { x: 4 } }}
          initial={'initial'}
          animate={isHovered ? 'hovered' : 'initial'}
        >
          <ArrowRightIcon className={'size-[1em]'} />
        </motion.div>
      ) : null

    return (
      <Slot
        data-testid={'link'}
        ref={ref}
        className={classes}
        data-focus-visible={isFocusVisible || undefined}
        data-hovered={isHovered || undefined}
        data-pressed={isPressed || undefined}
        {...mergeProps(hoverProps, focusProps, linkProps)}
      >
        {arrowLeft}
        <Slottable>{children}</Slottable>
        {arrowRight}
      </Slot>
    )
  },
)

Link.displayName = 'Link'
