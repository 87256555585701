import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { CircleXIcon } from 'lucide-react'
import { useSpinDelay } from 'spin-delay'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'
import { Callout } from '@fysioscout/ui/status/callout'
import { Spinner } from '@fysioscout/ui/status/spinner'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { useTreatmentContext } from '@/features/treatments/contexts/treatment-context'
import { EditExerciseForm } from '@/features/treatments/user-actions/edit-exercise/components/edit-exercise-form'

interface EditExerciseDialogProps {
  exerciseId: Schemas['ExerciseDto']['id']
}

export function EditExerciseDialog({ exerciseId }: EditExerciseDialogProps) {
  const { patientId, treatmentId } = useTreatmentContext()

  const query = useQuery(clinicTreatmentQueries.exercise({ exerciseId, patientId, treatmentId }))

  const isLoading = useSpinDelay(query.isLoading, { minDuration: 400, delay: 0 })

  if (isLoading) {
    return (
      <Dialog
        data-testid={'edit-exercise-dialog-loading'}
        className={'stack center text-subtle-foreground h-full gap-2'}
        aria-label={'Indlæser øvelse'}
      >
        <Spinner />
        <Text size={'2'}>Indlæser øvelse..</Text>
      </Dialog>
    )
  }

  if (query.data) {
    const exercise = query.data.exercise

    return (
      <Dialog data-testid={'edit-exercise-dialog'} className={'stack h-full outline-none'}>
        {({ close }) => (
          <>
            <DialogCloseButton onPress={close} aria-label={`Luk ${exercise.name ?? 'øvelse'}`} />

            <div className={'mt-8'}>
              {/*<MuxPlayer*/}
              {/*  disableTracking*/}
              {/*  className={'aspect-video w-full overflow-hidden rounded-lg'}*/}
              {/*  playbackId={'uNbxnGLKJ00yfbijDO8COxTOyVKT01xpxW'}*/}
              {/*  streamType={'on-demand'}*/}
              {/*  autoPlay={autoPlay}*/}
              {/*  muted*/}
              {/*  preload={'auto'}*/}
              {/*/>*/}

              <div className={'stack mb-4'}>
                {exercise.name ? (
                  <Heading slot={'title'} medium size={'5'}>
                    {exercise.name}
                  </Heading>
                ) : null}
              </div>

              {/* @TODO: Fix this abomination */}
              <div
                className={
                  'bg-neutral-3 text-subtle-foreground stack center aspect-video w-full overflow-hidden rounded-lg'
                }
              >
                {exercise.video ? (
                  <Video videoSrc={exercise.video} />
                ) : exercise.image ? (
                  <img
                    src={exercise.image}
                    alt={exercise.name ?? ''}
                    className={'w-full object-cover'}
                  />
                ) : exercise.video_thumbnail ? (
                  <img
                    src={exercise.video_thumbnail}
                    alt={exercise.name ?? ''}
                    className={'w-full object-cover'}
                  />
                ) : (
                  <CircleXIcon className={'size-5'} />
                )}
              </div>

              <EditExerciseForm exercise={exercise} />
            </div>
          </>
        )}
      </Dialog>
    )
  }

  return (
    <Dialog
      data-testid={'edit-exercise-dialog-error'}
      className={'stack center h-full'}
      aria-label={'Øvelse kunne ikke indlæses'}
    >
      <Callout type={'error'} size={'1'}>
        <Text>Der skete en fejl ved indlæsning af øvelse.</Text>
      </Callout>
    </Dialog>
  )
}

interface VideoProps {
  videoSrc?: string
  className?: string
}

export function Video(props: VideoProps) {
  return (
    <video className={cn('size-full object-cover')} controls muted preload={'metadata'}>
      <source src={props.videoSrc} type={'video/mp4'} />
      Your browser does not support the video tag.
    </video>
  )
}
