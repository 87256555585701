'use client'

import { getLocalTimeZone, today } from '@internationalized/date'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import {
  Calendar as RACCalendar,
  CalendarCell as RACCalendarCell,
  CalendarGrid as RACCalendarGrid,
  CalendarGridBody as RACCalendarGridBody,
  CalendarGridHeader as RACCalendarGridHeader,
  CalendarHeaderCell as RACCalendarHeaderCell,
  Heading as RACHeading,
  Text as RACText,
  useLocale,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { DateValue, CalendarProps as RACCalendarProps } from 'react-aria-components'

import { Button } from '../../buttons/button/button'
import { focusRing } from '../../utils'

const cellStyles = tv({
  extend: focusRing,
  base: 'flex size-9 cursor-default items-center justify-center rounded-full text-xs',
  variants: {
    isSelected: {
      false: 'text-subtle-foreground hover:bg-neutral-3',
      true: [
        'bg-accent-9 text-accent-contrast hover:bg-accent-10 pressed:bg-accent-9',
        'invalid:bg-red-9 invalid:text-red-contrast invalid:pressed:bg-red-9',
      ],
    },
    isToday: {
      true: 'bg-neutral-5 hover:bg-neutral-6 pressed:bg-neutral-7',
    },
    isDisabled: {
      true: 'text-disabled-foreground invalid:text-red-12',
    },
  },
  compoundVariants: [
    {
      isToday: true,
      isSelected: true,
      className: 'bg-accent hover:bg-accent-10',
    },
  ],
})

export interface CalendarProps<T extends DateValue>
  extends Omit<RACCalendarProps<T>, 'visibleDuration'> {
  errorMessage?: string
}

export function Calendar<T extends DateValue>({ errorMessage, ...props }: CalendarProps<T>) {
  return (
    <RACCalendar {...props}>
      <CalendarHeader />

      <RACCalendarGrid className={'[&_td]:px-0'}>
        <CalendarGridHeader />

        <RACCalendarGridBody>
          {(date) => (
            <RACCalendarCell
              data-today={date.compare(today(getLocalTimeZone())) === 0}
              date={date}
              className={(renderProps) =>
                cellStyles({
                  ...renderProps,
                  isToday: date.compare(today(getLocalTimeZone())) === 0,
                })
              }
            />
          )}
        </RACCalendarGridBody>
      </RACCalendarGrid>

      {errorMessage ? (
        <RACText slot={'errorMessage'} className={'text-red text-sm'}>
          {errorMessage}
        </RACText>
      ) : null}
    </RACCalendar>
  )
}

export function CalendarHeader() {
  const { direction } = useLocale()

  return (
    <header className={'flex w-full items-center gap-1 px-1 pb-4'}>
      <Button variant={'ghost'} color={'neutral'} slot={'previous'} size={'sm'}>
        {direction === 'rtl' ? <ChevronRight aria-hidden /> : <ChevronLeft aria-hidden />}
      </Button>

      <RACHeading className={'mx-2 flex-1 text-center text-base font-semibold capitalize'} />

      <Button variant={'ghost'} color={'neutral'} slot={'next'} size={'sm'}>
        {direction === 'rtl' ? <ChevronLeft aria-hidden /> : <ChevronRight aria-hidden />}
      </Button>
    </header>
  )
}

export function CalendarGridHeader() {
  return (
    <RACCalendarGridHeader>
      {(day) => (
        <RACCalendarHeaderCell className={'text-neutral-12 text-center text-xs font-semibold'}>
          {day}
        </RACCalendarHeaderCell>
      )}
    </RACCalendarGridHeader>
  )
}
