import React from 'react'
import { createFileRoute } from '@tanstack/react-router'

import { Spinner } from '@fysioscout/ui/status/spinner'

import { AuthLayout } from '@/components/layouts/auth-layout'

export const Route = createFileRoute('/_auth')({
  component: AuthLayout,
  pendingComponent: () => (
    <div className={'stack center min-h-dvh -translate-y-16'}>
      <Spinner className={'size-5'} aria-label={'Indlæser...'} />
    </div>
  ),
})
