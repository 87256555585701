import { useMutation } from '@tanstack/react-query'

import { clinicJournalMutations } from '@/api/fysioscout/endpoints/clinic-journal/mutations'

/**
 * Generates a URL to view a journal for a given treatment.
 *
 * @example
 *   const { mutate, isLoading, isError } = useCopyJournalUrl()
 *   mutate(generateJournalUrlOptions)
 *
 * @returns - The mutation object
 * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Journal/paths/~1api~1clinics~1journal~1url~1%7Bid%7D/get}
 */
export function useCopyJournalUrl() {
  return useMutation({
    mutationFn: clinicJournalMutations.generateUrl,
    onSuccess: async (data) => {
      await navigator.clipboard.writeText(data.url)
    },
  })
}
