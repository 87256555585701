import { useTitle } from 'ahooks'

import { PageLayout } from '@/components/layouts/page-layout'

export function PractitionersView() {
  useTitle('Behandlere')

  return (
    <PageLayout>
      <h3>Practitioners view</h3>
    </PageLayout>
  )
}
