import { useKeyPress } from 'ahooks'

import type React from 'react'

/**
 * Focus the search input when the user presses meta+k
 *
 * @param inputRef - The ref object of the search input
 */
export function useSearchHotkey(inputRef: React.RefObject<HTMLInputElement>) {
  useKeyPress('meta.k', () => {
    inputRef.current?.focus()
  })
}
