import React from 'react'

import { CreateTreatmentDialog } from '@/features/treatments/user-actions/create-treatment/components/create-treatment-dialog'
import { CreateTreatmentContext } from '@/features/treatments/user-actions/create-treatment/context'
import { createTreatmentStore } from '@/features/treatments/user-actions/create-treatment/store'

interface CreateTreatmentProviderProps {
  /** The unique identifier for the patient. */
  patientId: string

  /** The child components to render within the treatment provider. */
  children?: React.ReactNode
}

export function CreateTreatmentProvider({ patientId, children }: CreateTreatmentProviderProps) {
  const [store] = React.useState(() => createTreatmentStore())

  return (
    <CreateTreatmentContext.Provider value={{ store, patientId }}>
      <CreateTreatmentDialog />

      {children}
    </CreateTreatmentContext.Provider>
  )
}
