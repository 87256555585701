import type { DetachPatientStore } from '@/features/patients/user-actions/detach-patient/store'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export interface DetachPatientContextValue {
  /** The store object for the detach patient use case. */
  store: StoreApi<DetachPatientStore>

  /**
   * Callback function to be invoked when the detach patient operation is successful.
   *
   * @default undefined
   */
  onSuccess?: () => void
}

export const [DetachPatientContext, useDetachPatientContext] =
  createStrictContext<DetachPatientContextValue>({
    name: 'DetachPatientStore',
    errorMessage: 'useDetachPatientContext must be used within a DetachPatientProvider',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
