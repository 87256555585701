import { tv } from 'tailwind-variants'

import { focusRing } from '../../utils'

export const itemStyles = tv({
  extend: focusRing,
  base: 'group relative flex cursor-default select-none items-center gap-8 rounded-md px-2.5 py-1.5 text-sm will-change-transform',
  variants: {
    isSelected: {
      false: 'hover:bg-neutral-4 -outline-offset-2',
      true: 'bg-blue-5 text-white -outline-offset-4 outline-white [&+[data-selected]]:rounded-t-none [&:has(+[data-selected])]:rounded-b-none',
    },
    isDisabled: {
      true: 'text-disabled-foreground',
    },
  },
})

export const dropdownItemStyles = tv({
  base: 'group flex cursor-default select-none items-center gap-2 rounded-md py-2 pl-3 pr-2 text-xs outline outline-0',
  variants: {
    isDisabled: {
      true: 'text-disabled-foreground',
    },
    isFocused: {
      true: 'bg-neutral-4',
    },
    isLink: {
      true: 'cursor-pointer',
    },
  },
})
