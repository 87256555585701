import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'

import { clinicTreatmentMutations } from '@/api/fysioscout/endpoints/clinic-treatment/mutations'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

interface CreateClinicTreatmentOptions {
  redirectToTreatment?: boolean
}

/**
 * Create a clinic treatment.
 *
 * @example
 *   const { mutate, isLoading, isError } = useCreateClinicTreatment()
 *   mutate(createClinicTreatmentOptions)
 *
 * @param otps - Options for the mutation
 * @param otps.redirectToTreatment - Whether to redirect to the patient's treatment after creation
 * @returns - The result of the mutation.
 */
export function useCreateClinicTreatment({
  redirectToTreatment = false,
}: CreateClinicTreatmentOptions = {}) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicTreatmentMutations.create,
    onSuccess: async (response) => {
      if (response.patient_id && redirectToTreatment) {
        await navigate({
          to: '/patients/$patientId/treatments/$treatmentId',
          params: { patientId: response.patient_id, treatmentId: response.id },
          search: { view: 'exercises' },
        })
      }

      void queryClient.invalidateQueries({ queryKey: clinicTreatmentQueries.lists() })
    },
  })
}
