import { useNavigate, useRouter } from '@tanstack/react-router'
import { EditIcon, EllipsisIcon, FileHeartIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { doNothing } from 'remeda'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { useHoverTimer } from '@fysioscout/utils/react'

import { useEditTreatmentNameActions } from '@/features/treatments/user-actions/edit-treatment-name/store'

const ACTIONS = {
  seeTreatmentDetails: 'see-treatment-details',
  editName: 'edit-name',
} as const

interface TreatmentTableActionsProps {
  /** The patient associated with the treatment. */
  patient: Schemas['PatientWithEmployee']

  /** The details of the treatment. */
  treatment: Schemas['TreatmentDto']
}

export function TreatmentTableActions({ patient, treatment }: TreatmentTableActionsProps) {
  const navigate = useNavigate()
  const router = useRouter()
  const editTreatmentNameActions = useEditTreatmentNameActions()

  const preloadTreatmentDetailsHover = useHoverTimer(() => {
    void router.preloadRoute({
      to: '/patients/$patientId/treatments/$treatmentId',
      params: { patientId: patient.id, treatmentId: treatment.id },
      search: { view: 'exercises' },
    })
  })

  const handleAction = (key: Key) => {
    match(key)
      .with(ACTIONS.seeTreatmentDetails, () => {
        void navigate({
          to: '/patients/$patientId/treatments/$treatmentId',
          params: { patientId: patient.id, treatmentId: treatment.id },
        })
      })
      .with(ACTIONS.editName, () => {
        editTreatmentNameActions.setTreatment(treatment)

        setTimeout(() => {
          editTreatmentNameActions.open()
        }, 100)
      })
      .otherwise(() => doNothing())
  }

  return (
    <MenuTrigger>
      <Button
        variant={'soft'}
        size={'md'}
        color={'neutral'}
        aria-label={`Handlinger for ${treatment.name ?? ''}`}
      >
        <EllipsisIcon />
      </Button>

      <Menu
        selectionMode={'none'}
        disallowEmptySelection
        onAction={handleAction}
        placement={'bottom end'}
      >
        <MenuItem
          id={ACTIONS.seeTreatmentDetails}
          icon={<FileHeartIcon />}
          isLink
          onHoverStart={preloadTreatmentDetailsHover.start}
          onHoverEnd={preloadTreatmentDetailsHover.end}
        >
          Se detaljer
        </MenuItem>

        <MenuItem id={ACTIONS.editName} icon={<EditIcon />}>
          Rediger navn
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}
