import { createStore, useStore } from 'zustand'

import type { Key, Selection } from 'react-aria-components'

import { useAddExercisesContext } from '@/features/treatments/user-actions/add-exercise/context'

interface BasketItem {
  id: string
  name: string
}

export interface AddExercisesStore {
  isOpen: boolean

  pageNumber: number
  searchQuery: string
  favourite: boolean
  library: Key
  region: Key
  treatment: Key

  selection: Selection
  basket: BasketItem[]
  actions: {
    open: () => void
    onOpenChange: (isOpen: boolean) => void

    setPageNumber: (pageNumber: number) => void
    setSearchQuery: (query: string) => void
    setFavourite: (favourite: boolean) => void
    setLibrary: (library: Key) => void
    setRegion: (region: Key) => void
    setTreatment: (treatment: Key) => void

    updateSelectionAndBasket: (selection: Selection, availableExercises: BasketItem[]) => void
    removeFromBasket: (keys: Set<Key>) => void
    resetSelectionAndBasket: () => void

    resetFilters: () => void
    reset: () => void
  }
}

export type InitialState = Omit<AddExercisesStore, 'actions'>

export const INITIAL_STATE = {
  isOpen: false,

  pageNumber: 1,
  searchQuery: '',
  favourite: false,
  library: '',
  region: '',
  treatment: '',

  selection: new Set(),
  basket: [],
} satisfies InitialState

export const createAddExercisesStore = (initialState: InitialState = INITIAL_STATE) => {
  return createStore<AddExercisesStore>((set) => {
    return {
      ...initialState,
      actions: {
        open: () => {
          set({ isOpen: true })
        },

        onOpenChange: (isOpen) => {
          set({ isOpen })
        },

        setPageNumber: (pageNumber) => {
          const newPageNumber = Math.max(pageNumber, 1)

          set({ pageNumber: newPageNumber })
        },

        setSearchQuery: (searchQuery) => {
          set((state) => handleFilterSelection(state, { searchQuery }))
        },

        setFavourite: (favourite) => {
          set((state) => handleFilterSelection(state, { favourite }))
        },

        setLibrary: (library: Key) => {
          set((state) =>
            handleFilterSelection(state, {
              library: library === 'all' ? INITIAL_STATE.library : library,
            }),
          )
        },

        setRegion: (region: Key) => {
          set((state) =>
            handleFilterSelection(state, {
              region: region === 'all' ? INITIAL_STATE.region : region,
            }),
          )
        },

        setTreatment: (treatment: Key) => {
          set((state) =>
            handleFilterSelection(state, {
              treatment: treatment === 'all' ? INITIAL_STATE.treatment : treatment,
            }),
          )
        },

        updateSelectionAndBasket: (selection: Selection, availableExercises: BasketItem[]) => {
          set((state) => {
            if (selection === 'all') {
              /**
               * When 'all' is selected, add all available exercises to the basket without
               * duplicates
               */
              const currentBasketIds = new Set(state.basket.map((item) => item.id))
              const newExercises = availableExercises.filter(
                (exercise) => !currentBasketIds.has(exercise.id),
              )

              const updatedBasket = [...state.basket, ...newExercises]
              const updatedSelection = new Set(updatedBasket.map((item) => item.id))

              return {
                selection: updatedSelection,
                basket: updatedBasket,
              }
            }

            const currentBasket = state.basket
            const availableExerciseIds = new Set(availableExercises.map((exercise) => exercise.id))

            /**
             * Keep items in the basket that are either:
             *
             * 1. Not in the availableExercises set (we don't touch these)
             * 2. In the availableExercises set AND in the new selection
             */
            const updatedBasket = currentBasket.filter(
              (item) => !availableExerciseIds.has(item.id) || selection.has(item.id),
            )

            /** Add new exercises that are in the selection but not in the current basket */
            const exercisesToAdd = availableExercises.filter(
              (exercise) =>
                selection.has(exercise.id) &&
                !currentBasket.some((item) => item.id === exercise.id),
            )

            const finalBasket = [...updatedBasket, ...exercisesToAdd]

            /** Ensure the selection includes all basket IDs */
            const updatedSelection = new Set([...finalBasket.map((item) => item.id), ...selection])

            return {
              selection: updatedSelection,
              basket: finalBasket,
            }
          })
        },

        removeFromBasket: (keys: Set<Key>) => {
          set((state) => {
            if (state.selection === 'all') {
              /** If 'all' is selected, remove items from the basket but keep 'all' selected */
              return {
                selection: 'all',
                basket: state.basket.filter((item) => !keys.has(item.id)),
              }
            }

            const newSelection = new Set(state.selection)
            for (const key of keys) newSelection.delete(key.toString())

            return {
              selection: newSelection,
              basket: state.basket.filter((item) => !keys.has(item.id)),
            }
          })
        },

        resetSelectionAndBasket: () => {
          set({ selection: INITIAL_STATE.selection, basket: INITIAL_STATE.basket })
        },

        resetFilters: () => {
          set((state) => ({
            ...state,
            ...INITIAL_STATE,
            isOpen: state.isOpen,
            selection: state.selection,
            basket: state.basket,
          }))
        },

        reset: () => {
          set(INITIAL_STATE)
        },
      },
    }
  })
}

/**
 * Handles the selection of a filter in the AddExercisesStore.
 *
 * If the current selection is 'all', a new selection is created based on the current basket items.
 * This ensures that when switching from 'all' to a specific filter, the selection is updated to
 * reflect the current basket contents.
 *
 * @param state - The current state of the AddExercisesStore.
 * @param newState - The new partial state of the AddExercisesStore with the filter selection.
 * @returns The updated state of the AddExercisesStore with the filter selection applied.
 */
function handleFilterSelection(state: AddExercisesStore, newState: Partial<AddExercisesStore>) {
  newState.pageNumber = 1

  if (state.selection === 'all') {
    const selection = new Set(state.basket.map((item) => item.id))

    return { ...newState, selection }
  }

  return newState
}

function useAddExercisesStore<T>(selector: (store: AddExercisesStore) => T): T {
  const { store } = useAddExercisesContext()

  return useStore(store, selector)
}

export function useAddExercisesActions() {
  return useAddExercisesStore((state) => state.actions)
}

export function useIsOpen() {
  return useAddExercisesStore((state) => state.isOpen)
}

export function useSelection() {
  return useAddExercisesStore((state) => state.selection)
}

export function useBasket() {
  return useAddExercisesStore((state) => state.basket)
}

export function useLibrary() {
  return useAddExercisesStore((state) => state.library)
}

export function useTreatmentId() {
  return useAddExercisesStore((state) => state.treatment)
}

export function useRegionId() {
  return useAddExercisesStore((state) => state.region)
}

export function usePageNumber() {
  return useAddExercisesStore((state) => state.pageNumber)
}

export function useSearchQuery() {
  return useAddExercisesStore((state) => state.searchQuery)
}

export function useFavorite() {
  return useAddExercisesStore((state) => state.favourite)
}
