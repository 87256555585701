import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'

import { ExportExercisesForm } from '@/features/patients/user-actions/export-exercises/components/export-exercises-form'
import { useTreatment } from '@/features/patients/user-actions/export-exercises/store'

export function ExportExercisesDialog() {
  const treatment = useTreatment()

  if (!treatment) {
    return null
  }

  return (
    <Dialog
      data-testid={'export-exercises-dialog'}
      aria-label={'Eksporter øvelser'}
      className={'stack h-full'}
    >
      {({ close }) => (
        <>
          <DialogCloseButton onPress={close} />
          <ExportExercisesForm treatment={treatment} />
        </>
      )}
    </Dialog>
  )
}
