import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

export const containerStyles = tv({
  base: 'mx-auto w-full px-5 md:px-6 lg:px-8',
  variants: {
    size: {
      fluid: 'max-w-full',
      prose: 'max-w-prose',
      sm: 'max-w-screen-sm',
      md: 'max-w-screen-md',
      lg: 'max-w-screen-lg',
      xl: 'max-w-screen-xl',
      '2xl': 'max-w-screen-2xl',
      '3xl': 'max-w-screen-3xl',
    },
  },
  defaultVariants: {
    size: 'xl',
  },
})

export type ContainerVariants = VariantProps<typeof containerStyles>
