import { useMutation } from '@tanstack/react-query'

import { clinicExerciseMutations } from '@/api/fysioscout/endpoints/clinic-exercise/mutations'

/**
 * Sends an email with exercises in PDF format.
 *
 * @example
 *   const { mutate, isLoading, isError } = useEmailExercisesPdf()
 *   mutate(emailExercisesPdfOptions)
 *
 * @returns - The result of the mutation.
 */
export function useSendExercisesPdfEmail() {
  return useMutation({
    mutationFn: clinicExerciseMutations.sendExercisesPdfEmail,
  })
}
