import { createFileRoute } from '@tanstack/react-router'

import { authMutations } from '@/api/fysioscout/endpoints/auth/mutations'
import { redirectToAuth } from '@/features/auth/utils'
import { getAuthStoreActions } from '@/stores/auth-store'

export const Route = createFileRoute('/_auth/logout')({
  preload: false,
  beforeLoad: async () => {
    await authMutations.logout()
    getAuthStoreActions().removeSession()
    redirectToAuth({ afterLoginRedirectUrl: globalThis.location.origin })
  },
})
