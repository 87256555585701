import { getLocalTimeZone, today } from '@internationalized/date'

import type { DateDuration } from '@internationalized/date'
import type { DateRange } from 'react-aria'

export function subtractFromToday(duration: DateDuration): DateRange {
  return {
    start: today(getLocalTimeZone()).subtract(duration),
    end: today(getLocalTimeZone()),
  }
}

export type PresetRangeOption = '24h' | '7d' | '14d' | '30d' | '90d' | '6m' | '1y'
export type PresetRangeGroup = 'all' | 'recent' | 'extended'

export interface PresetRange {
  label: string
  getRange: () => DateRange
}

export const presetRangeConfig = {
  '24h': {
    label: 'Seneste 24 timer',
    getRange: () => subtractFromToday({ days: 1 }),
  },
  '7d': {
    label: 'Seneste 7 dage',
    getRange: () => subtractFromToday({ days: 7 }),
  },
  '14d': {
    label: 'Seneste 14 dage',
    getRange: () => subtractFromToday({ days: 14 }),
  },
  '30d': {
    label: 'Seneste 30 dage',
    getRange: () => subtractFromToday({ days: 30 }),
  },
  '90d': {
    label: 'Seneste 90 dage',
    getRange: () => subtractFromToday({ days: 90 }),
  },
  '6m': {
    label: 'Seneste 6 måneder',
    getRange: () => subtractFromToday({ months: 6 }),
  },
  '1y': {
    label: 'Seneste år',
    getRange: () => subtractFromToday({ years: 1 }),
  },
} satisfies Record<PresetRangeOption, PresetRange>
