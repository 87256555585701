'use client'

import { FocusRing as RACFocusRing } from 'react-aria'
import { tv } from 'tailwind-variants'

import type { FocusRingProps as RACFocusRingProps } from 'react-aria'

import { focusRing } from '../../utils'

const focusRingStyles = tv({
  base: `${focusRing.base} outline-2`,
})

export type FocusRingProps = RACFocusRingProps

export function FocusRing({ focusRingClass, children, ...rest }: FocusRingProps) {
  return (
    <RACFocusRing focusRingClass={focusRingStyles({ className: focusRingClass })} {...rest}>
      {children}
    </RACFocusRing>
  )
}
