import type { FetchResponse } from 'openapi-fetch'

type MediaType = `${string}/${string}`

/**
 * Unwraps the data from an openapi-fetch fetch call, throwing an error if the response is an error
 * or if the data is undefined.
 *
 * @param promise - The API call to make.
 * @returns The unwrapped data from the response.
 * @throws If there is an error returned by the API call or if the data is unexpectedly undefined.
 */
export async function unwrap<T, E, M extends MediaType = MediaType>(
  promise: Promise<FetchResponse<T, E, M>>,
): Promise<NonNullable<FetchResponse<T, E, M>['data']>> {
  const { data, error } = await promise

  if (error) throw new Error(error)

  if (data === undefined) {
    throw new Error('Unexpected undefined data')
  }

  return data as NonNullable<FetchResponse<T, E, M>['data']>
}
