import { Outlet } from '@tanstack/react-router'

import { CreateVideoConsultation } from '@/features/video-consultation/user-actions/create-video-consultation/components/create-video-consultation'

export function VideoConsultationRoute() {
  return (
    <CreateVideoConsultation>
      <Outlet />
    </CreateVideoConsultation>
  )
}
