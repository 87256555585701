import { createStore, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useEditExercisesContext } from './context'

export interface EditExercisesStore {
  isOpen: boolean
  exerciseId: Schemas['ExerciseDto']['id'] | null
  actions: {
    open: () => void
    onOpenChange: (isOpen: boolean) => void
    setExerciseId: (exerciseId: Schemas['ExerciseDto']['id']) => void
    reset: () => void
  }
}

export type InitialState = Omit<EditExercisesStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  exerciseId: null,
} satisfies InitialState

export const createEditExercisesStore = (initialState: InitialState = INITIAL_STATE) => {
  return createStore<EditExercisesStore>((set) => {
    return {
      ...initialState,
      actions: {
        open: () => {
          set({ isOpen: true })
        },

        onOpenChange: (isOpen) => {
          set({ isOpen })
        },

        setExerciseId: (exerciseId) => {
          set({ exerciseId })
        },

        reset: () => {
          set(INITIAL_STATE)
        },
      },
    }
  })
}

function useEditExercisesStore<T>(selector: (store: EditExercisesStore) => T): T {
  const { store } = useEditExercisesContext()

  return useStore(store, selector)
}

export function useEditExercisesActions() {
  return useEditExercisesStore((state) => state.actions)
}

export function useIsOpen() {
  return useEditExercisesStore((state) => state.isOpen)
}

export function useExerciseId() {
  return useEditExercisesStore((state) => state.exerciseId)
}
