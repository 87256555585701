import { create } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

interface AuthStore {
  session: Schemas['TokenResponse'] | null
  actions: {
    setSession: (session: Schemas['TokenResponse']) => void
    removeSession: () => void
    reset: () => void
  }
}

const INITIAL_STATE = {
  session: null,
} satisfies Omit<AuthStore, 'actions'>

export const authStore = create<AuthStore>()((set) => ({
  ...INITIAL_STATE,
  actions: {
    setSession: (session) => {
      set((state) => ({ ...state, session }))
    },
    removeSession: () => {
      set((state) => ({ ...state, session: null }))
    },
    reset: () => {
      set(INITIAL_STATE)
    },
  },
}))

export function getSession(): Schemas['TokenResponse'] | null {
  return authStore.getState().session
}

export function setSession(accessToken: Schemas['TokenResponse']) {
  authStore.getState().actions.setSession(accessToken)
}

export function getAuthStoreActions() {
  return authStore.getState().actions
}

export function removeSession() {
  authStore.getState().actions.removeSession()
}
