import type { CreateTreatmentStore } from '@/features/treatments/user-actions/create-treatment/store'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export const [CreateTreatmentContext, useCreateTreatment] = createStrictContext<{
  patientId: string
  store: StoreApi<CreateTreatmentStore>
}>({
  name: 'CreateTreatmentStore',
  errorMessage: 'useCreateTreatmentContext must be used within a CreateTreatmentProvider',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})

export function usePatientId() {
  const { patientId } = useCreateTreatment()

  return patientId
}
