'use client'

import React from 'react'
import {
  composeRenderProps,
  OverlayArrow as RACOverlayArrow,
  Tooltip as RACTooltip,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { TooltipProps as RACTooltipProps } from 'react-aria-components'

import { Text } from '../../typography/text/text'

export interface TooltipProps extends Omit<RACTooltipProps, 'children'> {
  children: React.ReactNode
}

const styles = tv({
  base: 'bg-neutral-12 text-neutral-1 group rounded px-3 py-2 text-xs will-change-transform',
  variants: {
    isEntering: {
      true: 'animate-in fade-in placement-bottom:slide-in-from-top-0.5 placement-top:slide-in-from-bottom-0.5 placement-left:slide-in-from-right-0.5 placement-right:slide-in-from-left-0.5 duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out placement-bottom:slide-out-to-top-0.5 placement-top:slide-out-to-bottom-0.5 placement-left:slide-out-to-right-0.5 placement-right:slide-out-to-left-0.5 duration-150 ease-in',
    },
  },
})

export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <RACTooltip
      {...props}
      offset={10}
      className={composeRenderProps(props.className, (className, renderProps) =>
        styles({ ...renderProps, className }),
      )}
    >
      <RACOverlayArrow>
        <svg
          width={8}
          height={8}
          viewBox={'0 0 8 8'}
          className={
            'group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90 fill-neutral-12 stroke-neutral-12'
          }
        >
          <path d={'M0 0 L4 4 L8 0'} />
        </svg>
      </RACOverlayArrow>

      <Text size={'1'} color={'current'} className={'leading-tight'}>
        {children}
      </Text>
    </RACTooltip>
  )
}
