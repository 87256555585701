import React from 'react'

import { Form } from '@fysioscout/ui/forms/form'
import { TextField } from '@fysioscout/ui/forms/text-field'
import {
  Dialog,
  DialogButtonGroup,
  DialogCancelButton,
  DialogContent,
  DialogCta,
  DialogTitle,
} from '@fysioscout/ui/overlays/dialog'
import { Modal } from '@fysioscout/ui/overlays/modal'
import { toast } from '@fysioscout/ui/status/toast'

import { useCreateClinicTreatment } from '../api/create-clinic-treatment'
import { usePatientId } from '../context'
import { useCreateTreatmentActions, useIsOpen } from '../store'

const FIELDS = {
  name: 'name',
}

export function CreateTreatmentDialog() {
  const actions = useCreateTreatmentActions()
  const isOpen = useIsOpen()
  const mutation = useCreateClinicTreatment({ redirectToTreatment: true })
  const patientId = usePatientId()

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      actions.close()
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const name = String(formData.get(FIELDS.name))

    mutation.mutate(
      { name, patient_id: patientId },
      {
        onSuccess: () => {
          toast.success(`Forløbet "${name}" blev oprettet.`)
          actions.reset()
        },
      },
    )
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Dialog>
        {({ close }) => (
          <>
            <div className={'stack'}>
              <DialogTitle>Opret nyt behandlingsforløb</DialogTitle>

              <DialogContent>
                <Form onSubmit={handleSubmit}>
                  <TextField
                    label={'Navngiv behandlingsforløb'}
                    name={FIELDS.name}
                    type={'text'}
                    isRequired
                    autoFocus
                  />

                  <DialogButtonGroup>
                    <DialogCancelButton onPress={close} />
                    <DialogCta isPending={mutation.isPending}>Opret behandlingsforløb</DialogCta>
                  </DialogButtonGroup>
                </Form>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </Modal>
  )
}
