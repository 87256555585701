import type { Middleware } from 'openapi-fetch'

import { getErrorMessage } from '@fysioscout/utils/error'

/** Middleware for handling errors in HTTP responses. */
export const throwErrors = {
  async onResponse({ response }) {
    if (response.status >= 400 || response.status === 302) {
      const body: unknown = response.headers.get('content-type')?.includes('json')
        ? await response.clone().json()
        : await response.clone().text()

      const message = getErrorMessage(body)

      throw new Error(message)
    }

    return undefined
  },
} satisfies Middleware

/** Middleware function to set default headers for requests. */
export const setDefaultHeaders = {
  onRequest({ request }) {
    if (!request.headers.has('Content-Type')) {
      const contentType =
        request.body instanceof FormData ? 'multipart/form-data' : 'application/json'

      request.headers.set('Content-Type', contentType)
    }

    request.headers.set('Accept', 'application/json')

    return request
  },
} satisfies Middleware
