import { create, useStore } from 'zustand'

import { useCreateTreatment } from '@/features/treatments/user-actions/create-treatment/context'

export interface CreateTreatmentStore {
  isOpen: boolean
  actions: {
    open: () => void
    close: () => void
    toggle: () => void
    reset: () => void
  }
}

type InitialState = Omit<CreateTreatmentStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
} satisfies InitialState

export function createTreatmentStore(initialState: InitialState = INITIAL_STATE) {
  return create<CreateTreatmentStore>((set) => ({
    ...initialState,
    actions: {
      open: () => {
        set({ isOpen: true })
      },
      close: () => {
        set({ isOpen: false })
      },
      toggle: () => {
        set((state) => ({ isOpen: !state.isOpen }))
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

export function useCreateTreatmentStore<T>(selector: (state: CreateTreatmentStore) => T) {
  const { store } = useCreateTreatment()

  return useStore(store, selector)
}

export function useIsOpen() {
  return useCreateTreatmentStore((state) => state.isOpen)
}

export function useCreateTreatmentActions() {
  return useCreateTreatmentStore((state) => state.actions)
}
