import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicTemplateExerciseMutations } from '@/api/fysioscout/endpoints/clinic-template-exercise/mutations'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

/**
 * Transfer template exercises to the patient.
 *
 * @example
 *   const { mutate, isLoading, isError } = useTransferTemplateExercises()
 *   mutate(transferTemplateExercisesOptions)
 *
 * @returns The mutation object
 */
export function useTransferTemplateExercises() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicTemplateExerciseMutations.transferClinicTemplateExercises,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: clinicTreatmentQueries.all() })
    },
  })
}
