import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'

import { TreatmentTable } from '@/features/treatments/components/treatment/treatment-table'

interface TreatmentListProps {
  /** Optional CSS class name for custom styling. */
  className?: string

  /** The unique identifier for the patient. */
  patient: Schemas['PatientWithEmployee']
}

export function TreatmentList({ className, patient }: TreatmentListProps) {
  const headingId = React.useId()

  return (
    <div data-testid={'treatment-list'} className={className}>
      <Panel>
        <PanelHeading id={headingId}>Behandlingsforløb</PanelHeading>
        <TreatmentTable aria-labelledby={headingId} patient={patient} />
      </Panel>
    </div>
  )
}
