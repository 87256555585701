'use client'

import type { ComponentPropsWithoutRef } from 'react'

import { cn } from '../../utils'

type LogoMarkProps = ComponentPropsWithoutRef<'svg'>

export function LogoMark({ className, ...rest }: LogoMarkProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'none'}
      viewBox={'0 0 56 45'}
      className={cn('fill-accent w-14', className)}
      {...rest}
    >
      <g>
        <path
          d={
            'M52.668.779a12.107 12.107 0 0 1-8.202 3.2h-15.14a14.576 14.576 0 0 0-14.582 14.578 1.85 1.85 0 0 0 1.849 1.848h22.798A16.424 16.424 0 0 0 55.822 3.98V2.177A1.882 1.882 0 0 0 52.668.779Z'
          }
          opacity={'.45'}
        />

        <path
          d={
            'M39.802 24.013H17.004A16.437 16.437 0 0 0 5.392 28.83 16.427 16.427 0 0 0 .584 40.443v1.802a1.88 1.88 0 0 0 3.157 1.387 12.108 12.108 0 0 1 8.202-3.2h15.14a14.585 14.585 0 0 0 13.472-8.999 14.575 14.575 0 0 0 1.11-5.579 1.852 1.852 0 0 0-1.863-1.841Z'
          }
        />
      </g>
    </svg>
  )
}
