import { createFileRoute } from '@tanstack/react-router'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

import { PatientView } from './-patient-view'

export const Route = createFileRoute('/_auth/patients/$patientId/')({
  component: PatientView,
  loader: async ({ context: { queryClient }, params: { patientId } }) => {
    const treatments = await queryClient.ensureQueryData(
      clinicTreatmentQueries.treatments(patientId),
    )

    return {
      treatments,
    }
  },
})
