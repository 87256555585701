import { useQuery } from '@tanstack/react-query'
import { omitBy } from 'remeda'
import { match } from 'ts-pattern'

import { clinicTemplateExerciseQueries } from '@/api/fysioscout/endpoints/clinic-template-exercise/queries'
import { clinicTemplateRegionQueries } from '@/api/fysioscout/endpoints/clinic-template-region/queries'
import { clinicTemplateTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-template-treatment/queries'
import {
  useFavorite,
  useLibrary,
  useRegionId,
  useSearchQuery,
  useTreatmentId,
} from '@/features/treatments/user-actions/add-exercise/store'

export function useExercisesList() {
  const libraryKey = useLibrary()
  const regionId = useRegionId()
  const search = useSearchQuery()
  const treatmentId = useTreatmentId()
  const favourite = useFavorite()

  const { data: regions } = useQuery(clinicTemplateRegionQueries.listRegions())
  const { data: treatments } = useQuery(
    clinicTemplateTreatmentQueries.list(
      { regionId: String(regionId) },
      { enabled: Boolean(regionId) && regionId !== 'all' },
    ),
  )

  const activeRegionName = regions?.find((region) => region.id === regionId)?.name
  const activeTreatmentName = treatments?.find(({ treatment }) => treatment.id === treatmentId)
    ?.treatment.name

  const library = match(libraryKey)
    .with('fysio_scout', () => 'fysio_scout' as const)
    .with('own', () => 'own' as const)
    .otherwise(() => null)

  const query = omitBy(
    {
      library,
      favourite,
      region: activeRegionName,
      treatment: activeTreatmentName,
      search,
      take: 100,
    },
    (value) => !value,
  )

  return useQuery(clinicTemplateExerciseQueries.list({ query }))
}
