'use client'

import React from 'react'
import { Radio as RACRadio, RadioGroup as RACRadioGroup } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { ReactNode } from 'react'
import type {
  RadioGroupProps as RACRadioGroupProps,
  RadioProps as RACRadioProps,
  ValidationResult,
} from 'react-aria-components'

import { composeTailwindRenderProps, focusRing } from '../../utils'
import { Description, FieldError, Label } from '../field/field'

export interface RadioGroupProps extends Omit<RACRadioGroupProps, 'children'> {
  label?: string
  children?: ReactNode
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export const RadioGroup = React.forwardRef<React.ElementRef<typeof RACRadioGroup>, RadioGroupProps>(
  ({ label, description, errorMessage, className, children, ...rest }, ref) => (
    <RACRadioGroup
      {...rest}
      ref={ref}
      className={composeTailwindRenderProps(className, 'group flex flex-col gap-2')}
    >
      <Label>{label}</Label>

      <div
        className={
          'group-orientation-vertical:flex-col group-orientation-horizontal:gap-4 flex gap-2'
        }
      >
        {children}
      </div>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACRadioGroup>
  ),
)

RadioGroup.displayName = 'RadioGroup'

const styles = tv({
  extend: focusRing,
  base: 'bg-field size-5 rounded-full border-2 transition-colors duration-150',
  variants: {
    isSelected: {
      false: 'group-pressed:border-neutral-8 group-hover:border-neutral-8 border-neutral-7',
      true: 'group-pressed:border-accent-10 group-hover:border-accent-10 border-accent-9 border-[7px]',
    },
    isInvalid: {
      true: 'group-pressed:border-red-9 border-red-8',
    },
    isDisabled: {
      true: 'border-neutral-3',
    },
  },
})

export const Radio = React.forwardRef<React.ElementRef<typeof RACRadio>, RACRadioProps>(
  (props, ref) => (
    <RACRadio
      {...props}
      ref={ref}
      className={composeTailwindRenderProps(
        props.className,
        'disabled:text-disabled-foreground group flex items-center gap-2 text-sm transition',
      )}
    >
      {(renderProps) => (
        <>
          <div className={styles(renderProps)} />
          {props.children}
        </>
      )}
    </RACRadio>
  ),
)

Radio.displayName = 'Radio'
