import { createStore, useStore } from 'zustand'

import { useCreateVideoConsultationContext } from './context'

export interface CreateVideoConsultationStore {
  isOpen: boolean
  actions: {
    open: () => void
    close: () => void
    onOpenChange: (isOpen: boolean) => void
    reset: () => void
  }
}

export type InitialState = Omit<CreateVideoConsultationStore, 'actions'>

export const INITIAL_STATE = {
  isOpen: false,
} satisfies InitialState

export function createVideoConsultationStore(initialState: InitialState = INITIAL_STATE) {
  return createStore<CreateVideoConsultationStore>((set) => {
    return {
      ...initialState,
      actions: {
        open: () => {
          set({ isOpen: true })
        },
        close: () => {
          set({ isOpen: false })
        },
        onOpenChange: (isOpen) => {
          set({ isOpen })
        },
        reset: () => {
          set(INITIAL_STATE)
        },
      },
    }
  })
}

function useCreateVideoConsultationStore<T>(selector: (state: CreateVideoConsultationStore) => T) {
  const { store } = useCreateVideoConsultationContext()

  return useStore(store, selector)
}

export function useIsOpen() {
  return useCreateVideoConsultationStore((state) => state.isOpen)
}

export function useCreateVideoConsultationActions() {
  return useCreateVideoConsultationStore((state) => state.actions)
}
