import type { ButtonVariants } from './button.styles'

import { createStrictContext } from '@fysioscout/utils/react'

interface ButtonGroupContextValue {
  variants: ButtonVariants
}

export const [ButtonContext, useButtonContext] = createStrictContext<ButtonGroupContextValue>({
  name: 'ButtonContext',
  errorMessage: 'useButtonContext must be used within a ButtonContext.Provider.',
  allowMissingProvider: true,
})
