import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { createLink, Link, Outlet, useMatches } from '@tanstack/react-router'
import {
  BookIcon,
  HomeIcon,
  LayoutListIcon,
  MessageSquareMoreIcon,
  UsersIcon,
  VideoIcon,
} from 'lucide-react'
import { composeRenderProps, Link as RACLink, TooltipTrigger } from 'react-aria-components'
import { uniqueBy } from 'remeda'
import { endsWith, slice } from 'string-ts'
import { tv } from 'tailwind-variants'

import type { LinkProps as RACLinkProps } from 'react-aria-components'

import { useEnableVideoConsultationPage } from '@fysioscout/hypertune/flags'
import { Button } from '@fysioscout/ui/buttons/button'
import { FocusRing } from '@fysioscout/ui/misc/focus-ring'
import { LogoMark } from '@fysioscout/ui/misc/logo-mark'
import { Breadcrumb, Breadcrumbs } from '@fysioscout/ui/navigation/breadcrumbs'
import { Tooltip } from '@fysioscout/ui/overlays/tooltip'
import { Text } from '@fysioscout/ui/typography/text'
import { cn, focusRing } from '@fysioscout/ui/utils'
import { ThemeSelector } from '@fysioscout/widgets/theme'

import { env } from '@/config/env'
import { QuickSettingsMenu } from '@/features/general/components/quick-settings-menu'

interface AuthLayoutProps {
  children?: React.ReactNode
}

export function AuthLayout({ children }: AuthLayoutProps) {
  const isVideoConsultationPageEnabled = useEnableVideoConsultationPage()

  return (
    <div className={'relative isolate flex min-h-svh w-full flex-col'}>
      <div className={'fixed inset-y-0 left-0 w-64'}>
        <nav className={'flex h-full min-h-0 flex-col'}>
          <div className={'p-6'}>
            <FocusRing>
              <Link
                to={'/'}
                className={'flex items-center gap-3 self-start text-sm font-medium outline-none'}
              >
                <LogoMark className={'fill-accent-10 flex size-8 items-center'} />
                <Text size={'2'} className={'tracking-wide'}>
                  FysioScout
                </Text>
              </Link>
            </FocusRing>
          </div>

          <div className={'flex flex-1 flex-col overflow-y-auto px-4 py-6'}>
            <div className={'flex flex-col gap-0.5'}>
              <NavLink to={'/'} activeOptions={{ includeSearch: false }} icon={<HomeIcon />}>
                Hjem
              </NavLink>

              <NavLink
                to={'/patients'}
                search={{ status: 'accepted' }}
                activeOptions={{ includeSearch: false }}
                icon={<UsersIcon />}
              >
                Patienter
              </NavLink>

              {isVideoConsultationPageEnabled ? (
                <NavLink
                  to={'/video-consultation'}
                  search={{ status: 'planned' }}
                  activeOptions={{ includeSearch: false }}
                  icon={<VideoIcon />}
                >
                  Videokonsultation
                </NavLink>
              ) : null}
            </div>

            <div className={'mt-8 flex flex-col gap-0.5'}>
              <Text size={'1'} medium className={'text-neutral-10 mb-2 px-3'}>
                Kommer snart
              </Text>

              {isVideoConsultationPageEnabled ? null : (
                <NavLink
                  icon={<VideoIcon />}
                  to={'/video-consultation'}
                  search={{ status: 'planned' }}
                  isDisabled
                >
                  Videokonsultation
                </NavLink>
              )}

              <NavLink to={'/practitioners'} isDisabled icon={<LayoutListIcon />}>
                Behandlere
              </NavLink>

              <NavLink to={'/templates'} isDisabled icon={<BookIcon />}>
                Skabeloner
              </NavLink>
            </div>
          </div>
        </nav>
      </div>

      <main className={'flex min-w-0 flex-1 flex-col py-2 pl-64 pr-2 2xl:py-2.5 2xl:pr-2.5'}>
        <div
          className={
            'bg-main-background ring-neutral-5 flex grow flex-col rounded-lg shadow-sm ring-1'
          }
        >
          {env.VITE_API_MOCKING ? <MockingIndicator /> : null}

          <TopNavigation />

          {children ?? <Outlet />}
        </div>
      </main>
    </div>
  )
}

function MockingIndicator() {
  return (
    <div className={'stack center bg-yellow-5 h-6 rounded-t-lg px-2'}>
      <Text size={'0'} className={'leading-none'} medium>
        Mocking active
      </Text>
    </div>
  )
}

function TopNavigation() {
  const matches = useMatches()

  const breadcrumbs = React.useMemo(() => {
    const crumbs = matches.map((match) => {
      const pathname = match.pathname
      const title = match.meta?.at(0)?.title

      return { title, pathname }
    })

    const uniquePathname = uniqueBy(crumbs, (crumb) => {
      if (endsWith(crumb.pathname, '/')) {
        return slice(crumb.pathname, 0, -1)
      }

      return crumb.pathname
    })

    const filtered = uniquePathname.filter((crumb) => crumb.title)

    return [{ title: 'Hjem', pathname: '/' }, ...filtered] as const
  }, [matches])

  return (
    <div
      className={'border-neutral-5 flex max-h-12 items-center justify-between border-b px-6 py-3'}
    >
      <div className={'hstack w-full items-center gap-6'}>
        <div className={'hstack items-center'}>
          <Breadcrumbs items={breadcrumbs}>
            {(item) => (
              <Breadcrumb id={item.pathname} className={'last-of-type:pointer-events-none'}>
                <Link
                  to={item.pathname}
                  className={'hover:text-foreground leading-none transition-colors duration-100'}
                >
                  <Text size={'1'}>{item.title}</Text>
                </Link>
              </Breadcrumb>
            )}
          </Breadcrumbs>
        </div>

        <div className={'hstack ml-auto items-center gap-8'}>
          <div className={'hstack items-center gap-1.5'}>
            <TooltipTrigger delay={0} closeDelay={200}>
              <Button
                size={'xs'}
                variant={'transparent'}
                iconStart={<MessageSquareMoreIcon />}
                isVisuallyDisabled
              >
                Support
              </Button>

              <Tooltip>
                Support er midlertidigt kun <br /> tilgængelig i appen.
              </Tooltip>
            </TooltipTrigger>

            <TooltipTrigger delay={0} closeDelay={200}>
              <Button
                size={'xs'}
                variant={'transparent'}
                iconStart={<MessageSquareMoreIcon />}
                isVisuallyDisabled
              >
                Chat
              </Button>

              <Tooltip>
                Chat er midlertidigt kun <br /> tilgængelig i appen.
              </Tooltip>
            </TooltipTrigger>

            {/*<LinkButton size={'xs'} variant={'ghost'} color={'neutral'} iconStart={<CogIcon />}>*/}
            {/*  <Link to={'/settings'}>Indstillinger</Link>*/}
            {/*</LinkButton>*/}
          </div>

          <div className={'hstack gap-1'}>
            <ThemeSelector />
            <QuickSettingsMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

const navItemStyles = tv({
  extend: focusRing,
  base: 'text-foreground data-[status="active"]:bg-neutral-4 group relative flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm font-medium transition-colors duration-100 focus-visible:z-10',
  variants: {
    isDisabled: {
      true: 'cursor-default py-2 text-[0.8125rem] opacity-40',
    },
    isHovered: {
      true: 'bg-neutral-3',
    },
    isPressed: {
      true: 'bg-neutral-4 data-[status="active"]:bg-neutral-5',
    },
  },
})

interface NavItemProps extends RACLinkProps {
  icon: React.ReactNode
}

const NavItem = React.forwardRef<React.ElementRef<typeof RACLink>, NavItemProps>(
  ({ children, icon, className, ...rest }, ref) => (
    <RACLink
      {...rest}
      ref={ref}
      className={composeRenderProps(className, (className, renderProps) =>
        navItemStyles({ ...renderProps, className }),
      )}
    >
      {(renderProps) => (
        <>
          <Slot
            slot={'icon'}
            className={cn(
              'text-subtle-foreground group-hover:text-foreground size-5 transition-colors duration-100',
              'group-data-[status=active]:text-foreground',
              'group-aria-disabled:size-5',
            )}
          >
            {icon}
          </Slot>

          {typeof children === 'function' ? children(renderProps) : children}
        </>
      )}
    </RACLink>
  ),
)

NavItem.displayName = 'NavItem'

const NavLink = createLink(NavItem)
