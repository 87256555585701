import React from 'react'

import { EditExercisesContext } from '@/features/treatments/user-actions/edit-exercise/context'
import { createEditExercisesStore } from '@/features/treatments/user-actions/edit-exercise/store'

export interface EditExerciseProviderProps {
  children?: React.ReactNode
}

export function EditExerciseProvider({ children }: EditExerciseProviderProps) {
  const [store] = React.useState(() => createEditExercisesStore())

  return <EditExercisesContext.Provider value={{ store }}>{children}</EditExercisesContext.Provider>
}
