import React from 'react'

import { CreateVideoConsultationDrawer } from '@/features/video-consultation/user-actions/create-video-consultation/components/create-video-consultation-drawer'
import { CreateVideoConsultationProvider } from '@/features/video-consultation/user-actions/create-video-consultation/components/create-video-consultation-provider'

interface CreateVideoConsultationProps {
  children?: React.ReactNode
}

export function CreateVideoConsultation({ children }: CreateVideoConsultationProps) {
  return (
    <CreateVideoConsultationProvider>
      <CreateVideoConsultationDrawer />

      {children}
    </CreateVideoConsultationProvider>
  )
}
