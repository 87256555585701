import React from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

export function useCopyToClipboardState() {
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [copyStatus, setCopyStatus] = React.useState<'idle' | 'copied'>('idle')

  const copy = React.useCallback(
    async (text: string) => {
      await copyToClipboard(text).then((success) => {
        if (success) {
          setCopyStatus('copied')

          setTimeout(() => {
            setCopyStatus('idle')
          }, 800)
        }
      })
    },
    [copyToClipboard],
  )

  return {
    copiedText,
    copyStatus,
    copy,
  }
}
