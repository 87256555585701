import { Outlet } from '@tanstack/react-router'

import { ComposeProviders } from '@fysioscout/utils/react'

import { ChangePatientEmailProvider } from '@/features/patients/user-actions/change-email/components/change-patient-email-provider'
import { ChangePractitionerProvider } from '@/features/patients/user-actions/change-practitioner/components/change-practitioner-provider'
import { CreatePatientProvider } from '@/features/patients/user-actions/create-patient/components/create-patient-provider'

export function PatientsRoute() {
  return (
    <ComposeProviders
      providers={[ChangePractitionerProvider, CreatePatientProvider, ChangePatientEmailProvider]}
    >
      <Outlet />
    </ComposeProviders>
  )
}
