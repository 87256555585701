import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Switch } from '@fysioscout/ui/forms/switch'
import { toast } from '@fysioscout/ui/status/toast'

import { useUpdateClinicExercise } from '@/features/treatments/api/update-clinic-exercise'

interface ToggleExerciseStatusProps {
  /** The exercise data transfer object. */
  exercise: Schemas['ExerciseDto']

  /** The initial status value of the exercise. */
  initialValue: boolean
}

export function ToggleExerciseStatus(props: ToggleExerciseStatusProps) {
  const mutation = useUpdateClinicExercise()

  const handleChange = (isSelected: boolean) => {
    mutation.mutate(
      { id: props.exercise.id, body: { is_relevant: isSelected } },
      {
        onSuccess: () => {
          toast.success(
            `${props.exercise.name ?? 'Øvelsen'} er nu ${isSelected ? 'tilføjet til' : 'fjernet fra'} aktuelle.`,
          )
        },
      },
    )
  }

  const isSelected = mutation.isPending
    ? (mutation.variables.body.is_relevant ?? false)
    : props.initialValue

  return (
    <div className={'flex items-start'}>
      <Switch isSelected={isSelected} hideLabel onChange={handleChange}>
        {({ isSelected }) => (isSelected ? 'Fjern fra aktuelle' : 'Tilføj til aktuelle')}
      </Switch>
    </div>
  )
}
