import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useKeyPress } from 'ahooks'
import { CalendarDays, HistoryIcon } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { match } from 'ts-pattern'
import { z } from 'zod'

import type { UpdateClinicExerciseOptions } from '@/api/fysioscout/endpoints/clinic-exercise/models'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { SubmitHandler } from 'react-hook-form'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { NumberField } from '@fysioscout/ui/forms/number-field'
import { Radio, RadioGroup } from '@fysioscout/ui/forms/radio-group'
import { Switch } from '@fysioscout/ui/forms/switch'
import { TextArea } from '@fysioscout/ui/forms/text-area'
import { TextField } from '@fysioscout/ui/forms/text-field'
import { Separator } from '@fysioscout/ui/misc/separator'
import { toast } from '@fysioscout/ui/status/toast'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'
import {
  getMaxRepsFromMinutesAndSeconds,
  getMinutesAndSecondsFromMaxReps,
} from '@fysioscout/utils/functions'

import { useUpdateClinicExercise } from '@/features/treatments/api/update-clinic-exercise'

type EditExerciseBodyKeys =
  | Exclude<keyof UpdateClinicExerciseOptions['body'], 'video' | 'image' | 'is_pdf_patient'>
  | 'minutes'
  | 'seconds'

const PositiveIntegerSchema = z
  .number({ message: 'Feltet skal udfyldes.' })
  .min(1, { message: 'Værdien skal være større end 0.' })
  .default(1)

const NonNegativeIntegerSchema = z
  .number({ message: 'Feltet skal udfyldes.' })
  .min(0, { message: 'Værdien skal minimum være 0.' })
  .default(0)

const TimeValueSchema = z
  .number({ message: 'Feltet skal udfyldes.' })
  .min(0, { message: 'Værdien skal minimum være 0.' })
  .max(59, { message: 'Værdien kan maksimalt være 59.' })
  .default(1)

const FormSchema = z
  .object({
    name: z.string().min(2, { message: 'Navn skal indeholde mindst 2 tegn.' }).trim(),
    description: z.string().trim(),
    sets: PositiveIntegerSchema,
    exercise_input_type: z.enum(['reps', 'time', 'range']).default('reps'),
    reps_range: z.object({ minimum: NonNegativeIntegerSchema, maximum: PositiveIntegerSchema }),
    days: PositiveIntegerSchema,
    weeks: PositiveIntegerSchema,
    is_weight_included: z.boolean().optional().default(false),
    starting_weight: z.union([z.number().optional(), PositiveIntegerSchema]).optional(),
    is_relevant: z.boolean().optional().default(false),
    minutes: TimeValueSchema.default(0),
    seconds: TimeValueSchema.default(1),
  } as const satisfies Record<EditExerciseBodyKeys, z.ZodTypeAny>)
  .refine(
    (data) => {
      if (data.is_weight_included) {
        return data.starting_weight !== undefined && data.starting_weight > 0
      }

      return true
    },
    {
      message: 'Værdien skal være større end 0.',
      path: ['starting_weight'],
    },
  )

type FormSchema = z.infer<typeof FormSchema>

interface EditExerciseFormProps {
  exercise: Schemas['ExerciseDto']
}

export function EditExerciseForm({ exercise }: EditExerciseFormProps) {
  const mutation = useUpdateClinicExercise()

  const form = useForm<FormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: exercise.name ?? '',
      description: exercise.description ?? '',
      sets: exercise.set_count ?? 1,
      exercise_input_type: exercise.exercise_input_type ?? 'reps',
      days: exercise.per_day_count ?? 1,
      weeks: exercise.per_week_count ?? 1,
      is_weight_included: exercise.is_weight_included ?? false,
      starting_weight: exercise.starting_weight ?? 1,
      is_relevant: exercise.is_relevant ?? false,
      ...getDefaultRepValues(exercise),
      ...getDefaultTimeValues(exercise),
    },
  })

  /**
   * Allow the user to submit the form by pressing meta+enter key if the form is dirty and the
   * mutation is not pending.
   */
  useKeyPress(
    ['meta.enter'],
    () => {
      if (!form.formState.isDirty || mutation.isPending) return

      void (async () => {
        await form.handleSubmit(handleSubmit)()
      })()
    },
    { exactMatch: true },
  )

  /**
   * Handle the form submission.
   *
   * This function is called when the form is submitted. It updates the exercise in the API and
   * resets the form values with values calculated from the maximum reps.
   *
   * There is internal logic to handle values when the exercise input type is `time` or `reps`
   * (documented below).
   *
   * @param params - The form values
   * @param params.minutes - The number of minutes
   * @param params.seconds - The number of seconds
   */
  const handleSubmit: SubmitHandler<FormSchema> = ({ minutes, seconds, ...formValues }) => {
    const body = { ...formValues }

    /**
     * If the exercise input type is time, we need to calculate the maximum reps from the minutes
     * and seconds and set the minimum reps to 1.
     */
    if (exerciseInputType === 'time') {
      const maxReps = getMaxRepsFromMinutesAndSeconds({ minutes, seconds }).unwrapOr(1)

      body.reps_range.minimum = 1
      body.reps_range.maximum = maxReps
    }

    /**
     * If the exercise input type is reps, we need to set the minimum reps to 0 as the reps input is
     * bound to the `reps_range.maximum and the minimum is then unnecessary`.
     */
    if (exerciseInputType === 'reps') {
      body.reps_range.minimum = 0
    }

    mutation.mutate(
      { id: exercise.id, body },
      {
        onSuccess: ({ exercise }) => {
          toast.success(`${exercise.name ?? 'Øvelsen'} blev gemt.`)

          const dialog = document.querySelector(`[data-testid="edit-exercise-dialog"]`)

          if (dialog instanceof HTMLElement) {
            dialog.focus()
          }

          /** Reset the form values with values calculated from the maximum reps. */
          form.reset({
            ...body,
            ...getDefaultRepValues(exercise),
            ...getDefaultTimeValues(exercise),
          })
        },
      },
    )
  }

  const exerciseInputType = form.watch('exercise_input_type')
  const isWeightIncluded = form.watch('is_weight_included')
  const repsRangeMaximum = form.watch('reps_range.maximum')
  const minutes = form.watch('minutes')

  return (
    <Form
      onSubmit={form.handleSubmit(handleSubmit)}
      className={cn('transition-opacity', {
        'pointer-events-none opacity-70': mutation.isPending,
      })}
    >
      <div className={'hstack center gap-8 pt-4'}>
        {exercise.per_day_count ? (
          <div className={'hstack items-center gap-2'}>
            <HistoryIcon className={'size-4'} />
            <Text size={'2'}>
              {exercise.per_day_count} {exercise.per_day_count === 1 ? 'gang' : 'gange'} dagligt
            </Text>
          </div>
        ) : null}

        {exercise.per_week_count ? (
          <div className={'hstack items-center gap-2'}>
            <CalendarDays className={'size-4'} />
            <Text size={'2'}>
              {exercise.per_week_count} {exercise.per_week_count === 1 ? 'gang' : 'gange'} ugenligt
            </Text>
          </div>
        ) : null}
      </div>

      <Separator className={'m-0'} />

      <Controller
        control={form.control}
        name={'name'}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={'Navn på øvelse'}
            placeholder={exercise.name ?? ''}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name={'description'}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            label={'Beskrivelse'}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name={'sets'}
        render={({ field, fieldState }) => (
          <NumberField
            {...field}
            label={'Antal sæt'}
            minValue={1}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name={'exercise_input_type'}
        render={({ field, fieldState }) => (
          <RadioGroup
            {...field}
            label={'Øvelsestype'}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
            className={'self-start'}
          >
            <Radio value={'reps'}>Reps</Radio>
            <Radio value={'range'}>Rep range</Radio>
            <Radio value={'time'}>Tid</Radio>
          </RadioGroup>
        )}
      />

      {exerciseInputType === 'reps' ? (
        <Controller
          control={form.control}
          name={'reps_range.maximum'}
          render={({ field, fieldState }) => (
            <NumberField
              {...field}
              label={'Reps'}
              minValue={1}
              validationBehavior={'aria'}
              isInvalid={fieldState.invalid}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      ) : null}

      {exerciseInputType === 'range' ? (
        <div className={'grid w-full grid-cols-2 items-baseline gap-4'}>
          <Controller
            control={form.control}
            name={'reps_range.minimum'}
            render={({ field, fieldState }) => (
              <NumberField
                {...field}
                label={'Min. reps'}
                minValue={1}
                maxValue={repsRangeMaximum - 1}
                validationBehavior={'aria'}
                isInvalid={fieldState.invalid}
                errorMessage={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={form.control}
            name={'reps_range.maximum'}
            render={({ field, fieldState }) => (
              <NumberField
                {...field}
                label={'Maks. reps'}
                minValue={1}
                validationBehavior={'aria'}
                isInvalid={fieldState.invalid}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </div>
      ) : null}

      {exerciseInputType === 'time' ? (
        <div className={'grid w-full grid-cols-2 items-baseline gap-4'}>
          <Controller
            control={form.control}
            name={'minutes'}
            render={({ field, fieldState }) => (
              <NumberField
                {...field}
                label={'Minutter'}
                minValue={0}
                maxValue={59}
                validationBehavior={'aria'}
                isInvalid={fieldState.invalid}
                errorMessage={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={form.control}
            name={'seconds'}
            render={({ field, fieldState }) => (
              <NumberField
                {...field}
                label={'Sekunder'}
                minValue={minutes === 0 ? 1 : 0}
                maxValue={59}
                validationBehavior={'aria'}
                isInvalid={fieldState.invalid}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </div>
      ) : null}

      <Controller
        control={form.control}
        name={'days'}
        render={({ field, fieldState }) => (
          <NumberField
            {...field}
            label={'Antal gange pr. dag'}
            minValue={1}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name={'weeks'}
        render={({ field, fieldState }) => (
          <NumberField
            {...field}
            label={'Antal dage pr. uge'}
            minValue={1}
            maxValue={7}
            validationBehavior={'aria'}
            isInvalid={fieldState.invalid}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={form.control}
        name={'is_weight_included'}
        render={({ field: { value, ...field } }) => (
          <Switch
            {...field}
            isSelected={value}
            onChange={(isSelected) => {
              form.setValue('starting_weight', 1)
              field.onChange(isSelected)
            }}
          >
            Skal patienten indtaste vægt
          </Switch>
        )}
      />

      {isWeightIncluded ? (
        <Controller
          control={form.control}
          name={'starting_weight'}
          render={({ field, fieldState }) => (
            <NumberField
              {...field}
              label={'Startvægt (kg)'}
              minValue={1}
              validationBehavior={'aria'}
              isInvalid={fieldState.invalid}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      ) : null}

      <Controller
        control={form.control}
        name={'is_relevant'}
        render={({ field: { value, ...field } }) => (
          <Switch {...field} isSelected={value}>
            Tilføj til aktuelle øvelser
          </Switch>
        )}
      />

      <Separator />

      <Button
        type={'submit'}
        variant={'solid'}
        color={'accent'}
        size={'md'}
        className={'self-start'}
        isDisabled={!form.formState.isDirty}
      >
        Gem ændringer
      </Button>
    </Form>
  )
}

/**
 * Retrieves default time values based on the exercise's input type and maximum repetition range.
 *
 * If the exercise input type is anything but 'time', the default time values are set to 0 minutes
 * and 1. Otherwise, we calculate the default time values based on the maximum repetition range.
 *
 * @param exercise - The exercise object containing details about the exercise.
 * @returns The default time values in minutes and seconds.
 */
function getDefaultTimeValues(exercise: Schemas['ExerciseDto']): {
  minutes: number
  seconds: number
} {
  return match(exercise.exercise_input_type)
    .with('time', () => {
      return getMinutesAndSecondsFromMaxReps(exercise.rep_range?.maximum ?? 1).unwrapOr({
        minutes: 0,
        seconds: 1,
      })
    })
    .otherwise(() => ({
      minutes: 0,
      seconds: 1,
    }))
}

/**
 * Returns the default repetition values based on the exercise input type.
 *
 * If the exercise input type is 'reps', the minimum reps are set to 0 and the maximum reps are set
 * to the maximum reps of the exercise.
 *
 * If the exercise input type is 'range', the minimum reps are set to the minimum reps of the
 * exercise and the maximum reps are set to the maximum reps of the exercise.
 *
 * Otherwise, the minimum reps is set to 0 and the maximum reps is set to 1.
 *
 * @param exercise - The exercise data transfer object.
 * @returns An object containing the reps_range with minimum and maximum values.
 */
function getDefaultRepValues(exercise: Schemas['ExerciseDto']): {
  reps_range: { minimum: number; maximum: number }
} {
  const defaultMin = 0
  const defaultMax = 1

  return match(exercise.exercise_input_type)
    .with('reps', () => ({
      reps_range: {
        minimum: 1,
        maximum: exercise.rep_range?.maximum ?? defaultMax,
      },
    }))
    .with('range', () => ({
      reps_range: {
        minimum: exercise.rep_range?.minimum ?? defaultMin,
        maximum: exercise.rep_range?.maximum ?? defaultMax,
      },
    }))
    .otherwise(() => ({
      reps_range: { minimum: defaultMin, maximum: defaultMax },
    }))
}
