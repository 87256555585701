import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'

import { clinicPatientMutations } from '@/api/fysioscout/endpoints/clinic-patient/mutations'
import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

interface CreateClinicPatientOptions {
  redirectToPatient?: boolean
}

/**
 * Create a clinic patient.
 *
 * @example
 *   const { mutate, isLoading, isError } = useCreateClinicPatient()
 *   mutate(createClinicPatientOptions)
 *
 * @param opts - Options for creating the clinic patient.
 * @param opts.redirectToPatient - Whether to redirect to the patient view after creation.
 * @returns - The result of the mutation.
 */
export function useCreateClinicPatient({
  redirectToPatient = false,
}: CreateClinicPatientOptions = {}) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicPatientMutations.create,
    onSuccess: async (response) => {
      if (redirectToPatient) {
        await navigate({
          to: '/patients/$patientId',
          params: { patientId: response.id },
        })
      }

      void queryClient.invalidateQueries({ queryKey: clinicPatientQueries.lists() })
    },
  })
}
