'use client'

import { endOfMonth, getLocalTimeZone, startOfMonth, today } from '@internationalized/date'
import {
  CalendarCell as RACCalendarCell,
  CalendarGrid as RACCalendarGrid,
  CalendarGridBody as RACCalendarGridBody,
  RangeCalendar as RACRangeCalendar,
  Text as RACText,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { DateValue, RangeCalendarProps as RACRangeCalendarProps } from 'react-aria-components'

import { cn, focusRing } from '../../utils'
import { CalendarGridHeader, CalendarHeader } from '../calendar/calendar'

export interface RangeCalendarProps<T extends DateValue>
  extends Omit<RACRangeCalendarProps<T>, 'visibleDuration'> {
  errorMessage?: string
}

const cellStyles = tv({
  extend: focusRing,
  base: 'text-subtle-foreground flex size-full items-center justify-center rounded-full',
  variants: {
    selectionState: {
      none: 'group-hover:bg-neutral-4',
      middle: 'text-foreground group-hover:bg-accent-5 group-invalid:group-hover:bg-red-5',
      cap: 'bg-accent-9 text-accent-contrast hover:bg-accent-10 group-pressed:bg-accent-9 group-invalid:text-red-contrast group-invalid:bg-red-9 group-invalid:pressed:bg-red-9',
    },
    isToday: {
      true: 'bg-neutral-5 group-hover:bg-neutral-6 group-pressed:bg-neutral-7',
    },
    isDisabled: {
      true: 'text-disabled-foreground group-invalid:text-red-12',
    },
    isOutsideMonth: {
      true: 'invisible',
    },
  },
  compoundVariants: [
    {
      isToday: true,
      selectionState: 'middle',
      className: 'bg-accent-6 group-hover:bg-accent-7',
    },
    {
      isToday: true,
      selectionState: 'cap',
      className: 'bg-accent-9 group-hover:bg-accent-10',
    },
  ],
})

export function RangeCalendar<T extends DateValue>({
  errorMessage,
  ...props
}: RangeCalendarProps<T>) {
  return (
    <RACRangeCalendar {...props}>
      <CalendarHeader />

      <RACCalendarGrid className={'[&_td]:px-0'}>
        <CalendarGridHeader />

        <RACCalendarGridBody>
          {(date) => {
            const firstDayOfMonth = startOfMonth(date)
            const lastDayOfMonth = endOfMonth(date)
            const todayDate = today(getLocalTimeZone())

            const isFirstDayOfMonth = date.compare(firstDayOfMonth) === 0
            const isLastDayOfMonth = date.compare(lastDayOfMonth) === 0
            const isToday = date.compare(todayDate) === 0

            return (
              <RACCalendarCell
                data-today={isToday}
                date={date}
                data-first-of-month={isFirstDayOfMonth || undefined}
                data-last-of-month={isLastDayOfMonth || undefined}
                className={cn([
                  'group size-9 cursor-default text-xs font-medium outline outline-0',
                  'outside-month:text-disabled-foreground',
                  'invalid:selected:bg-red-4',
                  'selected:bg-accent-4 selection-start:rounded-s-full selection-end:rounded-e-full',
                  '[td:first-child_&]:rounded-s-full [td:last-child_&]:rounded-e-full',
                  'data-[first-of-month]:rounded-s-full data-[last-of-month]:rounded-e-full',
                ])}
              >
                {({ formattedDate, isSelected, isSelectionStart, isSelectionEnd, ...rest }) => (
                  <span
                    className={cellStyles({
                      isToday,
                      selectionState:
                        isSelected && (isSelectionStart || isSelectionEnd)
                          ? 'cap'
                          : isSelected
                            ? 'middle'
                            : 'none',
                      ...rest,
                    })}
                  >
                    {formattedDate}
                  </span>
                )}
              </RACCalendarCell>
            )
          }}
        </RACCalendarGridBody>
      </RACCalendarGrid>

      {errorMessage ? (
        <RACText slot={'errorMessage'} className={'text-red text-sm'}>
          {errorMessage}
        </RACText>
      ) : null}
    </RACRangeCalendar>
  )
}
