import { create, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useEditTreatmentNameContext } from '@/features/treatments/user-actions/edit-treatment-name/context'

export interface EditTreatmentNameStore {
  isOpen: boolean
  treatment: Schemas['TreatmentDto'] | null
  actions: {
    open: () => void
    close: () => void
    toggle: () => void
    reset: () => void

    setTreatment: (treatment: Schemas['TreatmentDto']) => void
  }
}

type InitialState = Omit<EditTreatmentNameStore, 'actions'>

const INITIAL_STATE = {
  isOpen: false,
  treatment: null,
} satisfies InitialState

export function editTreatmentNameStore(initialState: InitialState = INITIAL_STATE) {
  return create<EditTreatmentNameStore>((set) => ({
    ...initialState,
    actions: {
      open: () => {
        set({ isOpen: true })
      },
      close: () => {
        set({ isOpen: false })
      },
      toggle: () => {
        set((state) => ({ isOpen: !state.isOpen }))
      },
      reset: () => {
        set(INITIAL_STATE)
      },
      setTreatment: (treatment) => {
        set({ treatment })
      },
    },
  }))
}

export function useEditTreatmentNameStore<T>(selector: (state: EditTreatmentNameStore) => T) {
  const { store } = useEditTreatmentNameContext()

  return useStore(store, selector)
}

export function useEditTreatmentNameActions() {
  return useEditTreatmentNameStore((state) => state.actions)
}

export function useTreatment() {
  return useEditTreatmentNameStore((state) => state.treatment)
}

export function useIsOpen() {
  return useEditTreatmentNameStore((state) => state.isOpen)
}
