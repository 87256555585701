import { tv } from 'tailwind-variants'

export const headingStyles = tv({
  base: 'font-heading text-foreground text-balance font-bold',
  variants: {
    size: {
      '1': 'text-xs leading-normal tracking-wide',
      '2': 'text-sm leading-normal tracking-normal',
      '3': 'text-base leading-normal tracking-normal',
      '4': 'text-lg leading-snug tracking-tight',
      '5': 'text-xl leading-snug tracking-tight',
      '6': 'text-2xl leading-tight tracking-tight',
      '7': 'text-3xl leading-tight tracking-tight',
      '8': 'text-4xl leading-tight tracking-tight',
      '9': 'text-6xl leading-none tracking-tighter',
    },
    medium: {
      true: 'font-medium',
    },
  },
  defaultVariants: {
    size: '4',
  },
})
