'use client'

import React from 'react'
import { CalendarDaysIcon, CheckIcon } from 'lucide-react'
import {
  composeRenderProps,
  DateRangePicker as RACDateRangePicker,
  DateRangePickerStateContext as RACDateRangePickerStateContext,
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
} from 'react-aria-components'
import { entries, keys } from 'remeda'
import { tv } from 'tailwind-variants'
import { match, P } from 'ts-pattern'

import type {
  DateValue,
  DateRangePickerProps as RACDateRangePickerProps,
  ListBoxItemProps as RACListBoxItemProps,
  ListBoxProps as RACListBoxProps,
  Selection,
  ValidationResult,
} from 'react-aria-components'
import type { PresetRangeGroup, PresetRangeOption } from './date-range-picker.utils'

import { Description, FieldError, FieldGroup, FieldTrigger, Label } from '../../forms/field/field'
import { Separator } from '../../misc/separator/separator'
import { Dialog } from '../../overlays/dialog/dialog'
import { Popover } from '../../overlays/popover/popover'
import { composeTailwindRenderProps, focusRing } from '../../utils'
import { DateInput } from '../date-field/date-field'
import { RangeCalendar } from '../range-calendar/range-calendar'
import { presetRangeConfig } from './date-range-picker.utils'

export const presetRangeGroups = {
  all: keys(presetRangeConfig),
  recent: ['24h', '7d', '14d', '30d'],
  extended: ['90d', '6m', '1y'],
} satisfies Record<PresetRangeGroup, PresetRangeOption[]>

export interface DateRangePickerProps<T extends DateValue> extends RACDateRangePickerProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  presetRanges?: PresetRangeOption[] | PresetRangeGroup
}

export function DateRangePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  presetRanges,
  ...props
}: DateRangePickerProps<T>) {
  const resolvedPresetRanges = React.useMemo(() => {
    return match(presetRanges)
      .with('all', () => presetRangeGroups.all)
      .with('recent', () => presetRangeGroups.recent)
      .with('extended', () => presetRangeGroups.extended)
      .with(P.array(P.string), (rangeOptions) => rangeOptions)
      .otherwise(() => [])
  }, [presetRanges])

  return (
    <RACDateRangePicker
      {...props}
      className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-1')}
    >
      {label ? <Label>{label}</Label> : null}

      <FieldGroup className={'min-w-64 justify-between gap-2'}>
        <div className={'flex items-center gap-1.5 px-2.5'}>
          <DateInput slot={'start'} className={'flex-none px-0'} />

          <span
            aria-hidden={'true'}
            className={'text-muted-foreground group-disabled:text-disabled-foreground'}
          >
            –
          </span>

          <DateInput slot={'end'} className={'flex-none px-0'} />
        </div>

        <FieldTrigger>
          <CalendarDaysIcon aria-hidden />
        </FieldTrigger>
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>

      <Popover>
        <Dialog>
          <RangeCalendar />

          {presetRanges && presetRanges.length > 0 ? (
            <>
              <Separator className={'my-2'} />
              <PresetRangeList presetRanges={resolvedPresetRanges} />
            </>
          ) : null}
        </Dialog>
      </Popover>
    </RACDateRangePicker>
  )
}

interface PresetRangeListProps<T extends object> extends RACListBoxProps<T> {
  presetRanges: PresetRangeOption[]
}

function PresetRangeList<T extends object>({
  presetRanges,
  className,
  ...rest
}: PresetRangeListProps<T>) {
  const state = React.useContext(RACDateRangePickerStateContext)

  const selectedStartDate = state.value?.start
  const selectedEndDate = state.value?.end

  const handleSelectionChange = (selection: Selection) => {
    if (selection === 'all') return

    const matchingRange = entries(presetRangeConfig).find(([key]) => selection.has(key))

    if (!matchingRange) return

    const [_key, rangeConfig] = matchingRange
    state.setDateRange(rangeConfig.getRange())
  }

  const items = presetRanges.map((rangeOption) => {
    const range = presetRangeConfig[rangeOption]

    return {
      id: rangeOption,
      label: range.label,
    }
  })

  /**
   * Selected keys should be the matching key if the matching keys value (today - duration) is
   * between the selected start and end dates.
   */
  const selectedKeys = React.useMemo(() => {
    if (selectedStartDate && selectedEndDate) {
      const matchingKey = entries(presetRangeConfig).find(([_, { getRange }]) => {
        const range = getRange()

        return (
          selectedStartDate.compare(range.start) === 0 && selectedEndDate.compare(range.end) === 0
        )
      })

      if (matchingKey) {
        return [matchingKey[0]]
      }
    }

    return []
  }, [selectedStartDate, selectedEndDate])

  return (
    <RACListBox
      {...rest}
      aria-label={'Forhåndsvisningsvalg'}
      selectionMode={'single'}
      onSelectionChange={handleSelectionChange}
      selectedKeys={selectedKeys}
      className={composeTailwindRenderProps(className, 'stack gap-0.5')}
      items={items}
    >
      {(item) => <PresetRangeItem textValue={item.label}>{item.label}</PresetRangeItem>}
    </RACListBox>
  )
}

type PresetRangeItemProps = RACListBoxItemProps

const presetRangeItemStyles = tv({
  extend: focusRing,
  base: 'text-subtle-foreground outline-accent-12 hover:bg-neutral-4 pressed:bg-neutral-5 relative flex cursor-default flex-row items-center gap-2 rounded-md px-2 py-1.5 text-xs outline-dashed outline-offset-2 transition-colors duration-100 focus-visible:z-10 disabled:opacity-40',
  variants: {
    isSelected: {
      true: 'bg-accent-5 pressed:bg-accent-5 text-accent-11 hover:bg-accent-4',
    },
  },
})

function PresetRangeItem({ className, children, ...rest }: PresetRangeItemProps) {
  return (
    <RACListBoxItem
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        presetRangeItemStyles({ ...renderProps, className }),
      )}
    >
      {(renderProps) => (
        <>
          <span className={'flex w-5 items-center'}>
            {renderProps.isSelected ? <CheckIcon className={'size-4'} /> : null}
          </span>

          {typeof children === 'function' ? children(renderProps) : children}
        </>
      )}
    </RACListBoxItem>
  )
}
