import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicTreatmentMutations } from '@/api/fysioscout/endpoints/clinic-treatment/mutations'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

/**
 * Custom hook for editing a clinic treatment.
 *
 * @example
 *   const { mutate, isLoading, isError } = useEditClinicTreatment()
 *   mutate(editClinicTreatmentOptions)
 *
 * @returns The mutation object.
 */
export function useEditClinicTreatment() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicTreatmentMutations.update,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: clinicTreatmentQueries.all() })
    },
  })
}
