import type { ExportExercisesStore } from '@/features/patients/user-actions/export-exercises/store'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export const [ExportExercisesContext, useExportExercisesContext] = createStrictContext<{
  store: StoreApi<ExportExercisesStore>
  patient: Schemas['PatientWithEmployee']
}>({
  name: 'ExportExercisesStore',
  errorMessage: 'useExportExercisesContext must be used within a ExportExercisesProvider',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
