'use client'

import React from 'react'
import { ChevronDown } from 'lucide-react'
import {
  composeRenderProps,
  ComboBox as RACComboBox,
  ListBox as RACListBox,
} from 'react-aria-components'

import type {
  ComboBoxProps as RACComboBoxProps,
  ListBoxItemProps as RACListBoxItemProps,
  ValidationResult,
} from 'react-aria-components'
import type { DropdownSectionProps } from '../../collections/list-box/list-box'

import { DropdownItem, DropdownSection } from '../../collections/list-box/list-box'
import {
  Description,
  FieldError,
  FieldGroup,
  FieldTrigger,
  Input,
  Label,
} from '../../forms/field/field'
import { fieldStyles } from '../../forms/field/field.styles'
import { Popover } from '../../overlays/popover/popover'

export interface ComboBoxProps<T extends object> extends Omit<RACComboBoxProps<T>, 'children'> {
  label?: string
  description?: string | null
  errorMessage?: string | ((validation: ValidationResult) => string)
  children: React.ReactNode | ((item: T) => React.ReactNode)
}

export const ComboBox = React.forwardRef(
  <T extends object>(
    { label, description, errorMessage, children, items, className, ...rest }: ComboBoxProps<T>,
    forwardedRef: React.Ref<HTMLInputElement>,
  ) => (
    <RACComboBox
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      <Label>{label}</Label>

      <FieldGroup>
        <Input ref={forwardedRef} />

        <FieldTrigger>
          <ChevronDown aria-hidden />
        </FieldTrigger>
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}

      {rest.isDisabled ? null : <FieldError>{errorMessage}</FieldError>}

      <Popover className={'w-[--trigger-width]'}>
        <RACListBox
          items={items}
          className={
            'max-h-[400px] overflow-auto p-1 outline-0 [clip-path:inset(0_0_0_0_round_.75rem)]'
          }
        >
          {children}
        </RACListBox>
      </Popover>
    </RACComboBox>
  ),
) as <T extends object>(
  props: ComboBoxProps<T> & React.RefAttributes<HTMLInputElement>,
) => React.ReactElement

export type ComboBoxItemProps = RACListBoxItemProps

export function ComboBoxItem(props: ComboBoxItemProps) {
  return <DropdownItem {...props} />
}

export type ComboBoxSectionProps<T> = DropdownSectionProps<T>

export function ComboBoxSection<T extends object>(props: ComboBoxSectionProps<T>) {
  return <DropdownSection {...props} />
}
