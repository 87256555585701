'use client'

import { Slot } from '@radix-ui/react-slot'
import { ChevronRightIcon } from 'lucide-react'
import { Breadcrumb as RACBreadcrumb, Breadcrumbs as RACBreadcrumbs } from 'react-aria-components'

import type {
  BreadcrumbProps as RACBreadcrumbProps,
  BreadcrumbsProps as RACBreadcrumbsProps,
} from 'react-aria-components'

import { FocusRing } from '../../misc/focus-ring/focus-ring'
import { cn, composeTailwindRenderProps } from '../../utils'

export type BreadcrumbsProps<T> = RACBreadcrumbsProps<T>

export function Breadcrumbs<T extends object>({ className, ...rest }: BreadcrumbsProps<T>) {
  return (
    <RACBreadcrumbs
      {...rest}
      className={cn('flex gap-2 [&>li:last-child>svg]:hidden', className)}
    />
  )
}

export type BreadcrumbProps = RACBreadcrumbProps

export function Breadcrumb({ className, children, ...rest }: BreadcrumbProps) {
  return (
    <RACBreadcrumb
      {...rest}
      className={composeTailwindRenderProps(
        className,
        'text-subtle-foreground hover:text-accent-11 flex items-center gap-2 transition-colors duration-150',
      )}
    >
      {(renderProps) => (
        <>
          <FocusRing>
            <Slot className={'outline-none'}>
              {typeof children === 'function' ? children(renderProps) : children}
            </Slot>
          </FocusRing>

          <ChevronRightIcon className={'size-3'} />
        </>
      )}
    </RACBreadcrumb>
  )
}
