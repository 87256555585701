'use client'

import { Text as RACText } from 'react-aria-components'

import type { TextProps as RACTextProps } from 'react-aria-components'
import type { TextVariants } from './text.styles'

import { textStyles } from './text.styles'

interface TextOwnProps {
  /** The HTML element to use for the root node. */
  elementType?:
    | 'blockquote'
    | 'cite'
    | 'label'
    | 'p'
    | 'q'
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'dt'
    | 'dd'
}

export interface TextProps
  extends TextOwnProps,
    Omit<RACTextProps, 'elementType' | 'color'>,
    TextVariants {}

export function Text({
  accent,
  className,
  color,
  isDisabled,
  light,
  medium,
  muted,
  semiBold,
  size,
  subtle,
  ...rest
}: TextProps) {
  const styles = textStyles({
    accent,
    className,
    color,
    isDisabled,
    light,
    medium,
    muted,
    semiBold,
    size,
    subtle,
  })

  return <RACText className={styles} {...rest} />
}
