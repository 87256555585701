import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

import { VideoConsultationView } from './-video-consultation-view'

const DateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)

/**
 * Safely parses a date string.
 *
 * This function checks if the input string is a valid date string and returns the parsed date. If
 * the input string is not a valid date string, it returns undefined.
 *
 * @param val - The input string to be parsed.
 * @returns The parsed date or undefined if the input string is not a valid date string.
 */
function safeDate(val: unknown): string | undefined {
  const result = DateSchema.safeParse(val)

  if (result.success) {
    const date = new Date(result.data)
    return Number.isNaN(date.getTime()) ? undefined : result.data
  }

  return undefined
}

/**
 * Validates the start and end date and returns the validated values.
 *
 * - If the start date is after the end date, the end date is set to undefined.
 * - If the start date is not provided, the end date is set to undefined.
 * - If the end date is not provided, the start date is set to undefined.
 * - If the end date is before the start date, the start date is set to undefined.
 *
 * @param start - The start date represented as a ISO 8601 string (e.g., '2023-01-01').
 * @param end - The end date represented as a ISO 8601 string (e.g., '2023-01-01').
 * @returns The validated start and end date.
 */
function validateDateRange(start: string | undefined, end: string | undefined) {
  if (!start || !end) {
    return { start: undefined, end: undefined }
  }

  if (end <= start) {
    return { start: undefined, end: undefined }
  }

  return {
    start,
    end,
  }
}

const VideoConsultationRouteSearchSchema = z
  .object({
    status: fallback(z.enum(['planned', 'done']), 'planned').default('planned'),
    search: fallback(z.string().optional(), undefined),
    start: fallback(z.preprocess(safeDate, z.string().optional()).optional(), undefined),
    end: fallback(z.preprocess(safeDate, z.string().optional()).optional(), undefined),
  })
  .transform(({ start, end, ...rest }) => ({
    ...rest,
    ...validateDateRange(start, end),
  }))

export const Route = createFileRoute('/_auth/video-consultation/')({
  validateSearch: zodSearchValidator(VideoConsultationRouteSearchSchema),
  loaderDeps: ({ search: { status } }) => ({ status }),
  component: VideoConsultationView,
})
