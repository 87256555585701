'use client'

import React from 'react'
import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'
import type { HeadingProps } from '../../typography/heading/heading'

import { Heading } from '../../typography/heading/heading'

const panelStyles = tv({
  base: 'border-surface-border bg-surface gap-2 overflow-hidden rounded-lg border transition-colors duration-150',
  variants: {},
})

type PanelVariants = VariantProps<typeof panelStyles>

export interface PanelProps extends PanelVariants, React.HTMLAttributes<HTMLDivElement> {
  /** Optional children to be rendered within the list item. */
  children?: React.ReactNode
}

export function Panel({ className, ...rest }: PanelProps) {
  const styles = panelStyles({ className })

  return <div className={styles} {...rest} />
}

export type PanelHeadingProps = HeadingProps

export function PanelHeading({ children, ...rest }: PanelHeadingProps) {
  return (
    <div data-testid={'panel-heading'}>
      <div className={'p-4'}>
        <Heading level={3} size={'4'} medium {...rest}>
          {children}
        </Heading>
      </div>
    </div>
  )
}
