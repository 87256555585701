'use client'

import React from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { clsx } from 'clsx'
import { mergeProps, useButton, useFocusRing, useHover } from 'react-aria'
import { LinkContext, useContextProps } from 'react-aria-components'

import type { ButtonProps as RACButtonProps } from 'react-aria-components'
import type { ButtonVariants } from '../button.styles'

import { cn } from '../../utils'
import { buttonStyles } from '../button.styles'

interface LinkButtonOwnProps {
  /** Children of the button. */
  children?: React.ReactNode

  /** Optional CSS class name for the link button. */
  className?: string

  iconStart?: React.ReactNode
  iconEnd?: React.ReactNode
}

export interface LinkButtonProps
  extends LinkButtonOwnProps,
    Omit<ButtonVariants, 'isLoading' | 'isPending'>,
    Omit<RACButtonProps, 'className' | 'children'> {}

export const LinkButton = React.forwardRef<React.ElementRef<'a'>, LinkButtonProps>((props, ref) => {
  ;[props, ref] = useContextProps(props, ref, LinkContext)
  const { isFocusVisible, isFocused, focusProps } = useFocusRing(props)
  const { isHovered, hoverProps } = useHover(props)
  const { isPressed, buttonProps } = useButton({ ...props, elementType: 'a' }, ref)

  const { iconStart, iconEnd, children, ...rest } = props

  const className = buttonStyles({
    ...rest,
    isFocusVisible,
    className: clsx('gap-1.5', props.className, { 'cursor-pointer': !props.isDisabled }),
  })

  return (
    <Slot
      data-testid={'link-button'}
      ref={ref}
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      className={className}
      data-disabled={props.isDisabled ?? undefined}
      data-focus-visible={isFocusVisible || undefined}
      data-focused={isFocused || undefined}
      data-hovered={isHovered || undefined}
      data-pressed={isPressed || undefined}
      data-rac={''}
      tabIndex={props.isDisabled ? -1 : 0}
    >
      {iconStart ? <Icon className={'mr-1.5'}>{iconStart}</Icon> : null}
      <Slottable>{children}</Slottable>
      {iconEnd ? <Icon className={'ml-1.5'}>{iconEnd}</Icon> : null}
    </Slot>
  )
})

function Icon({ children, className }: { children: React.ReactNode; className?: string }) {
  return <Slot className={cn('size-[1.2em]', className)}>{children}</Slot>
}

LinkButton.displayName = 'LinkButton'
