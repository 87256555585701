import { Drawer } from '@fysioscout/ui/overlays/drawer'

import {
  useEditExercisesActions,
  useExerciseId,
  useIsOpen,
} from '@/features/treatments/user-actions/edit-exercise/store'

import { EditExerciseDialog } from './edit-exercise-dialog'

export function EditExerciseDrawer() {
  const actions = useEditExercisesActions()
  const exerciseId = useExerciseId()
  const isOpen = useIsOpen()

  if (!exerciseId) return null

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.onOpenChange} size={'2'}>
      <EditExerciseDialog exerciseId={exerciseId} />
    </Drawer>
  )
}
