'use client'

import {
  composeRenderProps,
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { ReactNode } from 'react'
import type {
  RadioGroupProps as RACRadioGroupProps,
  RadioProps as RACRadioProps,
  ValidationResult as RACValidationResult,
} from 'react-aria-components'

import { Text } from '../../typography/text/text'
import { cn, composeTailwindRenderProps, focusRing } from '../../utils'
import { Description, FieldError, Label } from '../field/field'

export interface RadioGroupListProps extends Omit<RACRadioGroupProps, 'children'> {
  label?: string
  children?: ReactNode
  description?: string
  errorMessage?: string | ((validation: RACValidationResult) => string)
}

export function RadioGroupList({
  label,
  description,
  errorMessage,
  className,
  children,
  ...rest
}: RadioGroupListProps) {
  return (
    <RACRadioGroup
      {...rest}
      className={composeTailwindRenderProps(
        className,
        'stack w-full items-start gap-2 transition-opacity duration-150 disabled:opacity-40',
      )}
    >
      <Label>
        <Text slot={'description'}>{label}</Text>
      </Label>

      {children}

      {description ? <Description>{description}</Description> : null}

      {rest.isDisabled ? null : <FieldError>{errorMessage}</FieldError>}
    </RACRadioGroup>
  )
}

const radioStyles = tv({
  extend: focusRing,
  base: 'border-neutral-6 hover:bg-neutral-3 selected:bg-accent-5 hover:border-neutral-7 selected:border-accent-9 hstack text-muted-foreground selected:text-foreground hover:text-foreground w-full cursor-default items-center justify-between gap-8 rounded border p-3 text-left transition-colors duration-150',
  variants: {
    isSelected: {
      false: '',
      true: '',
    },
    isInvalid: {
      true: '',
    },
    isDisabled: {
      true: '',
    },
  },
})

export function RadioListRadio(props: RACRadioProps) {
  return (
    <RACRadio
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        radioStyles({ ...renderProps, className }),
      )}
    >
      {(renderProps) => (
        <>
          <Text size={'2'} slot={'description'} color={'current'}>
            {typeof props.children === 'function' ? props.children(renderProps) : props.children}
          </Text>

          <div
            data-selected={renderProps.isSelected || undefined}
            className={cn(
              'border-neutral-7 bg-field selected:bg-accent-9 selected:border-accent-9 group flex size-4 items-center justify-center rounded-full border transition-colors',
            )}
          >
            <div className={'group-selected:bg-white size-1.5 rounded-full transition-colors'} />
          </div>
        </>
      )}
    </RACRadio>
  )
}
