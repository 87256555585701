import React from 'react'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'

import { useAddExercisesActions } from '@/features/treatments/user-actions/add-exercise/store'

export function AddExercisesTrigger() {
  const actions = useAddExercisesActions()

  return (
    <Button
      variant={'solid'}
      color={'accent'}
      iconEnd={<PlusIcon />}
      onPress={() => {
        actions.open()
      }}
    >
      Tilføj øvelser
    </Button>
  )
}
