import { createStrictContext } from '@fysioscout/utils/react'

type Theme = 'light' | 'dark' | 'system'

interface ThemeContextValue {
  theme: Theme
  setTheme: (theme: Theme) => void
  isDarkMode: boolean
}

export const [ThemeContext, useThemeContext] = createStrictContext<ThemeContextValue>({
  name: 'ThemeContext',
  errorMessage: 'useThemeContext must be used within a ThemeContext.Provider.',
  allowMissingProvider: false,
})
