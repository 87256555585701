import { snakeKeys } from 'string-ts'

import type { TransferClinicTemplateExercisesOptions } from '@/api/fysioscout/endpoints/clinic-template-exercise/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTemplateExerciseMutations = {
  /**
   * Transfer templates to patient
   *
   * @param body - The request body.
   * @returns The mutation result
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Template-Exercise-or-Shared/paths/~1api~1clinics~1template-exercises~1transfer/post}
   */
  transferClinicTemplateExercises: (
    body: CamelKeys<TransferClinicTemplateExercisesOptions['body']>,
  ) => {
    return unwrap(
      fysioscoutClient.POST('/api/clinics/template-exercises/transfer', { body: snakeKeys(body) }),
    )
  },
}
