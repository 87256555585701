import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { ExportExercisesDrawer } from '@/features/patients/user-actions/export-exercises/components/export-exercises-drawer'
import { ExportExercisesContext } from '@/features/patients/user-actions/export-exercises/context'
import { createExportExercisesStore } from '@/features/patients/user-actions/export-exercises/store'

interface ExportExercisesProviderProps {
  patient: Schemas['PatientWithEmployee']
  children?: React.ReactNode
}

export function ExportExercisesProvider({ patient, children }: ExportExercisesProviderProps) {
  const [store] = React.useState(() => createExportExercisesStore())

  return (
    <ExportExercisesContext.Provider value={{ patient, store }}>
      <ExportExercisesDrawer />

      {children}
    </ExportExercisesContext.Provider>
  )
}
