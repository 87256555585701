import { useTitle } from 'ahooks'

import { PageHeading, PageLayout } from '@/components/layouts/page-layout'

export function SettingsView() {
  useTitle('Indstillinger')

  return (
    <PageLayout>
      <PageHeading>Indstillinger</PageHeading>
    </PageLayout>
  )
}
