import { useQuery } from '@tanstack/react-query'
import ms from 'ms'

import { isTokenCloseToExpiry } from '@fysioscout/fysioscout-js/auth'

import { refreshAccessToken } from '@/features/auth/api/refresh-access-token'
import { getSession, setSession } from '@/stores/auth-store'

/**
 * A custom hook that manages the token for authentication.
 *
 * @returns The result of the token management.
 */
export function useSession() {
  return useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const jwt = getSession()

      if (!jwt || isTokenCloseToExpiry(jwt)) {
        const result = await refreshAccessToken()

        return result.match(
          (token) => {
            setSession(token)
            return token
          },
          (error) => {
            throw error
          },
        )
      }

      return jwt
    },
    refetchInterval: ms('2m'),
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: 0,
  })
}
