import type { ChangePractitionerStore } from '@/features/patients/user-actions/change-practitioner/store'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export const [ChangePractitionerContext, useChangePractitioner] = createStrictContext<{
  store: StoreApi<ChangePractitionerStore>
  employees: Schemas['ClinicEmployeesDetails'][]
}>({
  name: 'ChangePractitionerStore',
  errorMessage: 'useChangePractitionerContext must be used within a ChangePractitionerProvider',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
