'use client'
'use client'

import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { AnimatePresence, motion } from 'framer-motion'
import { useObjectRef } from 'react-aria'
import { composeRenderProps, Button as RACButton } from 'react-aria-components'
import { useSpinDelay } from 'spin-delay'

import type { ButtonProps as RACButtonProps } from 'react-aria-components'
import type { ButtonVariants } from '../button.styles'

import { Spinner } from '../../status/spinner/spinner'
import { cn } from '../../utils'
import { useButtonContext } from '../button.context'
import { buttonStyles } from '../button.styles'

const ICON_SIZE = 'size-[1.2em]'

export interface ButtonOwnProps {
  /** Icon to display at the start of the button. */
  iconStart?: React.ReactNode

  /** Icon to display at the end of the button. */
  iconEnd?: React.ReactNode

  /** Content to display inside the button. */
  children?: React.ReactNode
}

export interface ButtonProps
  extends ButtonOwnProps,
    Omit<ButtonVariants, 'isLoading' | 'isIconOnly' | 'isPending'>,
    Omit<RACButtonProps, 'children'> {}

export const Button = React.forwardRef<React.ElementRef<typeof RACButton>, ButtonProps>(
  ({ iconStart, iconEnd, children, ...rest }, forwardedRef) => {
    const buttonContext = useButtonContext()
    const ref = useObjectRef(forwardedRef)

    const [isIconOnly, setIsIconOnly] = React.useState(false)
    const [isWithIcon, setIsWithIcon] = React.useState(false)

    let isLoading = useSpinDelay(rest.isPending ?? false, { minDuration: 500, delay: 500 })

    if (rest.isDisabled) {
      isLoading = false
    }

    React.useLayoutEffect(() => {
      const buttonNode = ref.current
      const svgs = buttonNode?.querySelectorAll('svg')
      const hasOneSvg = svgs?.length === 1

      // Check for text content excluding whitespace
      const noText = buttonNode?.textContent?.trim() === ''

      if (hasOneSvg && noText) {
        setIsIconOnly(true)
      }

      if (hasOneSvg && !noText) {
        setIsWithIcon(true)
      }
    }, [ref])

    const renderChildren = () => {
      if (isIconOnly && isLoading) {
        return <Spinner />
      }

      if (isIconOnly) {
        return children
      }

      return (
        <>
          <AnimatePresence mode={'wait'}>
            {isLoading && !isWithIcon ? <ButtonSpinner /> : null}
          </AnimatePresence>

          {iconStart ? (
            <Icon className={'mr-1.5'}>{isLoading ? <Spinner /> : iconStart}</Icon>
          ) : null}

          {children}

          {iconEnd ? <Icon className={'ml-1.5'}>{isLoading ? <Spinner /> : iconEnd}</Icon> : null}
        </>
      )
    }

    return (
      <RACButton
        {...rest}
        ref={ref}
        data-icon={isIconOnly || undefined}
        className={composeRenderProps(rest.className, (className, renderProps) =>
          buttonStyles({
            ...renderProps,
            ...buttonContext?.variants,
            ...rest,
            isIconOnly,
            className,
            isLoading,
          }),
        )}
      >
        {renderChildren()}
      </RACButton>
    )
  },
)

Button.displayName = 'Button'

function Icon({ children, className }: { children: React.ReactNode; className?: string }) {
  return <Slot className={cn(ICON_SIZE, className)}>{children}</Slot>
}

function ButtonSpinner() {
  return (
    <motion.div
      key={'spinner-container'}
      initial={{ width: 0 }}
      animate={{ width: 'auto', transition: { duration: 0.2 } }}
      exit={{ width: 0, transition: { delay: 0.15, duration: 0.25 } }}
    >
      <motion.div
        key={'spinner'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.2 } }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        className={'mr-1.5'}
      >
        <Spinner className={ICON_SIZE} />
      </motion.div>
    </motion.div>
  )
}
