import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicExerciseMutations } from '@/api/fysioscout/endpoints/clinic-exercise/mutations'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

/**
 * Update a clinic exercise
 *
 * @example
 *   const mutation = useUpdateClinicExercise()
 *   mutation.mutate(updateClinicExerciseOptions)
 *
 * @returns The mutation object
 */
export function useUpdateClinicExercise() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicExerciseMutations.update,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: clinicTreatmentQueries.all() })
    },
  })
}
