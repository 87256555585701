import { dayjs } from '@/lib/dayjs'

export const sortFunctions = {
  string: (a: string, b: string) => a.localeCompare(b),
  date: (a: string | null | undefined, b: string | null | undefined) => {
    if (!a) return -1 // treat null/undefined as earlier dates
    if (!b) return 1 // treat null/undefined as later dates

    return dayjs.instance(a).valueOf() - dayjs.instance(b).valueOf()
  },
  boolean: (a: boolean, b: boolean) => Number(a) - Number(b),
}
