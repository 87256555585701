import React from 'react'
import { UserRoundIcon } from 'lucide-react'
import { useSessionStorage } from 'usehooks-ts'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTrigger,
} from '@fysioscout/ui/layout/collapsible-panel'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { PatientTable } from '@/features/patients/components/patient-table'

interface EmployeePatientsProps {
  /** List of patient data particularly associated with a clinic employee. */
  data: Schemas['EmployeePatients'][]

  /** Optional CSS class name to style the root element of the component. */
  className?: string
}

export function PatientsByEmployee(props: EmployeePatientsProps) {
  const [openPanels, setOpenPanels] = useSessionStorage<string[]>(
    'fs-patients-by-employee-panels',
    [],
  )

  return (
    <div data-testid={'patients'} className={props.className}>
      <div aria-label={'Patienter'} className={'stack gap-3'}>
        {props.data.length === 0 ? (
          <Text>Ingen patienter fundet.</Text>
        ) : (
          props.data.map(({ patients, employee }) => (
            <CollapsiblePanel
              key={employee.id}
              isDisabled={patients.length === 0}
              isOpen={patients.length > 0 ? openPanels.includes(employee.id) : undefined}
              onOpenChange={(isOpen) => {
                setOpenPanels((openPanels) => {
                  if (isOpen) {
                    return [...openPanels, employee.id]
                  }

                  return openPanels.filter((id) => id !== employee.id)
                })
              }}
            >
              {({ isOpen }) => (
                <EmployeePatientPanel employee={employee} patients={patients} isOpen={isOpen} />
              )}
            </CollapsiblePanel>
          ))
        )}
      </div>
    </div>
  )
}

interface EmployeePatientPanelProps {
  employee: Schemas['ClinicEmployeesDetails']
  patients: Schemas['PatientWithEmployee'][]
  isOpen: boolean
}

function EmployeePatientPanel({ employee, patients, isOpen }: EmployeePatientPanelProps) {
  const [pageNumber, setPageNumber] = useSessionStorage(`fs-patients-page-${employee.id}`, 1)

  return (
    <>
      <CollapsiblePanelTrigger>
        <div className={'hstack center gap-3'}>
          <UserRoundIcon className={'size-5'} />
          <Text size={'2'} slot={'description'} medium>
            {employee.name}
          </Text>

          <div
            className={cn(
              'bg-neutral-5 group-hover:bg-accent-5 rounded px-3 py-1 text-xs transition-colors',
              { 'bg-accent-5': isOpen },
            )}
          >
            {patients.length} {patients.length === 1 ? 'patient' : 'patienter'}
          </div>
        </div>
      </CollapsiblePanelTrigger>

      <CollapsiblePanelContent>
        <PatientTable
          aria-label={`${employee.name}'s patienter`}
          patients={patients}
          hideColumns={['practitioner', 'deletion']}
          itemsPerPage={8}
          onPageChange={setPageNumber}
          pageNumber={pageNumber}
        />
      </CollapsiblePanelContent>
    </>
  )
}
