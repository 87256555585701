import { createFileRoute, redirect } from '@tanstack/react-router'

import { AccountView } from './-account-view'

export const Route = createFileRoute('/_auth/account/')({
  beforeLoad: ({ context: { hypertune } }) => {
    const isEnabled = hypertune.enableAccountPage({ fallback: false })

    if (!isEnabled) {
      throw redirect({ to: '/patients' })
    }
  },
  component: AccountView,
})
