import { Drawer } from '@fysioscout/ui/overlays/drawer'

import { ChangePractitionerDialog } from '@/features/patients/user-actions/change-practitioner/components/change-practitioner-dialog'
import { useChangePractitioner } from '@/features/patients/user-actions/change-practitioner/context'
import {
  useChangePractitionerActions,
  useIsOpen,
  usePatient,
  usePractitioner,
} from '@/features/patients/user-actions/change-practitioner/store'

export function ChangePractitionerDrawer() {
  const actions = useChangePractitionerActions()
  const isOpen = useIsOpen()
  const patient = usePatient()
  const practitioner = usePractitioner()
  const { employees } = useChangePractitioner()

  if (!patient) return null

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.onOpenChange}>
      <ChangePractitionerDialog
        patient={patient}
        practitioner={practitioner}
        employees={employees}
      />
    </Drawer>
  )
}
