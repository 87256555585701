import React from 'react'

import { AddExercisesDrawer } from './add-exercises-drawer'
import { AddExercisesProvider } from './add-exercises-provider'
import { AddExercisesTrigger } from './add-exercises-trigger'

export function AddExercises() {
  return (
    <AddExercisesProvider>
      <AddExercisesTrigger />
      <AddExercisesDrawer />
    </AddExercisesProvider>
  )
}
