import type { CreatePatientStore } from '@/features/patients/user-actions/create-patient/store'
import type { StoreApi } from 'zustand'

import { createStrictContext } from '@fysioscout/utils/react'

export const [CreatePatientContext, useCreatePatient] = createStrictContext<{
  store: StoreApi<CreatePatientStore>
}>({
  name: 'CreatePatientStore',
  errorMessage: 'useCreatePatientContext must be used within a CreatePatientProvider',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
