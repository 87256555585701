import React from 'react'
import { EllipsisIcon, PencilIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { doNothing } from 'remeda'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { Key } from 'react-aria-components'
import type { CamelCase } from 'string-ts'
import type { ExerciseTableAction } from './exercise-table.context'

import { Button } from '@fysioscout/ui/buttons/button'
import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'

import { useEditExercisesActions } from '@/features/treatments/user-actions/edit-exercise/store'

import { useExerciseTableContext } from './exercise-table.context'

const ACTIONS = {
  editExercise: 'edit-exercise',
} as const satisfies Record<CamelCase<ExerciseTableAction>, ExerciseTableAction>

interface ExerciseTableActionsProps {
  exercise: Schemas['ExerciseDto']
}

export function ExerciseTableActions(props: ExerciseTableActionsProps) {
  const context = useExerciseTableContext()
  const editExerciseActions = useEditExercisesActions()

  const handleEditExercise = () => {
    editExerciseActions.setExerciseId(props.exercise.id)

    setTimeout(() => {
      editExerciseActions.open()
    }, 100)
  }

  const handleAction = (key: Key) => {
    match(key)
      .with(ACTIONS.editExercise, handleEditExercise)
      .otherwise(() => doNothing())
  }

  return (
    <MenuTrigger>
      <Button
        variant={'soft'}
        size={'md'}
        color={'neutral'}
        aria-label={`Handlinger for ${props.exercise.name ?? ''}`}
      >
        <EllipsisIcon />
      </Button>

      <Menu
        selectionMode={'none'}
        disallowEmptySelection
        onAction={handleAction}
        placement={'bottom end'}
        disabledKeys={context.disabledActions}
      >
        <MenuItem id={ACTIONS.editExercise} icon={<PencilIcon />}>
          Ret øvelse
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}
