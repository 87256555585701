import React from 'react'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useDebounceFn, useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { SearchField } from '@fysioscout/ui/forms/search-field'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { Route } from '@/app/routes/_auth/patients/index'
import { PageHeading, PageLayout, PageSeparator } from '@/components/layouts/page-layout'
import { AcceptedPatients } from '@/features/patients/components/accepted-patients'
import { PendingPatients } from '@/features/patients/components/pending-patients'
import { useCreatePatientActions } from '@/features/patients/user-actions/create-patient/store'
import { DetachPatientProvider } from '@/features/patients/user-actions/detach-patient/components/detach-patient-provider'
import { useSearchHotkey } from '@/hooks/use-search-hotkey'

export function PatientsView() {
  useTitle('Alle patienter')

  const searchRef = React.useRef<HTMLInputElement>(null)

  const navigate = Route.useNavigate()
  const searchParams = Route.useSearch()

  const [searchQuery, setSearchQuery] = React.useState(searchParams.search)

  const createPatientActions = useCreatePatientActions()

  const { data: list } = useSuspenseQuery(clinicPatientQueries.list(searchParams.status))
  const { data: pending } = useQuery(clinicPatientQueries.list('pending'))

  const handleSearch = useDebounceFn(
    (query: string) => {
      setSearchQuery(query)
      void navigate({
        to: '.',
        search: (prev) => ({ ...prev, search: query || undefined }),
      })
    },
    { wait: 200 },
  )

  useSearchHotkey(searchRef)

  const pendingPatientsCount = pending?.clinic_patients.patients.length

  return (
    <PageLayout>
      <div className={'hstack items-end justify-between'}>
        <PageHeading>Alle patienter</PageHeading>

        <div className={'hstack gap-2'}>
          <LinkButton
            variant={'soft'}
            color={searchParams.status === 'accepted' ? 'accent' : 'neutral'}
            size={'sm'}
          >
            <Link
              from={Route.fullPath}
              to={'.'}
              search={(prev) => ({ ...prev, status: 'accepted' })}
            >
              Accepteret
            </Link>
          </LinkButton>

          <LinkButton
            variant={'soft'}
            color={searchParams.status === 'pending' ? 'accent' : 'neutral'}
            size={'sm'}
          >
            <Link
              from={Route.fullPath}
              to={'.'}
              search={(prev) => ({ ...prev, status: 'pending' })}
            >
              Afventer {pendingPatientsCount ? `(${pendingPatientsCount})` : null}
            </Link>
          </LinkButton>
        </div>
      </div>

      <PageSeparator />

      <div className={'hstack items-end justify-between gap-4'}>
        <SearchField
          ref={searchRef}
          label={'Søg efter patient'}
          className={'min-w-80'}
          onChange={handleSearch.run}
          defaultValue={searchQuery}
          onClear={() => setSearchQuery('')}
          isDisabled={list.employee_patients.length === 0}
        />

        <Button
          variant={'solid'}
          color={'accent'}
          iconEnd={<PlusIcon />}
          onPress={createPatientActions.open}
        >
          Opret patient
        </Button>
      </div>

      <DetachPatientProvider>
        <div className={'mt-8'}>
          {searchParams.status === 'accepted' ? (
            <AcceptedPatients list={list.employee_patients} searchQuery={searchQuery ?? ''} />
          ) : null}

          {searchParams.status === 'pending' ? (
            <PendingPatients list={list.clinic_patients.patients} searchQuery={searchQuery ?? ''} />
          ) : null}
        </div>
      </DetachPatientProvider>
    </PageLayout>
  )
}
