import React from 'react'
import { useEventEmitter } from 'ahooks'

import { AddExercisesContext } from '@/features/treatments/user-actions/add-exercise/context'
import { createAddExercisesStore } from '@/features/treatments/user-actions/add-exercise/store'

export function AddExercisesProvider({ children }: { children?: React.ReactNode }) {
  const [store] = React.useState(() => createAddExercisesStore())
  const resetFilters$ = useEventEmitter()

  return (
    <AddExercisesContext.Provider value={{ store, resetFilters$ }}>
      {children}
    </AddExercisesContext.Provider>
  )
}
