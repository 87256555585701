import React from 'react'

import { CreatePatientDrawer } from '@/features/patients/user-actions/create-patient/components/create-patient-drawer'
import { CreatePatientContext } from '@/features/patients/user-actions/create-patient/context'
import { createPatientStore } from '@/features/patients/user-actions/create-patient/store'

export function CreatePatientProvider({ children }: { children: React.ReactNode }) {
  const [store] = React.useState(() => createPatientStore())

  return (
    <CreatePatientContext.Provider value={{ store }}>
      <CreatePatientDrawer />

      {children}
    </CreatePatientContext.Provider>
  )
}
