'use client'

import React from 'react'
import { ButtonContext as RACButtonContext } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'
import type { ButtonVariants } from '../button.styles'

import { ButtonContext } from '../button.context'

const buttonGroupStyles = tv({
  base: 'flex shrink-0 flex-wrap gap-2',
  variants: {
    orientation: {
      horizontal: 'flex-row',
      vertical: 'flex-col items-start',
    },
  },
})

type ButtonGroupVariants = VariantProps<typeof buttonGroupStyles>

export interface ButtonGroupProps extends ButtonGroupVariants, ButtonVariants {
  /** The child components to render within the ButtonGroup. */
  children?: React.ReactNode

  /** Optional CSS class name for the ButtonGroup. */
  className?: string
}

export function ButtonGroup({
  children,
  className,
  orientation,
  isDisabled,
  ...rest
}: ButtonGroupProps) {
  return (
    <div className={buttonGroupStyles({ orientation, className })}>
      <ButtonContext.Provider value={{ variants: rest }}>
        <RACButtonContext.Provider value={{ isDisabled }}>{children}</RACButtonContext.Provider>
      </ButtonContext.Provider>
    </div>
  )
}
