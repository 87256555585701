import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

export const calloutStyles = tv({
  base: 'hstack items-start justify-start rounded-lg border border-transparent text-left [&_a]:underline [&_a]:underline-offset-2 [&_svg]:mt-0.5 [&_svg]:shrink-0',
  variants: {
    variant: {
      soft: '',
      surface: '',
      outline: '',
    },
    type: {
      accent: 'text-accent-12 [&_a]:text-accent-11 [&_a]:decoration-accent-7',
      neutral: 'text-neutral-12 [&_a]:text-neutral-11 [&_a]:decoration-neutral-7',
      success: 'text-green-12 [&_a]:text-green-11 [&_a]:decoration-green-7',
      warning: 'text-yellow-12 [&_a]:text-yellow-11 [&_a]:decoration-yellow-7',
      error: 'text-red-12 [&_a]:text-red-11 [&_a]:decoration-red-7',
      info: 'text-info-12 [&_a]:text-info-11 [&_a]:decoration-info-7',
    },
    size: {
      '1': 'gap-2 p-3 pr-4 text-sm [&_svg]:size-4',
      '2': 'gap-3 p-4 pr-5 text-sm [&_svg]:size-4',
      '3': 'gap-4 p-6 pr-7 text-base [&_svg]:size-5',
    },
  },
  compoundVariants: [
    /** Soft, Accent */
    {
      variant: 'soft',
      type: 'accent',
      className: 'bg-accentA-3',
    },

    /** Soft, Neutral */
    {
      variant: 'soft',
      type: 'neutral',
      className: 'bg-neutralA-3',
    },

    /** Soft, Success */
    {
      variant: 'soft',
      type: 'success',
      className: 'bg-greenA-3',
    },

    /** Soft, Warning */
    {
      variant: 'soft',
      type: 'warning',
      className: 'bg-yellowA-3',
    },

    /** Soft, Error */
    {
      variant: 'soft',
      type: 'error',
      className: 'bg-redA-3',
    },

    /** Soft, Info */
    {
      variant: 'soft',
      type: 'info',
      className: 'bg-infoA-3',
    },

    /** Surface, Accent */
    {
      variant: 'surface',
      type: 'accent',
      className: 'bg-accentA-2 border-accentA-6',
    },

    /** Surface, Neutral */
    {
      variant: 'surface',
      type: 'neutral',
      className: 'bg-neutralA-2 border-neutralA-6',
    },

    /** Surface, Success */
    {
      variant: 'surface',
      type: 'success',
      className: 'bg-greenA-2 border-greenA-6',
    },

    /** Surface, Warning */
    {
      variant: 'surface',
      type: 'warning',
      className: 'bg-yellowA-2 border-yellowA-6',
    },

    /** Surface, Error */
    {
      variant: 'surface',
      type: 'error',
      className: 'bg-redA-2 border-redA-6',
    },

    /** Surface, Info */
    {
      variant: 'surface',
      type: 'info',
      className: 'bg-infoA-2 border-infoA-6',
    },

    /** Outline, Accent */
    {
      variant: 'outline',
      type: 'accent',
      className: 'border-accentA-6 bg-transparent',
    },

    /** Outline, Neutral */
    {
      variant: 'outline',
      type: 'neutral',
      className: 'border-neutralA-6 bg-transparent',
    },

    /** Outline, Success */
    {
      variant: 'outline',
      type: 'success',
      className: 'border-greenA-6 bg-transparent',
    },

    /** Outline, Warning */
    {
      variant: 'outline',
      type: 'warning',
      className: 'border-yellowA-6 bg-transparent',
    },

    /** Outline, Error */
    {
      variant: 'outline',
      type: 'error',
      className: 'border-redA-6 bg-transparent',
    },

    /** Outline, Info */
    {
      variant: 'outline',
      type: 'info',
      className: 'border-infoA-6 bg-transparent',
    },
  ],
  defaultVariants: {
    variant: 'soft',
    type: 'accent',
    size: '2',
  },
})

export type CalloutVariants = VariantProps<typeof calloutStyles>
