'use client'

import React from 'react'
import { composeRenderProps, TimeField as RACTimeField } from 'react-aria-components'

import type {
  TimeFieldProps as RACTimeFieldProps,
  TimeValue,
  ValidationResult,
} from 'react-aria-components'

import { Description, FieldError, Label } from '../../forms/field/field'
import { fieldBorderStyles, fieldStyles } from '../../forms/field/field.styles'
import { DateInput } from '../date-field/date-field'

export interface TimeFieldProps<T extends TimeValue> extends RACTimeFieldProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export const TimeField = React.forwardRef(
  <T extends TimeValue>(
    { label, description, errorMessage, className, ...rest }: TimeFieldProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <RACTimeField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      <Label>{label}</Label>

      <DateInput ref={ref} className={fieldBorderStyles} />

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACTimeField>
  ),
) as <T extends TimeValue>(
  props: TimeFieldProps<T> & React.RefAttributes<HTMLDivElement>,
) => React.ReactElement
