import { snakeKeys } from 'string-ts'

import type {
  CreateClinicPatientOptions,
  DeleteClinicPatientOptions,
  InviteClinicPatientOptions,
  UpdateClinicPatientEmailOptions,
  UpdateClinicPatientOptions,
} from '@/api/fysioscout/endpoints/clinic-patient/models'
import type { CamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicPatientMutations = {
  create: (body: CreateClinicPatientOptions['body']) => {
    return unwrap(fysioscoutClient.POST('/api/clinics/patients', { body }))
  },
  update: (params: {
    patientId: UpdateClinicPatientOptions['params']['path']['patient_id']
    body: UpdateClinicPatientOptions['body']
  }) => {
    return unwrap(
      fysioscoutClient.PUT('/api/clinics/patients/{patient_id}', {
        body: params.body,
        params: { path: { patient_id: params.patientId } },
      }),
    )
  },
  delete: (patientId: DeleteClinicPatientOptions['params']['path']['patient_id']) => {
    const params = { path: { patient_id: patientId } }

    return unwrap(fysioscoutClient.DELETE('/api/clinics/patients/{patient_id}', { params }))
  },
  invite: async (body: InviteClinicPatientOptions['body']) => {
    return unwrap(fysioscoutClient.PUT('/api/clinics/patients', { body }))
  },
  updateEmail: async (params: {
    path: CamelKeys<UpdateClinicPatientEmailOptions['params']['path']>
    body: CamelKeys<UpdateClinicPatientEmailOptions['body']>
  }) => {
    return unwrap(
      fysioscoutClient.POST('/api/clinics/patients/{patient_id}/email', {
        params: { path: snakeKeys(params.path) },
        body: snakeKeys(params.body),
      }),
    )
  },
}
