import ms from 'ms'

import type { Schemas } from './type-helpers'

/**
 * The REFRESH_THRESHOLD defines how close to expiry a token needs to be before it is considered
 * close to expiry.
 */
export const REFRESH_THRESHOLD_MS = ms('5m')

/**
 * Represents the expiry buffer value.
 *
 * The value is used to determine if a token is close to expiry. The buffer is set to 30 seconds.
 */
export const EXPIRY_BUFFER_MS = ms('30s')

/** Names of different cookies used for authentication. */
export const ACCESS_TOKEN_COOKIE_NAME = 'access_token'
export const REFRESH_TOKEN_COOKIE_NAME = 'refresh_token'
export const TOKEN_EXPIRES_AT_COOKIE_NAME = 'expires_at'

/**
 * Checks if the access token is expired.
 *
 * The access token is considered expired if the current time plus an expiry buffer is greater than
 * the expiration time of the JWT.
 *
 * @param jwt - The JWT object.
 * @returns True if the access token is expired, false otherwise.
 */
export function isAccessTokenExpired(jwt: Schemas['TokenResponse']): boolean {
  const expirationTime = new Date(jwt.expires_at * 1000)
  const currentTime = new Date()
  const bufferTime = new Date(currentTime.getTime() + EXPIRY_BUFFER_MS)

  return bufferTime >= expirationTime
}

/**
 * Checks if the given JWT token is close to expiry.
 *
 * @param jwt - The JWT token to check.
 * @returns Returns true if the token is close to expiry, otherwise false.
 */
export function isTokenCloseToExpiry(jwt: Schemas['TokenResponse']): boolean {
  const expirationTimeMs = new Date(jwt.expires_at * 1000)
  const currentTimeMs = new Date()
  const timeUntilExpiryMs = expirationTimeMs.getTime() - currentTimeMs.getTime()

  return timeUntilExpiryMs <= REFRESH_THRESHOLD_MS
}

/**
 * Creates a function to redirect the user to the login page with a safely encoded redirect URL.
 *
 * @example
 *   const redirectToAuth = createRedirectToAuth('https://auth.example.com')
 *   redirectToAuth({ afterLoginRedirectUrl: 'https://www.fysioscout.dk' })
 *
 * @param authSiteUrl - The URL of the authentication site.
 * @returns A function that performs the redirection.
 */
export function createRedirectToAuth(authSiteUrl: string) {
  return function redirectToAuth(params: { afterLoginRedirectUrl?: string } = {}): void {
    const authUrl = new URL(authSiteUrl)
    const currentUrl = new URL(globalThis.location.href)

    const redirectUrl = params.afterLoginRedirectUrl
      ? new URL(params.afterLoginRedirectUrl)
      : currentUrl

    authUrl.searchParams.set('redirect_to', redirectUrl.toString())

    globalThis.location.replace(authUrl.toString())
  }
}
