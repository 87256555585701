'use client'

import { Check } from 'lucide-react'
import {
  composeRenderProps,
  Collection as RACCollection,
  Header as RACHeader,
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
  Section as RACSection,
} from 'react-aria-components'

import type {
  ListBoxItemProps as RACListBoxItemProps,
  ListBoxProps as RACListBoxProps,
  SectionProps as RACSectionProps,
} from 'react-aria-components'

import { cn, composeTailwindRenderProps } from '../../utils'
import { dropdownItemStyles, itemStyles } from './list-box.styles'

export type ListBoxProps<T> = Omit<RACListBoxProps<T>, 'layout' | 'orientation'>

export function ListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  return (
    <RACListBox
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'border-neutral-6 rounded-md border p-1 outline-0',
      )}
    >
      {children}
    </RACListBox>
  )
}

export function ListBoxItem(props: RACListBoxItemProps) {
  const textValue =
    props.textValue ?? (typeof props.children === 'string' ? props.children : undefined)

  return (
    <RACListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, (children) => (
        <>
          {children}

          <div
            className={
              'bg-whiteA-8 absolute inset-x-4 bottom-0 hidden h-px [.group[data-selected]:has(+[data-selected])_&]:block'
            }
          />
        </>
      ))}
    </RACListBoxItem>
  )
}

export function DropdownItem({ className, ...rest }: RACListBoxItemProps) {
  const textValue =
    rest.textValue ?? (typeof rest.children === 'string' ? rest.children : undefined)

  return (
    <RACListBoxItem {...rest} textValue={textValue} className={dropdownItemStyles}>
      {composeRenderProps(rest.children, (children, { isSelected }) => (
        <>
          <span
            className={cn(
              'group-selected:font-semibold flex flex-1 items-center gap-2 truncate font-normal',
              className,
            )}
          >
            {children}
          </span>

          <span className={'flex w-5 items-center'}>
            {isSelected ? <Check className={'size-4'} /> : null}
          </span>
        </>
      ))}
    </RACListBoxItem>
  )
}

export interface DropdownSectionProps<T> extends RACSectionProps<T> {
  title?: string
}

export function DropdownSection<T extends object>(props: DropdownSectionProps<T>) {
  return (
    <RACSection className={"after:block after:h-[5px] after:content-[''] first:mt-[-5px]"}>
      <RACHeader
        className={
          'bg-neutral-2 sticky top-[-5px] z-10 -mx-1 -mt-px truncate border-y px-4 py-1 text-sm font-semibold backdrop-blur-md [&+*]:mt-1'
        }
      >
        {props.title}
      </RACHeader>

      <RACCollection items={props.items}>{props.children}</RACCollection>
    </RACSection>
  )
}
