import React from 'react'
import { useThrottleFn } from 'ahooks'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Switch } from '@fysioscout/ui/forms/switch'
import { toast } from '@fysioscout/ui/status/toast'

import { useUpdateClinicPatient } from '@/features/patients/api/update-clinic-patient'

interface ToggleChatProps extends React.ComponentPropsWithoutRef<'div'> {
  /** The patient information */
  patient: Schemas['PatientWithEmployee']

  /** The initial boolean state for the toggle. */
  initialValue: boolean
}

export function ToggleChat({ patient, initialValue, ...rest }: ToggleChatProps) {
  const mutation = useUpdateClinicPatient()

  const handleChange = (isSelected: boolean) => {
    mutation.mutate(
      {
        patientId: patient.id,
        body: {
          is_chat_enabled: isSelected,
          clinic_employee_id: patient.clinic_employee?.id,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            `Chat for ${patient.name ?? 'Patienten'} er nu ${isSelected ? 'aktiveret' : 'deaktiveret'}.`,
          )
        },
      },
    )
  }

  const { run } = useThrottleFn(handleChange, {
    wait: 300,
    trailing: false,
  })

  const isSelected = mutation.isPending
    ? (mutation.variables.body.is_chat_enabled ?? undefined)
    : initialValue

  return (
    <div className={'flex items-start'} {...rest}>
      <Switch isSelected={isSelected} hideLabel onChange={run} isDisabled={patient.is_pdf_patient}>
        {({ isSelected }) => (isSelected ? 'Slå chat fra' : 'Slå chat til')}
      </Switch>
    </div>
  )
}
