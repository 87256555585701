'use client'

import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useCollapsiblePanelContext } from './collapsible-panel-context'

export interface CollapsiblePanelContentProps {
  children?: React.ReactNode
}

export function CollapsiblePanelContent(props: CollapsiblePanelContentProps) {
  const { isOpen } = useCollapsiblePanelContext()

  return (
    <AnimatePresence initial={false}>
      {isOpen ? (
        <motion.div
          className={'overflow-hidden'}
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0, transition: { type: 'spring', duration: 0.35, bounce: 0 } }}
          transition={{ type: 'spring', duration: 0.55, bounce: 0.2 }}
        >
          {props.children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
