import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicPatientMutations } from '@/api/fysioscout/endpoints/clinic-patient/mutations'
import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

/**
 * Update clinic patient.
 *
 * @example
 *   const { mutate, isLoading, isError } = useUpdateClinicPatient()
 *   mutate(updateClinicPatientOptions)
 *
 * @returns - The result of the mutation.
 */
export function useUpdateClinicPatient() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicPatientMutations.update,
    onSuccess: async ({ patient_id }) => {
      await queryClient.invalidateQueries({ queryKey: clinicPatientQueries.all() })

      /**
       * Invalidate and refetch the updated patient to avoid potential issues when navigating to a
       * patient that was already loaded but has since been updated from the table view.
       */
      void queryClient.refetchQueries({
        queryKey: clinicPatientQueries.getById(patient_id).queryKey,
        type: 'inactive',
      })

      /**
       * Invalidate and refetch the list of accepted patients to avoid potential sync issues when
       * the patient is updated from the patient view.
       */
      void queryClient.invalidateQueries({
        queryKey: clinicPatientQueries.list('accepted').queryKey,
        type: 'inactive',
      })
    },
  })
}
