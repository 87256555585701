import React from 'react'

interface PaginationOptions {
  totalItems: number
  itemsPerPage: number
  initialPage?: number
  onPageChange?: (page: number) => void
}

export function usePagination({
  totalItems,
  itemsPerPage,
  initialPage = 1,
  onPageChange,
}: PaginationOptions) {
  const [currentPage, setCurrentPage] = React.useState(initialPage)

  const totalPages = React.useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage],
  )

  const handlePageChange = React.useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage)
      }

      onPageChange?.(newPage)
    },
    [totalPages],
  )

  const goToFirstPage = React.useCallback(() => {
    setCurrentPage(1)
    onPageChange?.(1)
  }, [])

  const goToLastPage = React.useCallback(() => {
    setCurrentPage(totalPages)
    onPageChange?.(totalPages)
  }, [totalPages])

  return {
    currentPage,
    totalPages,
    handlePageChange,
    itemsPerPage,
    goToFirstPage,
    goToLastPage,
  }
}
