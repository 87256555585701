'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { ChevronRightIcon } from 'lucide-react'
import { Button as RACButton } from 'react-aria-components'

import { useCollapsiblePanelContext } from './collapsible-panel-context'

export interface CollapsiblePanelTriggerProps {
  children?: React.ReactNode
}

export function CollapsiblePanelTrigger(props: CollapsiblePanelTriggerProps) {
  const { isOpen, onOpenChange, isDisabled } = useCollapsiblePanelContext()

  return (
    <RACButton
      className={
        'bg-surface hover:bg-surface-hover group w-full cursor-default rounded-lg outline-none transition-colors'
      }
      isDisabled={isDisabled}
      onPress={() => {
        onOpenChange(!isOpen)
      }}
    >
      <div className={'hstack items-center justify-between p-4'}>
        {props.children}

        <motion.div
          initial={{ rotate: isOpen ? 90 : 0 }}
          animate={{ rotate: isOpen ? 90 : 0 }}
          transition={{ type: 'spring', duration: 0.7, bounce: 0.4 }}
          className={'mr-2'}
        >
          <ChevronRightIcon className={'size-5'} />
        </motion.div>
      </div>
    </RACButton>
  )
}
