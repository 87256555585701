import React from 'react'
import { Link } from '@tanstack/react-router'
import { drop, pipe, take } from 'remeda'
import { capitalize, replace } from 'string-ts'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { ColumnProps, SortDescriptor, SortDirection, TableProps } from 'react-aria-components'

import {
  Cell,
  Column,
  Pagination,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@fysioscout/ui/collections/table'
import { UnstyledLink } from '@fysioscout/ui/navigation/unstyled-link'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { PatientTableActions } from '@/features/patients/components/patient-table-actions'
import { ToggleChat } from '@/features/patients/user-actions/toggle-chat/components/toggle-chat'
import { dayjs } from '@/lib/dayjs'
import { sortFunctions } from '@/lib/sort-functions'

const COLUMNS = {
  name: 'name',
  email: 'email',
  practitioner: 'practitioner',
  requestDate: 'request-date',
  chat: 'chat',
  deletion: 'deletion',
  actions: 'actions',
} as const

type ColumnId = (typeof COLUMNS)[keyof typeof COLUMNS]

interface ColumnConfig extends Omit<ColumnProps, 'id'> {
  id: ColumnId
  name: string
  isHidden?: boolean
}

interface PatientSearchListProps extends TableProps {
  /** Array of patients paired with their respective practitioners. */
  patients: Schemas['PatientWithEmployee'][]

  /** Whether to hide the practitioner column. */
  hideColumns?: ColumnId[]

  /** The default column to sort by. */
  defaultSortColumn?: ColumnId

  /** The default sort direction. */
  defaultSortDirection?: SortDirection

  /** The page number to display. */
  pageNumber?: number

  /** The function to call when the page changes. */
  onPageChange?: (page: number) => void

  /** The number of items to display per page. */
  itemsPerPage?: number
}

export function PatientTable({
  patients,
  hideColumns,
  defaultSortColumn = 'name',
  defaultSortDirection = 'ascending',
  itemsPerPage = 15,
  pageNumber = 1,
  onPageChange,
  ...rest
}: PatientSearchListProps) {
  const hideName = hideColumns?.includes('name')
  const hideEmail = hideColumns?.includes('email')
  const hideDeletion = hideColumns?.includes('deletion')
  const hidePractitioner = hideColumns?.includes('practitioner')
  const hideRequestDate = hideColumns?.includes('request-date')
  const hideChat = hideColumns?.includes('chat')
  const hideActions = hideColumns?.includes('actions')

  const [currentPage, setCurrentPage] = React.useState(pageNumber)

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: defaultSortColumn,
    direction: defaultSortDirection,
  })

  const columns = React.useMemo(() => {
    return [
      { name: 'Navn', id: 'name', defaultWidth: 200, allowsSorting: true, isHidden: hideName },
      { name: 'Email', id: 'email', defaultWidth: 260, allowsSorting: true, isHidden: hideEmail },
      {
        name: 'Behandler',
        id: 'practitioner',
        defaultWidth: 180,
        allowsSorting: true,
        isHidden: hidePractitioner,
      },
      {
        name: 'Oprettet',
        id: 'request-date',
        defaultWidth: 180,
        allowsSorting: true,
        isHidden: hideRequestDate,
      },
      {
        name: 'Chat',
        id: 'chat',
        defaultWidth: 120,
        width: hideDeletion ? '1fr' : undefined,
        allowsSorting: true,
        isHidden: hideChat,
      },
      {
        name: 'Slettes om',
        id: 'deletion',
        width: '1fr',
        allowsSorting: true,
        isHidden: hideDeletion,
      },
      { name: '', id: 'actions', width: 50, isHidden: hideActions },
    ] satisfies ColumnConfig[]
  }, [hideActions, hideChat, hideDeletion, hideEmail, hideName, hidePractitioner, hideRequestDate])

  const sortedPatients = React.useMemo(() => {
    return [...patients].sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.name, () => sortFunctions.string(a.name ?? '', b.name ?? ''))
        .with(COLUMNS.email, () => sortFunctions.string(a.email, b.email))
        .with(COLUMNS.requestDate, () => sortFunctions.date(a.request_date, b.request_date))
        .with(COLUMNS.chat, () => sortFunctions.boolean(a.is_chat_enabled, b.is_chat_enabled))
        .with(COLUMNS.deletion, () => sortFunctions.date(a.deletion_date, b.deletion_date))
        .with(COLUMNS.practitioner, () =>
          sortFunctions.string(
            a.clinic_employee?.name ?? 'Ingen behandler',
            b.clinic_employee?.name ?? 'Ingen behandler',
          ),
        )
        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [patients, sortDescriptor.column, sortDescriptor.direction])

  const paginatedPatients = React.useMemo(() => {
    return pipe(sortedPatients, drop((currentPage - 1) * itemsPerPage), take(itemsPerPage))
  }, [sortedPatients, currentPage, itemsPerPage])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    onPageChange?.(newPage)
  }

  const showPagination = patients.length > itemsPerPage

  return (
    <div data-testid={'patient-table'} className={'stack'}>
      <Table
        aria-label={'Patienter'}
        selectionMode={'none'}
        className={'mt-2'}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        {...rest}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <>
              {column.isHidden ? null : (
                <Column
                  id={column.id}
                  isRowHeader={column.id === 'name'}
                  allowsSorting={column.allowsSorting}
                  defaultWidth={column.defaultWidth}
                  width={column.width}
                >
                  {column.name}
                </Column>
              )}
            </>
          )}
        </TableHeader>

        <TableBody items={paginatedPatients} renderEmptyState={() => 'Ingen patienter fundet.'}>
          {(patient) => (
            <Row>
              <Cell textValue={patient.name ?? undefined}>
                <UnstyledLink>
                  <Link
                    to={'/patients/$patientId'}
                    params={{ patientId: patient.id }}
                    className={'hover:text-accent-11'}
                  >
                    <Text>{patient.name}</Text>
                  </Link>
                </UnstyledLink>
              </Cell>

              <Cell>
                <Text>{patient.email}</Text>
              </Cell>

              {hidePractitioner ? null : (
                <Cell>
                  <Text>{patient.clinic_employee?.name ?? 'Ingen behandler'}</Text>
                </Cell>
              )}

              <Cell>
                <Text>{dayjs.to(patient.request_date)}</Text>
              </Cell>

              <Cell>
                <ToggleChat patient={patient} initialValue={patient.is_chat_enabled} />
              </Cell>

              {hideDeletion ? null : (
                <Cell>
                  <div className={'hstack items-center gap-2'}>
                    <div
                      className={cn('bg-green-9 size-1.5 rounded-full', {
                        'bg-yellow-9': dayjs
                          .instance(patient.deletion_date)
                          .isBefore(dayjs.instance().add(14, 'day')),
                        'bg-red-9': dayjs
                          .instance(patient.deletion_date)
                          .isBefore(dayjs.instance().add(7, 'day')),
                      })}
                    />
                    <Text>
                      {pipe(
                        dayjs.to(patient.deletion_date),
                        (str) => replace(str, 'om', ' '),
                        capitalize,
                      )}
                    </Text>
                  </div>
                </Cell>
              )}

              <Cell>
                <PatientTableActions
                  patient={patient}
                  practitioner={patient.clinic_employee ?? null}
                />
              </Cell>
            </Row>
          )}
        </TableBody>
      </Table>

      {showPagination ? (
        <Pagination
          totalItems={patients.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  )
}
