import createOpenApiClient from 'openapi-fetch'

import type { ClientOptions } from 'openapi-fetch'
import type { paths } from './gen/api'

/**
 * Creates a client for making API requests.
 *
 * @param options - The options for creating the client.
 * @param options.baseUrl - The base URL for the API.
 * @returns The openapi client.
 */
export function createClient(options: ClientOptions) {
  return createOpenApiClient<paths>(options)
}
