import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Label } from '@fysioscout/ui/forms/field'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { PatientQuickMenu } from '@/features/patients/components/patient-quick-menu'
import { ToggleChat } from '@/features/patients/user-actions/toggle-chat/components/toggle-chat'
import { dayjs } from '@/lib/dayjs'

interface PatientTopBarProps {
  /** The patient data, including employee details. */
  patient: Schemas['PatientWithEmployee']

  /** Optional CSS class name for styling the TopBar. */
  className?: string
}

export function PatientTopBar({ patient, className }: PatientTopBarProps) {
  const createdLabelId = React.useId()
  const patientEmailLabelId = React.useId()
  const practitionerLabelId = React.useId()
  const chatLabelId = React.useId()

  return (
    <div className={cn('border-neutral-6 border-y py-4', className)}>
      <div className={'hstack w-full items-center justify-between gap-8 text-sm'}>
        <div className={'stack gap-1'}>
          <Label id={patientEmailLabelId}>Email</Label>
          <Text aria-labelledby={patientEmailLabelId}>{patient.email}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={practitionerLabelId}>Behandler</Label>

          <Text aria-labelledby={practitionerLabelId}>
            {patient.clinic_employee?.name ?? 'Ingen behandler'}
          </Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={createdLabelId}>Oprettet</Label>
          <Text aria-labelledby={createdLabelId}>{dayjs.format(patient.request_date)}</Text>
        </div>

        <div className={'stack gap-1'}>
          <Label id={chatLabelId}>
            {patient.is_pdf_patient ? 'Ikke tilgængelig' : 'Aktiver chat'}
          </Label>

          <ToggleChat
            aria-labelledby={chatLabelId}
            patient={patient}
            initialValue={patient.is_chat_enabled}
          />
        </div>

        <PatientQuickMenu patient={patient} practitioner={patient.clinic_employee ?? null} />
      </div>
    </div>
  )
}
