import { useTitle } from 'ahooks'

import { PageLayout } from '@/components/layouts/page-layout'

export function TemplatesView() {
  useTitle('Skabeloner')

  return (
    <PageLayout>
      <h3>Templates view</h3>
    </PageLayout>
  )
}
