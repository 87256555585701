import React from 'react'
import { CircleXIcon, GlassesIcon } from 'lucide-react'
import { drop, pipe, take } from 'remeda'
import { match } from 'ts-pattern'

import type { ColumnProps, Selection, SortDescriptor } from 'react-aria-components'

import {
  Cell,
  Column,
  Pagination,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@fysioscout/ui/collections/table'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { dayjs } from '@/lib/dayjs'
import { sortFunctions } from '@/lib/sort-functions'

import { useExercisesList } from '../api/use-exercises-list'
import { useAddExercisesActions, usePageNumber, useSelection } from '../store'

const COLUMNS = {
  preview: 'preview',
  name: 'name',
  details: 'details',
  createdAt: 'created_at',
  owner: 'owner',
}
type ColumnId = (typeof COLUMNS)[keyof typeof COLUMNS]

interface ColumnConfig extends Omit<ColumnProps, 'id'> {
  id: ColumnId
  name: string
}

const columns = [
  { name: 'Forhåndsvisning', id: COLUMNS.preview, minWidth: 120, defaultWidth: 140 },
  { id: COLUMNS.name, name: 'Øvelse', defaultWidth: 240, allowsSorting: true, isRowHeader: true },
  { id: COLUMNS.details, name: 'Detaljer', defaultWidth: 360, allowsSorting: true },
  { id: COLUMNS.createdAt, name: 'Oprettet', defaultWidth: 160, allowsSorting: true },
  { id: COLUMNS.owner, name: 'Øvelseskartotek', defaultWidth: '1fr', allowsSorting: true },
] satisfies ColumnConfig[]

export function AddExercisesTable() {
  const list = useExercisesList()
  const selection = useSelection()
  const actions = useAddExercisesActions()
  const currentPage = usePageNumber()

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: COLUMNS.name,
    direction: 'ascending',
  })

  const items = React.useMemo(() => {
    const mappedList =
      list.data?.map(({ exercise }) => {
        const reps = `${exercise.set_count} sæt x ${exercise.rep_range?.maximum} reps`
        const range = `${exercise.set_count} sæt x ${exercise.rep_range?.minimum}-${exercise.rep_range?.maximum} reps`
        const time = `${exercise.set_count} sæt x ${exercise.rep_range?.maximum} min`

        const set = match(exercise.exercise_input_type)
          .with('reps', () => reps)
          .with('range', () => range)
          .with('time', () => time)
          .otherwise(() => reps)

        return {
          ...exercise,
          set,
        }
      }) ?? []

    return mappedList.sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.name, () => sortFunctions.string(a.name ?? '', b.name ?? ''))
        .with(COLUMNS.details, () => sortFunctions.string(a.set, b.set))
        .with(COLUMNS.createdAt, () => sortFunctions.date(a.created_at, b.created_at))
        .with(COLUMNS.owner, () => sortFunctions.string(a.clinic_id ?? '', b.clinic_id ?? ''))
        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [list.data, sortDescriptor.column, sortDescriptor.direction])

  const handleSelectionChange = (selection: Selection) => {
    if (list.data) {
      const availableExercises = list.data.map(({ exercise }) => ({
        id: exercise.id,
        name: exercise.name ?? '',
      }))

      actions.updateSelectionAndBasket(selection, availableExercises)
    }
  }

  const itemsPerPage = 20

  const paginatedItems = React.useMemo(() => {
    return pipe(items, drop((currentPage - 1) * itemsPerPage), take(itemsPerPage))
  }, [items, currentPage, itemsPerPage])

  const handlePageChange = (newPage: number) => {
    actions.setPageNumber(newPage)
  }

  const showPagination = items.length > itemsPerPage

  return (
    <div data-testid={'add-exercises-table'} className={'stack'}>
      <Table
        data-testid={'list'}
        aria-label={'Øvelser'}
        selectionMode={'multiple'}
        selectedKeys={selection}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        onSelectionChange={handleSelectionChange}
        className={cn('transition-opacity', {
          'pointer-events-none opacity-60': list.isFetching,
        })}
      >
        <TableHeader columns={columns} className={'bg-neutral-2'}>
          {(column) => (
            <Column
              key={column.id}
              isRowHeader={column.isRowHeader}
              defaultWidth={column.defaultWidth}
              allowsSorting={column.allowsSorting}
            >
              {column.name}
            </Column>
          )}
        </TableHeader>

        <TableBody
          items={paginatedItems}
          renderEmptyState={() => (
            <div className={'stack text-subtle-foreground center gap-2'}>
              <GlassesIcon />
              <Text>Ingen øvelser fundet.</Text>
            </div>
          )}
        >
          {(row) => (
            <Row id={row.id}>
              <Cell>
                {/* @TODO: Extract to component*/}
                <div
                  className={
                    'bg-neutral-4 stack center text-disabled-foreground aspect-video h-14 shrink-0 overflow-hidden rounded transition-opacity'
                  }
                >
                  {row.image ? (
                    <img
                      src={row.image}
                      className={'aspect-video h-14 shrink-0 object-cover'}
                      alt={'Billede af øvelse'}
                    />
                  ) : row.video_thumbnail ? (
                    <img
                      src={row.video_thumbnail}
                      className={'aspect-video h-14 shrink-0 object-cover'}
                      alt={'Billede af øvelse'}
                    />
                  ) : (
                    <CircleXIcon className={'size-5'} />
                  )}
                </div>
              </Cell>

              <Cell>
                <Text>{row.name}</Text>
              </Cell>

              <Cell>
                <div className={'flex items-center gap-4'}>
                  <div className={'w-[35%]'}>{row.set}</div>
                  <div className={'w-[30%]'}>{row.per_day_count}x dagligt</div>
                  <div className={'w-[35%]'}>{row.per_week_count}x om ugen</div>
                </div>
              </Cell>

              <Cell>{dayjs.to(row.created_at)}</Cell>

              <Cell>{row.clinic_id ? 'Egne øvelser' : 'FysioScout'}</Cell>
            </Row>
          )}
        </TableBody>
      </Table>

      {showPagination ? (
        <Pagination
          totalItems={items.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  )
}
