import { err, ok } from 'neverthrow'

import type { Result } from 'neverthrow'

interface TimeParams {
  minutes: number
  seconds: number
}

/**
 * Calculates the minutes and seconds from the given maximum reps.
 *
 * @param reps - The maximum reps
 * @returns The calculated minutes and seconds
 */
export function getMinutesAndSecondsFromMaxReps(reps: number): Result<TimeParams, string> {
  if (!Number.isInteger(reps) || reps < 0) {
    return err('Max reps must be a positive integer')
  }

  const minutes = Math.floor(reps / 60)
  const seconds = reps % 60

  return ok({ minutes, seconds })
}

/**
 * Calculates the maximum reps from the given minutes and seconds.
 *
 * @param params - The object containing the minutes and seconds
 * @param params.minutes - The number of minutes
 * @param params.seconds - The number of seconds
 * @returns The calculated maximum reps
 */
export function getMaxRepsFromMinutesAndSeconds({
  minutes,
  seconds,
}: TimeParams): Result<number, string> {
  if (!Number.isInteger(minutes) || minutes < 0) {
    return err('Minutes must be a positive integer')
  }

  if (!Number.isInteger(seconds) || seconds < 0 || seconds >= 60) {
    return err('Seconds must be a positive integer less than 60')
  }

  return ok(minutes * 60 + seconds)
}
