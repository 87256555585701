'use client'

import React from 'react'
import { SearchIcon, XIcon } from 'lucide-react'
import { composeRenderProps, SearchField as RACSearchField } from 'react-aria-components'

import type {
  SearchFieldProps as RACSearchFieldProps,
  ValidationResult,
} from 'react-aria-components'

import { Button } from '../../buttons/button/button'
import { Description, FieldError, FieldGroup, Input, Label } from '../field/field'
import { fieldStyles } from '../field/field.styles'

export interface SearchFieldProps extends RACSearchFieldProps {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export const SearchField = React.forwardRef<React.ElementRef<typeof Input>, SearchFieldProps>(
  ({ label, description, errorMessage, className, ...rest }, ref) => (
    <RACSearchField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label>{label}</Label> : null}

      <FieldGroup className={'relative'}>
        <SearchIcon
          aria-hidden
          className={'text-muted absolute left-0 ml-2 size-4 group-disabled:text-transparent'}
        />

        <Input ref={ref} className={'px-8 [&::-webkit-search-cancel-button]:hidden'} />

        <Button
          className={'absolute right-0 group-empty:invisible'}
          variant={'transparent'}
          color={'neutral'}
        >
          <XIcon />
        </Button>
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACSearchField>
  ),
)

SearchField.displayName = 'SearchField'
