import React from 'react'
import { CircleXIcon } from 'lucide-react'
import { Button as RACButton } from 'react-aria-components'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { ColumnProps, SortDescriptor } from 'react-aria-components'

import { Cell, Column, Row, Table, TableBody, TableHeader } from '@fysioscout/ui/collections/table'
import { FocusRing } from '@fysioscout/ui/misc/focus-ring'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'
import { getMinutesAndSecondsFromMaxReps } from '@fysioscout/utils/functions'

import { useEditExercisesActions } from '@/features/treatments/user-actions/edit-exercise/store'
import { ToggleExerciseStatus } from '@/features/treatments/user-actions/toggle-exercise-status/components/toggle-exercise-status'
import { dayjs } from '@/lib/dayjs'
import { sortFunctions } from '@/lib/sort-functions'

import { ExerciseTableActions } from './exercise-table-actions'

interface ExerciseTableProps {
  /** An array of exercise information objects. */
  exercises: Schemas['ExerciseInfo'][]

  /** Whether the top left corner of the table should be rounded. */
  roundTopLeft?: boolean
}

const COLUMNS = {
  preview: 'preview',
  name: 'name',
  details: 'details',
  created_at: 'created-at',
  isRelevant: 'is-relevant',
  actions: 'actions',
}

type ColumnId = (typeof COLUMNS)[keyof typeof COLUMNS]

interface ColumnConfig extends Omit<ColumnProps, 'id'> {
  id: ColumnId
  name: string
}

const columns = [
  { name: 'Forhåndsvisning', id: COLUMNS.preview, minWidth: 150, defaultWidth: 160 },
  { name: 'Navn', id: COLUMNS.name, defaultWidth: 200, allowsSorting: true },
  {
    name: 'Detaljer',
    id: COLUMNS.details,
    minWidth: 280,
    defaultWidth: 340,
    allowsSorting: true,
  },
  { name: 'Tilføjet', id: COLUMNS.created_at, width: 160, allowsSorting: true },
  { name: 'Aktuelle øvelser', id: COLUMNS.isRelevant, width: '1fr', allowsSorting: true },
  { name: '', id: COLUMNS.actions, width: 50 },
] satisfies ColumnConfig[]

export function ExerciseTable({ exercises, roundTopLeft }: ExerciseTableProps) {
  const editExerciseActions = useEditExercisesActions()

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: COLUMNS.created_at,
    direction: 'descending',
  })

  const items = React.useMemo(() => {
    const mappedExercises = exercises.map(({ exercise }) => {
      const { exercise_input_type, rep_range, set_count } = exercise

      const set = match(exercise_input_type)
        .with('reps', () => `${set_count} sæt x ${rep_range?.maximum} reps`)
        .with('range', () => `${set_count} sæt x ${rep_range?.minimum}-${rep_range?.maximum} reps`)
        .with('time', () => {
          const result = getMinutesAndSecondsFromMaxReps(rep_range?.maximum ?? 1)

          return result.match(
            ({ minutes, seconds }) => {
              const paddedSeconds = seconds.toString().padStart(2, '0')

              return `${set_count} sæt x ${minutes}:${paddedSeconds} min.`
            },
            (_err) => `${set_count} sæt x 0 min.`,
          )
        })
        .otherwise(() => 'Ukendt øvelsestype')

      return {
        ...exercise,
        set,
      }
    })

    return mappedExercises.sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.name, () => sortFunctions.string(a.name ?? '', b.name ?? ''))
        .with(COLUMNS.details, () => sortFunctions.string(a.description ?? '', b.description ?? ''))
        .with(COLUMNS.created_at, () => sortFunctions.date(a.created_at, b.created_at))
        .with(COLUMNS.isRelevant, () =>
          sortFunctions.boolean(a.is_relevant ?? false, b.is_relevant ?? false),
        )
        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [exercises, sortDescriptor.column, sortDescriptor.direction])

  const handleOpenExercise = (exerciseId: string) => {
    editExerciseActions.setExerciseId(exerciseId)
    editExerciseActions.open()
  }

  return (
    <div
      data-testid={'exercise-table'}
      className={cn('border-surface-border bg-surface rounded-r-lg rounded-bl-lg border pt-4', {
        'rounded-tl-lg': roundTopLeft,
      })}
    >
      <Table
        aria-label={'Øvelser'}
        selectionMode={'none'}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <Column
              id={column.id}
              isRowHeader={column.id === 'name'}
              defaultWidth={column.defaultWidth}
              allowsSorting={column.allowsSorting}
              minWidth={column.minWidth}
              width={column.width}
            >
              {column.name}
            </Column>
          )}
        </TableHeader>

        <TableBody items={items} renderEmptyState={() => 'Ingen øvelser fundet.'}>
          {(exercise) => (
            <Row id={exercise.id}>
              <Cell>
                <FocusRing>
                  <RACButton
                    className={'rounded outline-0 transition-opacity hover:opacity-80'}
                    onPress={() => handleOpenExercise(exercise.id)}
                  >
                    {/* @TODO: Extract to component*/}
                    <div
                      className={
                        'bg-neutral-4 stack center text-disabled-foreground aspect-video h-16 shrink-0 overflow-hidden rounded transition-opacity'
                      }
                    >
                      {exercise.image ? (
                        <img
                          src={exercise.image}
                          className={'aspect-video h-16 shrink-0 object-cover'}
                          alt={'Billede af øvelse'}
                        />
                      ) : exercise.video_thumbnail ? (
                        <img
                          src={exercise.video_thumbnail}
                          className={'aspect-video h-16 shrink-0 object-cover'}
                          alt={'Billede af øvelse'}
                        />
                      ) : (
                        <CircleXIcon className={'size-5'} />
                      )}
                    </div>
                  </RACButton>
                </FocusRing>
              </Cell>

              <Cell textValue={exercise.name ?? ''}>
                <div className={'hstack items-start'}>
                  <FocusRing>
                    <RACButton
                      className={'hover:text-accent-11 truncate outline-0'}
                      onPress={() => handleOpenExercise(exercise.id)}
                    >
                      {exercise.name}
                    </RACButton>
                  </FocusRing>
                </div>
              </Cell>

              <Cell>
                <div className={'flex items-center gap-4'}>
                  <div className={'w-[35%]'}>{exercise.set}</div>
                  <div className={'w-[30%]'}>{exercise.per_day_count}x dagligt</div>
                  <div className={'w-[35%]'}>{exercise.per_week_count}x om ugen</div>
                </div>
              </Cell>

              <Cell>
                <Text>{dayjs.to(exercise.created_at)}</Text>
              </Cell>

              <Cell>
                <ToggleExerciseStatus
                  exercise={exercise}
                  initialValue={exercise.is_relevant ?? false}
                />
              </Cell>

              <Cell>
                <ExerciseTableActions exercise={exercise} />
              </Cell>
            </Row>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
