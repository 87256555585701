import React from 'react'

import { Form } from '@fysioscout/ui/forms/form'
import { TextField } from '@fysioscout/ui/forms/text-field'
import {
  Dialog,
  DialogButtonGroup,
  DialogCancelButton,
  DialogContent,
  DialogCta,
  DialogTitle,
} from '@fysioscout/ui/overlays/dialog'
import { Modal } from '@fysioscout/ui/overlays/modal'
import { toast } from '@fysioscout/ui/status/toast'
import { Text } from '@fysioscout/ui/typography/text'

import { useEditClinicTreatment } from '@/features/treatments/api/edit-clinic-treatment'
import { useEditTreatmentNameContext } from '@/features/treatments/user-actions/edit-treatment-name/context'
import {
  useEditTreatmentNameActions,
  useIsOpen,
  useTreatment,
} from '@/features/treatments/user-actions/edit-treatment-name/store'

const FIELDS = {
  name: 'name',
}

export function EditTreatmentNameDialog() {
  const actions = useEditTreatmentNameActions()
  const context = useEditTreatmentNameContext()
  const isOpen = useIsOpen()
  const mutation = useEditClinicTreatment()
  const storeTreatment = useTreatment()

  const treatment = context.treatment ?? storeTreatment

  if (!treatment) {
    return null
  }

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      actions.close()
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const name = String(formData.get(FIELDS.name))

    mutation.mutate(
      { path: { patientId: context.patientId, treatmentId: treatment.id }, body: { name } },
      {
        onSuccess: () => {
          actions.reset()
          toast.success(`Behandlingsforløbets navn blev ændret til "${name}".`)
        },
      },
    )
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Dialog data-testid={'edit-treatment-name-dialog'}>
        {({ close }) => (
          <>
            <div className={'stack'}>
              {treatment.name ? (
                <Text size={'1'} muted medium>
                  {treatment.name}
                </Text>
              ) : null}

              <DialogTitle>Rediger navn på behandlingsforløb</DialogTitle>

              <DialogContent>
                <Form onSubmit={handleSubmit}>
                  <TextField
                    label={'Nyt navn'}
                    name={FIELDS.name}
                    type={'text'}
                    isRequired
                    autoFocus
                  />

                  <DialogButtonGroup>
                    <DialogCancelButton onPress={close} />
                    <DialogCta isPending={mutation.isPending}>Rediger navn</DialogCta>
                  </DialogButtonGroup>
                </Form>
              </DialogContent>
            </div>
          </>
        )}
      </Dialog>
    </Modal>
  )
}
