import React from 'react'
import { getLocalTimeZone, parseDate, today } from '@internationalized/date'
import { Link } from '@tanstack/react-router'
import { useDebounceFn, useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'
import { match, P } from 'ts-pattern'

import type { DateValue } from '@internationalized/date'

import { useShowVideoConsultationDisabledNotice } from '@fysioscout/hypertune/flags'
import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { DateRangePicker } from '@fysioscout/ui/date-and-time/date-range-picker'
import { SearchField } from '@fysioscout/ui/forms/search-field'
import { Container } from '@fysioscout/ui/layout/container'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { Route } from '@/app/routes/_auth/video-consultation/index'
import { PageHeading, PageLayout, PageSeparator } from '@/components/layouts/page-layout'
import { useCreateVideoConsultationActions } from '@/features/video-consultation/user-actions/create-video-consultation/store'
import { useSearchHotkey } from '@/hooks/use-search-hotkey'

export function VideoConsultationView() {
  useTitle('Videokonsultation')

  const showDisabledNotice = useShowVideoConsultationDisabledNotice()

  if (showDisabledNotice) {
    return <VideoConsultationDisabledNotice />
  }

  return (
    <PageLayout data-testid={'video-consultation-page'}>
      <VideoConsultationHeader />
      <PageSeparator />
      <VideoConsultationControls />
    </PageLayout>
  )
}

function VideoConsultationDisabledNotice() {
  return (
    <div data-testid={'video-consultation-disabled-notice'} className={'relative flex-1'}>
      <div className={'stack center absolute inset-0 z-20 flex-1 backdrop-blur-lg'}>
        <Container size={'sm'}>
          <div className={'mb-40 flex flex-col items-center gap-2 text-center'}>
            <Heading size={'6'}>Videokonsultationer er ikke aktiveret</Heading>
            <Text size={'2'} subtle>
              Skriv til os på{' '}
              <UILink>
                <a href={'mailto:info@fysioscout.dk'}>info@fysioscout.dk</a>
              </UILink>{' '}
              eller ring direkte på +45 27575162 og hør hvordan du aktiverer videokonsultationer.
            </Text>
          </div>
        </Container>
      </div>

      {/*  Skeleton UI of VideoConsultationPage */}
      <PageLayout className={'opacity-40'}>
        <div className={'hstack items-end justify-between'}>
          <PageHeading>Videokonsultationer</PageHeading>

          <div className={'hstack gap-2'}>
            <div className={'bg-accent-6 h-9 w-20 rounded-md'} />
            <div className={'bg-neutral-6 h-9 w-20 rounded-md'} />
          </div>
        </div>

        <PageSeparator />

        <div className={'hstack items-end justify-between gap-4'}>
          <div className={'hstack gap-2'}>
            <div className={'bg-neutral-3 h-9 w-60 rounded-md'} />
            <div className={'bg-neutral-3 h-9 w-60 rounded-md'} />
          </div>

          <div className={'bg-accent h-9 w-60 rounded-md'} />
        </div>

        <PageSeparator />

        <div className={'mt-8 flex flex-col gap-6'}>
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
        </div>
      </PageLayout>
    </div>
  )
}

function VideoConsultationHeader() {
  const searchParams = Route.useSearch()

  return (
    <div className={'hstack items-end justify-between'}>
      <PageHeading>Videokonsultationer</PageHeading>

      <div className={'hstack gap-2'}>
        <LinkButton
          variant={'soft'}
          color={searchParams.status === 'planned' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link from={Route.fullPath} to={'.'} search={(prev) => ({ ...prev, status: 'planned' })}>
            Planlagte
          </Link>
        </LinkButton>

        <LinkButton
          variant={'soft'}
          color={searchParams.status === 'done' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link from={Route.fullPath} to={'.'} search={(prev) => ({ ...prev, status: 'done' })}>
            Afholdte
          </Link>
        </LinkButton>
      </div>
    </div>
  )
}

function VideoConsultationControls() {
  const actions = useCreateVideoConsultationActions()

  return (
    <div className={'hstack items-end justify-between gap-4'}>
      <div className={'hstack gap-2'}>
        <PatientSearchField />
        <DateRangeSelector />
      </div>

      <Button variant={'solid'} color={'accent'} iconEnd={<PlusIcon />} onPress={actions.open}>
        Opret videokonsultation
      </Button>
    </div>
  )
}

function PatientSearchField() {
  const navigate = Route.useNavigate()
  const searchParams = Route.useSearch()
  const inputRef = React.useRef<HTMLInputElement>(null)

  useSearchHotkey(inputRef)

  const [searchQuery, setSearchQuery] = React.useState(searchParams.search)

  const { run: handleSearch } = useDebounceFn(
    (query: string): void => {
      setSearchQuery(query)

      void navigate({
        to: '.',
        search: (prev) => ({ ...prev, search: query || undefined }),
      })
    },
    { wait: 200 },
  )

  return (
    <SearchField
      ref={inputRef}
      label={'Søg efter patient'}
      className={'min-w-64'}
      onChange={handleSearch}
      defaultValue={searchQuery}
      onClear={() => setSearchQuery('')}
    />
  )
}

function DateRangeSelector() {
  const searchParams = Route.useSearch()
  const navigate = Route.useNavigate()

  const handlePeriodChange = (params: { start: DateValue; end: DateValue } | null): void => {
    void navigate({
      to: '.',
      search: (prev) => ({
        ...prev,
        start: params?.start ? params.start.toString() : undefined,
        end: params?.end ? params.end.toString() : undefined,
      }),
    })
  }

  const defaultValue = match(searchParams)
    .with({ start: P.string, end: P.string }, ({ start, end }) => ({
      start: parseDate(start),
      end: parseDate(end),
    }))
    .otherwise(() => ({
      start: today(getLocalTimeZone()).subtract({ days: 7 }),
      end: today(getLocalTimeZone()),
    }))

  return (
    <DateRangePicker
      label={'Periode'}
      onChange={handlePeriodChange}
      defaultValue={defaultValue}
      presetRanges={['24h', '7d', '30d', '90d', '6m']}
    />
  )
}
