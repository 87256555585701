import React from 'react'

import type { ContainerProps } from '@fysioscout/ui/layout/container'

import { Container } from '@fysioscout/ui/layout/container'
import { Separator } from '@fysioscout/ui/misc/separator'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

interface PageLayoutProps
  extends Pick<ContainerProps, 'size'>,
    React.ComponentPropsWithoutRef<'div'> {}

export function PageLayout({ children, size = '2xl', ...rest }: PageLayoutProps) {
  return (
    <div data-testid={'page-layout'} {...rest}>
      <Container size={size} className={'md:px-10 lg:px-10 2xl:px-14'}>
        <div className={'py-16 2xl:py-20'}>{children}</div>
      </Container>
    </div>
  )
}

export function PageHeading(props: { children?: React.ReactNode }) {
  return (
    <Text size={'7'} medium elementType={'h1'}>
      {props.children}
    </Text>
  )
}

export function PageSeparator(props: { className?: string }) {
  return <Separator className={cn('mb-8 mt-6', props.className)} />
}
