import React from 'react'

import { useTheme } from '@fysioscout/utils/react'

import { ThemeContext } from './theme-context'

interface ThemeProviderProps {
  children?: React.ReactNode
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const value = useTheme()

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
