import { Drawer } from '@fysioscout/ui/overlays/drawer'

import { ExportExercisesDialog } from '@/features/patients/user-actions/export-exercises/components/export-exercises-dialog'
import {
  useExportExercisesActions,
  useIsOpen,
} from '@/features/patients/user-actions/export-exercises/store'

export function ExportExercisesDrawer() {
  const actions = useExportExercisesActions()
  const isOpen = useIsOpen()

  return (
    <Drawer isOpen={isOpen} onOpenChange={actions.onOpenChange}>
      <ExportExercisesDialog />
    </Drawer>
  )
}
