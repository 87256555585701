import { Result } from 'neverthrow'
import { match, P } from 'ts-pattern'

interface ErrorWithMessage {
  message: string
}

/**
 * Converts an error object or any other value to a string and returns it as an error message.
 *
 * @param maybeError - The value to be converted to an error message.
 * @returns - The error object with the converted error message.
 */
export function stringifyError(maybeError: unknown): ErrorWithMessage {
  const safeStringify = Result.fromThrowable(
    JSON.stringify,
    () => 'An error occurred during stringification',
  )

  const jsonResult = safeStringify(maybeError)
  const message = jsonResult.unwrapOr(String(maybeError))

  return new Error(message)
}

/**
 * Extracts the error message from the given input.
 *
 * @param maybeError - The input to extract the error message from.
 * @returns - The extracted error message.
 */
export function getErrorMessage(maybeError: unknown): string {
  return match(maybeError)
    .with(P.nullish, () => 'Prøv igen senere.')
    .with(P.string, (error) => error)
    .with({ description: P.string }, ({ description }) => description)
    .with({ message: P.string }, ({ message }) => message)
    .with({ msg: P.string }, ({ msg }) => msg)
    .otherwise(() => stringifyError(maybeError).message)
}
