import React from 'react'

import { Form } from '@fysioscout/ui/forms/form'
import { TextField } from '@fysioscout/ui/forms/text-field'
import {
  Dialog,
  DialogButtonGroup,
  DialogCancelButton,
  DialogContent,
  DialogCta,
  DialogSubtitle,
  DialogTitle,
} from '@fysioscout/ui/overlays/dialog'
import { Modal } from '@fysioscout/ui/overlays/modal'
import { toast } from '@fysioscout/ui/status/toast'

import { useChangePatientEmail } from '@/features/patients/api/change-patient-email'

import { useChangePatientEmailActions, useIsOpen, usePatient } from '../store'

const FIELDS = {
  email: 'email',
}

export function ChangePatientEmailDialog() {
  const actions = useChangePatientEmailActions()
  const isOpen = useIsOpen()
  const mutation = useChangePatientEmail()
  const patient = usePatient()

  const close = () => {
    actions.setPatientId(null)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!patient) return

    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const email = String(formData.get(FIELDS.email))

    mutation.mutate(
      {
        path: { patientId: patient.id },
        body: { email },
      },
      {
        onSuccess: () => {
          close()
          toast.success(`Email for ${patient.name ?? 'patienten'} blev opdateret.`)
        },
      },
    )
  }

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      close()
    }
  }

  return (
    <Modal isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Dialog data-testid={'edit-treatment-name-dialog'} className={'stack'}>
        {({ close }) => (
          <>
            <DialogTitle>Skift email for {patient?.name ?? 'patienten'}</DialogTitle>

            <DialogSubtitle>
              Når du ændrer patientens e-mailadresse, sendes der automatisk en ny invitation til den
              nye adresse.
            </DialogSubtitle>

            <DialogContent>
              <Form onSubmit={handleSubmit}>
                <TextField
                  label={'Ny email'}
                  name={FIELDS.email}
                  type={'email'}
                  isRequired
                  autoFocus
                />

                <DialogButtonGroup>
                  <DialogCancelButton onPress={close} />
                  <DialogCta isPending={mutation.isPending}>Skift email</DialogCta>
                </DialogButtonGroup>
              </Form>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Modal>
  )
}
